import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import AppBar from "../layouts/ApplicationBar";
import Banner from "../layouts/Banner";
import Footer from "../layouts/Footer";

const TripContainer = ({
  banner = <Banner />,
  bannerImage,
  bannerImgAlt = "Banner",
  ...props
}) => {
  return (
    <Paper
      sx={{
        // backgroundColor: "#EFF5F5",
        // backgroundImage:"url('../dotgrid.png')",
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
        minHeight: "100vh",
        "@media(max-width:768px)": {
          minHeight: '60vh'
        }
      }}
    >
      <AppBar />
      <Box sx={{ position: "relative", height: "65vh", zIndex: 0 }}>
        <img
          src={bannerImage?.url}
          alt={bannerImgAlt}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            position: "absolute"
          }}
        />
        <div style={{ backgroundColor: "#0007", minHeight: "65vh", position: "relative" }}></div>
      </Box>
      {banner}
      <Box
        sx={{
          mb: 2,
          padding: 2,
          backgroundColor: "white",
        }}
      >
        {props.children}
      </Box>
      <Footer />
      <a href="https://wa.me/918287734456" target="_blank"><img src="/whatsapp_icon.svg" class="callIcon"></img></a>
    </Paper>
  );
};

export default TripContainer;
