import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import Preloader from "../common/Preloader";
import FilterDropDownCategory from "./FilterDropDownCategory";
import GridTripCard from "./GridTripCard";

const TripGridWithFilter = ({
  trips,
  loadingTrips,
  editable = false,
  reloadTrips,
  handleOpenEdit,
  categoryName,
  monthName,
}) => {
  const [cachedTrips, setCachedTrips] = useState([...trips]);
  return (
    <Box mt={2}>
      <FilterDropDownCategory
        monthName={monthName}
        trips={trips}
        setCachedTrips={setCachedTrips}
      />
      {loadingTrips ? (
        <Grid container justifyContent="center">
          <Grid item pt={5}>
            <Preloader />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          {cachedTrips.map((td, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              // sx={{ display: "flex", justifyContent: "center" }}
            >
              <GridTripCard
                {...td}
                categoryName={categoryName}
                editable={editable}
                reloadTrips={reloadTrips}
                handleOpenEdit={handleOpenEdit}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TripGridWithFilter;
