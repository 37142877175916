import {
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./Curated.css";

const Curated = ({ categories }) => {
  return (
    <>
      {categories.length > 0 && (
        <>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              mt: 2,
              mb: 4,
              fontSize: "39.81px",
              color: "#2f86a6",
              lineHeight: "47px",
              fontWeight: 700,
              "@media(max-width:767px)": {
                marginBottom: "12px !important",
                mt: 1,
                fontSize: "24px !important",
              },
            }}
          >
            Curated Categories
          </Typography>
          <Grid
            container
            spacing={2}
            direction="row"
            paddingX={{ md: 10, lg: 10, xl: 10 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            rowSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {categories.map((c, index) => (
              <Grid
                item
                xs={6}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                key={index}
                sx={{ transform: "rotate(2deg) !important" }}
              >
                <Card
                  className="curated-card"
                  sx={{
                    maxWidth: "18vw",
                    borderRadius: "10px",
                    height: "23vw",
                    padding: "5px",
                    boxShadow: "0 0 0 #0000 !important",
                    "@media(max-width:767px)": {
                      maxWidth: "100vw",
                      height: "53vw",
                    },
                    "@media(min-width:600px)": { maxWidth: "100%" },
                  }}
                >
                  <CardActionArea
                    className="hoverOn"
                    LinkComponent={Link}
                    to={`/${c.slug}`}
                  >
                    <CardMedia
                      component="img"
                      image={c.coverImage.url}
                      alt={c.categoryName}
                      loading="lazy"
                      sx={{
                        transform: "rotate(-2deg) !important",
                        borderRadius: "10px",
                      }}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default Curated;
