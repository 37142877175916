import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

const icon = <CheckBoxOutlineBlankRounded fontSize="small" />;
const checkedIcon = <CheckBoxRounded fontSize="small" />;
const CategoryListView = ({
  bannerImageUrl = "/bg2.webp",
  categoryName = "Thailand Fullmoon New Year Party with Flight",
  slug,
  selected,
  _id,
  ...otherProps
}) => {
  const labelId = `checkbox-list-label-${slug}`;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const truncatedCategoryName = isSmallScreen
    ? `${categoryName.substring(0, 10)}...`
    : categoryName;
  return (
    <ListItem
      {...otherProps}
      key={`ley-${labelId}`}
      secondaryAction={
        <Checkbox
          edge="end"
          checked={selected}
          icon={icon}
          checkedIcon={checkedIcon}
          inputProps={{ "aria-labelledby": labelId }}
        />
      }
      disablePadding
    >
      <ListItemAvatar>
        <Avatar alt={categoryName} src={bannerImageUrl} />
      </ListItemAvatar>
      <Tooltip
        title={
          <Typography variant="caption">
            Category name: {categoryName}
          </Typography>
        }
      >
        <ListItemText primary={truncatedCategoryName} />
      </Tooltip>
    </ListItem>
  );
};

export default CategoryListView;
