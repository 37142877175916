import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const CategoryDescription = ({
  categoryName = "Shrimp and Chorizo Paella",
  categoryDescription = "Seeking a well-curated Thailand tour package from Delhi, Bangalore, Mumbai, or another Indian city? If so,  The finest travel agency offering a wide range of Thailand tour packages from india, so you've come to the perfect location. We have years of experience and are masters at creating unforgettable Thailand tour packages from India.",
}) => {
  const [expanded, setExpanded] = useState(false);
  const resultDesc = categoryDescription.substring(0, 150) + "...";
  const restDesc = categoryDescription.substring(150);
  return (
    <Card
      sx={{
        boxShadow: "0 0 2px #8c8c8c5c !important",
        // border: "1px solid #c9c9c95c",
        marginTop: "30px",
        marginBottom: "40px",
        borderRadius: "10px",
        "@media(max-width:767px)": {
          marginTop: "20px",
          marginBottom: "30px",
        },
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h4"
            sx={{
              color: "#2f86a6 !important",
              fontWeight: 700,
              fontSize: "25px",
              background: "#efefef !important",
              padding: "14px 20px",
            }}
          >
            {`Book ${categoryName} at Best Price`}
          </Typography>
        }
        sx={{ padding: "0px !important" }}
      />
      {/* <div
        dangerouslySetInnerHTML={{
          __html: categoryDescription,
        }}
        className="descCategory"
      /> */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: "0px", paddingLeft: "20px;" }}>
          <Typography variant="body2" color="text.secondary" component="div">
            <div dangerouslySetInnerHTML={{ __html: categoryDescription }} />
          </Typography>
        </CardContent>
      </Collapse>
      <CardActions disableSpacing sx={{ marginTop: "-50px" }}>
        <Tooltip title={`Click to ${expanded ? "Collapsed" : "Expand"}`}>
          <IconButton
            aria-label="Show more"
            color="info"
            size="small"
            onClick={() => setExpanded(!expanded)}
            sx={{
              marginLeft: "auto",
            }}
          >
            {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default CategoryDescription;
