import { Fragment, useEffect, useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // nested: {
  //   paddingLeft: theme.spacing(4),
  // },
}));

const DrawerMenu = ({ navList }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState({
    international: false,
    domestic: false,
    upcoming: false,
  });

  const handleToggle = (section) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <List>
      <ListItemButton component={Link} to="/" key="Home">
        <ListItemText primary="Home" />
      </ListItemButton>
      <Divider />
      <ListItemButton onClick={() => handleToggle('international')}>
        <ListItemText primary="International trips" />
        {openSections.international ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Divider />
      <Collapse in={openSections.international} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {navList
            .filter((category) => category.isInternationalCategory)
            .map((category) => (
              <ListItemButton
                component={Link}
                to={`/${category.slug}`}
                className={classes.nested}
                key={category._id}
              >
                <ListItemText primary={category.categoryName} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>

      <ListItemButton onClick={() => handleToggle('domestic')}>
        <ListItemText primary="Domestic trips" />
        {openSections.domestic ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Divider />
      <Collapse in={openSections.domestic} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {navList
            .filter((category) => category.isDomesticCategory)
            .map((category) => (
              <ListItemButton
                component={Link}
                to={`/${category.slug}`}
                className={classes.nested}
                key={category._id}
              >
                <ListItemText primary={category.categoryName} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
      <Divider />
      <ListItemButton component={Link} to="/weekend-trip-from-delhi" key="Weekend">
        <ListItemText primary="Weekend Trips" />
      </ListItemButton>
      <Divider />
      <ListItemButton onClick={() => handleToggle('upcoming')}>
        <ListItemText primary="Upcoming trips" />
        {openSections.upcoming ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openSections.upcoming} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {navList
            .filter((category) => category.categoryName === 'upcoming')
            .flatMap((category) => category.trips)
            .map((month) => (
              <ListItemButton className={classes.nested} key={month}
                component={Link}
                to={`/upcomingtrips/${month}`}
              >
                <ListItemText primary={month} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
      <Divider />
      <ListItemButton component={Link} to="/blog" key="blog">
        <ListItemText primary="Blog" />
      </ListItemButton>
      <Divider />
      <ListItemButton component={Link} to="/CruiseTrips" key="cruise">
        <ListItemText primary="Cruise Trips" />
      </ListItemButton>
      <Divider sx={{mb:2}} />
      <Button
      variant="contained"
      component={Link}
        onClick={() => navigate("/")}
        sx={{
          m: 4,
          p:"1px 15px !important",
          color: "#000000 !important",
          display: "flex",
          textTransform: "none",
          border:"0",
          lineHeight:1.5,
          fontSize:"14px !important",
          borderRadius:"25px !important",
          backgroundColor:"rgb(254, 230, 11)",
          alignItems:"center",
          height:"45px !important"
        }}
      >
        Contact Us
      </Button>
    </List>
  )
};

export default DrawerMenu;
