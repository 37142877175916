import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import LeadSearchBar from "./LeadSearchBar";

const bannerContents = [
  "Embracing Moments",
  "Savoring Experiences",
  "Capturing Emotions",
  "Fostering Connections",
  "Dil To Ghummakkad Hai...",
];
const VerticalSlider = styled(Box)(({ theme }) => ({
  height: "40px",
  overflow: "hidden",
  position: "relative",
}));

const SlideUpAnimation = styled("div")(({ theme }) => ({
  position: "relative",
  top: "0",
  animation: "slideUp 15s linear infinite",
  "@keyframes slideUp": {
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-100%)",
    },
  },
}));

const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Banner = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: [220, 300],
        width: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Stack direction="column" spacing={1}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "22px",
            color: "white !important",
          }}
        >
          Connecting Travelers Since 2021
        </Typography>
        <VerticalSlider>
          <SlideUpAnimation>
            <TextContainer>
              {bannerContents.map((text, index) => (
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: "3rem",
                    fontWeight: 600,
                    color: "white",
                    "@media(max-width:767px)": {
                      fontSize: "25px !important",
                    },
                  }}
                  key={index}
                >
                  {text}
                </Typography>
              ))}
            </TextContainer>
          </SlideUpAnimation>
        </VerticalSlider>
        <LeadSearchBar />
      </Stack>
    </Box>
  );
};

export default Banner;
