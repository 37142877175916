import {
  AddRounded,
  CloseOutlined,
  CurrencyRupeeRounded,
  DateRangeRounded,
  DriveFileRenameOutlineRounded,
  EmailRounded,
  MailRounded,
  PersonRounded,
  PhoneRounded,
  PinDropRounded,
  QrCodeScannerRounded,
  RemoveRounded,
  SendRounded,
  SmartphoneRounded,
  TimerRounded,
} from "@mui/icons-material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Slide,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import numeral from "numeral";
import React, { forwardRef, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import moment from "moment";
import YouTube from "react-youtube";
import Preloader from "../components/common/Preloader";
import {
  boilerTrip,
  formatDateInDayMonthYear,
  getCurrentDomain,
  getDurationNameById,
  validateEmail,
} from "../components/common/utils";
import TripContainer from "../components/containers/TripContainer";
import EnquiryForm from "../components/layouts/EnquiryFormCategoryView";
import RelatedTrips from "../components/layouts/RelatedTrips";
import TripBanner from "../components/layouts/TripBanner";
import MakeYouHappier from "../components/layouts/TripMakeYouHappier";
import YouTubeSlider from "../components/layouts/TripYoutubeSlider";
import useTostify from "../hooks/useTostify";
import EnquiryFormTrips from "../components/layouts/EnquiryFormTrips";

const RightTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const LeftTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const BookingTripView = () => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setNotification } = useTostify();
  const { tripSlug } = useParams();
  const [trip, setTrip] = useState(boilerTrip);
  const [imgGallery, setImgGallery] = useState([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const doublePrice = trip?.startingPrice + trip?.startingPrice * 0.1;
  const [itineraryPDF, setItineraryPDF] = useState({});
  const [openCalendar, setOpenCalendar] = useState(false);
  const groupByMonth = trip?.tripDates?.reduce((acc, dateRange) => {
    dateRange.forEach((dateString) => {
      const formattedDate = formatDateInDayMonthYear(dateString);
      const date = new Date(dateString);
      const monthName = date.toLocaleString("default", { month: "short" });
      if (!acc[monthName]) {
        acc[monthName] = [];
      }
      acc[monthName].push(formattedDate);
    });

    return acc;
  }, {});

  const groupedDates =
    trip?.tripDates?.flat().map((dtStr) => {
      const mname = new Date(dtStr)
        .toLocaleString("default", {
          month: "long",
        })
        .toUpperCase();
      return {
        mname,
        formattedDate: new Date(dtStr),
        dates: dtStr,
      };
    }) || [];

  const shouldDisableDates = (date) => {
    let groupedDate = groupedDates.map(
      (item) => item.formattedDate.toISOString().split("T")[0]
    );
    const dateString = date.toISOString().split("T")[0];
    return !groupedDate.includes(dateString);
  };

  const [tab, setTab] = useState("desc");
  const [monthName, setMonthName] = useState(Object.keys(groupByMonth)[0]);
  const [openBookingForm, setOpenBookingForm] = useState(false);
  const [openBookingConfirmation, setOpenBookingConfirmation] = useState(false);
  const [openPaymentConfirmation, setOpenPaymentConfirmation] = useState(false);
  const [loadingQrCode, setLoadingQrCode] = useState(false);
  const [loadingTrip, setLoadingTrip] = useState(false);
  const [saving, setSaving] = useState(false);
  const [qr, setQr] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [shouldDisable, setShouldDisable] = useState(false); // Initially set shouldDisable to false
  const [phone, setPhone] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [tripleBookingCount, setTripleBookingCount] = useState(0);
  const [doubleBookingCount, setDoubleBookingCount] = useState(0);
  const [quadBookingCount, setQuadBookingCount] = useState(0);
  const [doubleSharingAmount, setDoubleSharingAmount] = useState(0);
  const [tripleSharingAmount, setTripleSharingAmount] = useState(0);
  const [quadSharingAmount, setQuadSharingAmount] = useState(0);
  const [barCodePayableAmount, setBarCodePayableAmount] = useState(0);
  const [customField, setCustomField] = useState([]);
  const [customFieldCount, setCustomFieldCount] = useState({});
  const [errors, setErrors] = useState({});

  const [bookingData, setBookingData] = useState({
    tripName: "",
    tripDate: "2023-12-04T18:30:00.000Z",
    username: "",
    email: "",
    mobileNo: "",
    grossAmount: 0,
    payableAmount: 0,
    tripleSharing: {
      totalCost: 0,
      perPersonCost: 0,
      bookingCount: 0,
    },
    doubleSharing: {
      totalCost: 0,
      perPersonCost: 0,
      bookingCount: 0,
    },
    quadSharing: {
      totalCost: 0,
      perPersonCost: 0,
      bookingCount: 0,
    },
    doubleSharingAmount: 0,
    tripleSharingAmount: 0,
    quadSharingAmount: 0,
    customItems: [
      {
        index: "",
        customItemName: "",
        customItemAmount: 0,
      },
    ],
  });

  const getTripsDurationDate = () => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    const startDate = new Date(bookingData.tripDate);
    const endDate = new Date(bookingData.tripDate);
    endDate.setDate(endDate.getDate() + trip.durationId);
    return (
      startDate.toLocaleDateString("en-US", options) +
      " - " +
      endDate.toLocaleDateString("en-US", options)
    );
  };

  const handleBooking = () => {
    if (!validateBookingForm()) return;
    const grossAmount = calculateSharingAmount() + calculateCustomAmount();

    let newBooking = {
      tripName: trip.tripName,
      tripDate: selectedDate,
      username: fullName,
      email,
      mobileNo: phone,
      grossAmount,
      itineraryPDF: trip.itineraryPDF,
      payableAmount: Math.ceil(grossAmount + grossAmount * 0.05),
      tripleSharing: {
        totalCost: trip.tripleSharingAmount * tripleBookingCount,
        perPersonCost: trip.tripleSharingAmount,
        bookingCount: tripleBookingCount,
      },
      doubleSharing: {
        totalCost: trip.doubleSharingAmount * doubleBookingCount,
        perPersonCost: trip.doubleSharingAmount,
        bookingCount: doubleBookingCount,
      },
      quadSharing: {
        totalCost: trip.quadSharingAmount * quadBookingCount,
        perPersonCost: trip.quadSharingAmount,
        bookingCount: quadBookingCount,
      },
      customItems: customFieldCount,
      doubleSharingAmount: trip.doubleSharingAmount * doubleBookingCount,
      tripleSharingAmount: trip.tripleSharingAmount * tripleBookingCount,
      quadSharingAmount: trip.quadSharingAmount * quadBookingCount,
    };
    setBarCodePayableAmount(newBooking.payableAmount);
    let clonedBooking = { ...newBooking };
    delete clonedBooking.payableAmount;
    setSaving(true);
    axios
      .post(`${getCurrentDomain()}/bookings`, clonedBooking)
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "We got you, Thanks for booking!!",
        });
        setBookingData(newBooking);
        setSaving(false);
        setOpenBookingForm(false);
        setOpenBookingConfirmation(true);
        resetBookingForm();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while saving Category!!",
        });
        setSaving(false);
      });
  };

  const calculateSharingAmount = () => {
    let sharingAmount = 0;
    sharingAmount =
      tripleSharingAmount * tripleBookingCount +
      doubleSharingAmount * doubleBookingCount +
      quadSharingAmount * quadBookingCount;
    return sharingAmount;
  };
  const calculateCustomAmount = () => {
    let amount = 0;
    amount = Object.keys(customFieldCount).map(
      (id) =>
        customField.find((item) => item._id === id).customItemAmount *
        customFieldCount[id]
    );
    let totalAmount = 0;
    amount.forEach((num) => (totalAmount += num));
    return totalAmount;
  };
  const resetBookingForm = () => {
    setSelectedDate("");
    setTripleBookingCount(0);
    setDoubleBookingCount(0);
    setQuadBookingCount(0);
    setCustomFieldCount([]);
    setFullName("");
    setEmail("");
    setPhone("");
  };

  const validateBookingForm = () => {
    let valid = true;
    const newErrors = {};

    if (trip.tripDates.length > 0 && !selectedDate.trim()) {
      newErrors.selectedDate = "Date is required!!";
      valid = false;
    }
    if (!fullName.trim()) {
      newErrors.fullName = "Full Name is required!!";
      valid = false;
    }
    if (
      quadBookingCount === 0 &&
      tripleBookingCount === 0 &&
      doubleBookingCount === 0
    ) {
      newErrors.roomSharing = "Please select at least on Room sharing option!!";
      valid = false;
    }
    if (validateEmail(email)) {
      newErrors.email = "Invalid email!!";
      valid = false;
    }
    if (!matchIsValidTel(phone)) {
      newErrors.phone = "Invalid Phone!!";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleQrConfirmation = async (amount) => {
    setLoadingQrCode(true);
    try {
      const response = await fetch(
        `${getCurrentDomain()}/api/qr?amount=${Math.ceil(amount)}`
      );
      const dataURL = await response.text();
      setQr(dataURL);
      setBarCodePayableAmount(amount);
      setLoadingQrCode(false);
    } catch (e) {
      console.log("API exception ", e);
      setNotification({
        open: true,
        severity: "error",
        msg: "Oops Error while fetching QR code!!",
      });
      setLoadingQrCode(false);
    }
  };

  let removeCustomCount = (id) => {
    setCustomFieldCount((prevData) => {
      if (!prevData[id]) return prevData;
      const updatedCart = { ...prevData, [id]: prevData[id] - 1 };
      return updatedCart;
    });
  };
  let addCustomCount = (id) => {
    setCustomFieldCount((prevData) => ({
      ...prevData,
      [id]: (prevData[id] || 0) + 1,
    }));
  };

  useEffect(() => {
    setLoadingTrip(true);
    axios
      .get(`${getCurrentDomain()}/trips`, {
        params: {
          slug: tripSlug,
        },
      })
      .then((res) => {
        setTrip(res.data[0]);
        setCustomField(res?.data[0]?.customItems);
        setTripleSharingAmount(res?.data[0]?.tripleSharingAmount);
        setDoubleSharingAmount(res?.data[0]?.doubleSharingAmount);
        setQuadSharingAmount(res?.data[0]?.quadSharingAmount);
        setItineraryPDF(res?.data[0]?.itineraryPDF);
        setImgGallery(
          res.data[0]?.galleryImages?.map((obj) => ({
            original: obj.url,
            thumbnail: obj.url,
          }))
        );
        setLoadingTrip(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip Details!!",
        });
        setLoadingTrip(false);
      });
  }, [tripSlug, setNotification]);

  return (
    <Box>
      {loadingTrip ? (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingTrip}
        >
          <Preloader />
        </Backdrop>
      ) : (
        <TripContainer
          banner={
            <TripBanner
              itineraryURL={itineraryPDF?.url}
              tripPrice={trip.startingPrice}
              tripName={trip.tripName}
              setOpenBookingForm={setOpenBookingForm}
            />
          }
          bannerImage={trip.bannerImage}
          bannerImgAlt={trip.tripName}
        >
          <Stack spacing={1}>
            <Grid container px={{ sm: 1, md: 5, lg: 10 }}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                  "@media(max-width:767px)": {
                    marginTop: "0px",
                    marginBottom: "5px",
                  },
                }}
              >
                {/* <Stack
                  direction={isSmallScreen ? 'column' : 'row'}
                  justifyContent={'space-around'}
                > */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    // marginTop: "20px",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "70px",
                      width: "70px",
                      backgroundColor: "#2f86a6 !important",
                      "@media(max-width:767px)": {
                        height: "60px",
                        width: "60px",
                      },
                      "@media(max-width:550px)": {
                        height: "30px",
                        width: "30px",
                      },
                    }}
                  >
                    <TimerRounded
                      fontSize="large"
                      sx={{
                        padding: "1px",
                        "@media(max-width:550px)": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Avatar>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "18px",
                      "@media(max-width:767px)": {
                        fontSize: "16px",
                      },
                      "@media(max-width:550px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Duration
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media(max-width:767px)": {
                        lineHeight: "16px",
                        fontSize: "16px",
                      },
                    }}
                  >
                    {getDurationNameById(trip.durationId)}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                  "@media(max-width:767px)": {
                    marginTop: "0px",
                    marginBottom: "5px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    // marginTop: "20px",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "70px",
                      width: "70px",
                      backgroundColor: "#2f86a6 !important",
                      "@media(max-width:767px)": {
                        height: "60px",
                        width: "60px",
                      },
                      "@media(max-width:550px)": {
                        height: "30px",
                        width: "30px",
                      },
                    }}
                  >
                    <CurrencyRupeeRounded
                      fontSize="large"
                      sx={{
                        padding: "1px",
                        "@media(max-width:550px)": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Avatar>
                  {trip.startingPrice ? (
                    <>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "18px",
                          "@media(max-width:767px)": {
                            fontSize: "16px",
                          },
                          "@media(max-width:550px)": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        Starting Price &nbsp;
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "18px",
                          fontWeight: "600",
                          "@media(max-width:767px)": {
                            fontSize: "16px",
                          },
                          "@media(max-width:550px)": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {trip.startingPrice} /-
                      </Typography>
                    </>
                  ) : (
                    <Typography
                      variant="h6"
                      sx={{
                        "@media(max-width:767px)": {
                          lineHeight: "16px",
                          fontSize: "16px",
                        },
                        "@media(max-width:550px)": {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Price on Request
                    </Typography>
                  )}
                  <Typography
                    variant="p"
                    color="#2f86a6"
                    sx={{
                      fontSize: "14px",
                      "@media(max-width:767px)": {
                        fontSize: "10px",
                      },
                    }}
                  >
                    (No Cost EMI Available)
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                  "@media(max-width:767px)": {
                    marginTop: "0px",
                    marginBottom: "5px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    // marginTop: "20px",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "70px",
                      width: "70px",
                      backgroundColor: "#2f86a6 !important",
                      "@media(max-width:767px)": {
                        height: "60px",
                        width: "60px",
                      },
                      "@media(max-width:550px)": {
                        height: "30px",
                        width: "30px",
                      },
                    }}
                  >
                    <PinDropRounded
                      fontSize="large"
                      sx={{
                        padding: "1px",
                        "@media(max-width:550px)": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Avatar>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "18px",
                      "@media(max-width:767px)": {
                        fontSize: "16px",
                      },
                      "@media(max-width:550px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Pick and Drop
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media(max-width:767px)": {
                        lineHeight: "16px",
                        fontSize: "16px",
                      },
                      "@media(max-width:550px)": {
                        fontSize: "10px",
                      },
                    }}
                  >
                    {trip.pickupLocation} to {trip.dropLocation}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={{
                  marginTop: "15px",
                  marginBottom: "20px",
                  "@media(max-width:767px)": {
                    marginTop: "0px",
                    marginBottom: "5px",
                  },
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <Avatar
                    sx={{
                      height: "70px",
                      width: "70px",
                      backgroundColor: "#2f86a6 !important",
                      "@media(max-width:767px)": {
                        height: "60px",
                        width: "60px",
                      },
                      "@media(max-width:550px)": {
                        height: "30px",
                        width: "30px",
                      },
                    }}
                  >
                    <Instagram
                      fontSize="large"
                      sx={{
                        padding: "1px",
                        "@media(max-width:550px)": {
                          fontSize: "16px",
                        },
                      }}
                    />
                  </Avatar>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "18px",
                      "@media(max-width:767px)": {
                        fontSize: "16px",
                      },
                      "@media(max-width:550px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Community of 350k+
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      "@media(max-width:767px)": {
                        lineHeight: "16px",
                        fontSize: "16px",
                      },
                      "@media(max-width:550px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Travelers on Instagram
                  </Typography>
                </Box> */}
                {/* </Stack> */}
              </Grid>
            </Grid>
            <Grid container px={{ sm: 1, md: 5, lg: 10 }}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Card elevation={2} sx={{ m: 1 }}>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        className="trip_tabs"
                        onChange={(event, newValue) => setTab(newValue)}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                      >
                        <Tab
                          label="Description"
                          title="Description"
                          sx={{ textTransform: "none" }}
                          value="desc"
                        />
                        <Tab
                          label="Itinerary"
                          sx={{ textTransform: "none" }}
                          value="it"
                        />
                        <Tab
                          label="Date & Costing"
                          sx={{ textTransform: "none" }}
                          value="dc"
                        />
                        <Tab
                          label="Inclusion"
                          sx={{ textTransform: "none" }}
                          value="inc"
                        />
                        <Tab
                          label="Exclusion"
                          sx={{ textTransform: "none" }}
                          value="exc"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="desc">
                      <Typography variant="p" component="small">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: trip.tripDescription,
                          }}
                        />
                      </Typography>
                    </TabPanel>
                    <TabPanel value="it">
                      <Typography variant="p" component="small">
                        <div
                          dangerouslySetInnerHTML={{ __html: trip.itinerary }}
                        />
                      </Typography>
                    </TabPanel>
                    <TabPanel value="dc">
                      <Typography variant="h6" sx={{ fontSize: "16px" }}>
                        Dates:
                      </Typography>
                      <ToggleButtonGroup
                        color="primary"
                        size="small"
                        value={monthName}
                        exclusive
                        onChange={(event, newAlignment) => {
                          setMonthName(newAlignment);
                        }}
                        className="toggleMonths"
                        aria-label="dates"
                      >
                        {Object.keys(groupByMonth)?.map((m, _i) => (
                          <ToggleButton key={_i} value={m}>
                            {m}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      <Box
                        sx={{
                          display: "flex",
                          border: "0px !important",
                          "@media(max-width:767px)": {
                            display: "ruby-text !important",
                          },
                        }}
                      >
                        {groupByMonth[monthName]?.map((d, _i) => (
                          <Chip
                            key={_i}
                            variant="outlined"
                            color="success"
                            size="small"
                            label={d}
                            sx={{
                              m: 1,
                              borderRadius: "4px",
                              display: "block",
                              fontSize: "14px",
                              height: "33px !important",
                              paddingLeft: "12px !important",
                              paddingRight: "12px !important",
                              paddingTop: "5px !important",
                              paddingBottom: "5px !important",
                              border: "1px solid #2f86a6",
                              color: "#2f86a6",
                            }}
                          />
                        ))}
                      </Box>

                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {trip.startingPrice ? (
                          <Button
                            variant="outlined"
                            size={isSmallScreen ? "medium" : "large"}
                            className="book_btn"
                            sx={{
                              borderRadius: 10,
                              marginTop: "20px",
                              color: "#fff !important",
                              border: "1px solid #2f86a6",
                              background: "#2f86a6",
                            }}
                            onClick={() => setOpenBookingForm(true)}
                          >
                            Book Now
                          </Button>
                        ) : (
                          <Button
                            disabled="true"
                            variant="outlined"
                            size={isSmallScreen ? "medium" : "large"}
                            className="book_btn"
                            sx={{
                              borderRadius: 10,
                              marginTop: "20px",
                              color: "#fff !important",
                              border: "1px solid #2f86a6",
                              background: "#efefef",
                            }}
                          >
                            Book Now
                          </Button>
                        )}
                      </Box>
                      {trip.startingPrice ? (
                        <>
                          <Typography
                            variant="h6"
                            sx={{ fontSize: "16px", pt: 3 }}
                          >
                            Costing:
                          </Typography>
                          <TableContainer
                            component={Paper}
                            sx={{
                              borderRadius: 1,
                              mt: 2,
                              boxShadow: "0 0 10px #00000014",
                              border: "1px solid #00000020",
                            }}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" colSpan={3}>
                                    Room Sharing
                                  </TableCell>
                                  <TableCell align="right">
                                    Price per person
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="left" colSpan={3}>
                                    Quad Sharing
                                  </TableCell>
                                  <TableCell align="right">
                                    <s>
                                      INR{" "}
                                      {numeral(trip.startingPrice * 5).format(
                                        "0,0"
                                      )}
                                      /-
                                    </s>{" "}
                                    INR{" "}
                                    {numeral(trip.quadSharingAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left" colSpan={3}>
                                    Triple Sharing
                                  </TableCell>
                                  <TableCell align="right">
                                    <s>
                                      INR{" "}
                                      {numeral(
                                        trip.startingPrice +
                                          trip.startingPrice * 0.25
                                      ).format("0,0")}
                                      /-
                                    </s>{" "}
                                    INR{" "}
                                    {numeral(trip.tripleSharingAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left" colSpan={3}>
                                    Double Sharing
                                  </TableCell>
                                  <TableCell align="right">
                                    <s>
                                      INR{" "}
                                      {numeral(
                                        doublePrice + doublePrice * 0.25
                                      ).format("0,0")}
                                      /-
                                    </s>{" "}
                                    INR{" "}
                                    {numeral(trip.doubleSharingAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="left" colSpan={6}>
                                    <Typography
                                      variant="h6"
                                      component="small"
                                      sx={{
                                        fontSize: "15px",
                                      }}
                                    >
                                      NOTE: Extra Mattress will be Provided for
                                      Triple Sharing
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>
                      ) : null}
                      {customField.length > 0 ? (
                        <TableContainer
                          component={Paper}
                          sx={{
                            borderRadius: 1,
                            mt: 2,
                            boxShadow: "0 0 10px #00000014",
                            border: "1px solid #00000020",
                            // borderRadius: 2,
                            // boxShadow: "0 0 10px #0001 !important",
                            // border: "1px solid #0005",
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" colSpan={3}>
                                  Add Ons
                                </TableCell>
                                <TableCell align="right">
                                  Price per person
                                </TableCell>
                                {/* <TableCell align="center">
                                  <PersonRounded />
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customField.map((element) => (
                                <TableRow key={element._id}>
                                  <TableCell align="left" colSpan={3}>
                                    {element?.customItemName}
                                  </TableCell>
                                  <TableCell align="right">
                                    <s>
                                      INR{" "}
                                      {numeral(
                                        element?.customItemAmount * 0.25
                                      ).format("0,0")}
                                      /-
                                    </s>{" "}
                                    INR{" "}
                                    {numeral(element?.customItemAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                  {/* <TableCell>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <IconButton
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                          removeCustomCount(element._id)
                                        }
                                      >
                                        <RemoveRounded />
                                      </IconButton>
                                      <Box mx={2}>
                                        {customFieldCount[element._id] || 0}
                                      </Box>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          addCustomCount(element._id)
                                        }
                                      >
                                        <AddRounded />
                                      </IconButton>
                                    </Box>
                                  </TableCell> */}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}
                    </TabPanel>
                    <TabPanel value="inc">
                      <Typography variant="p" component="small">
                        <div
                          dangerouslySetInnerHTML={{ __html: trip.inclusion }}
                        />
                      </Typography>
                    </TabPanel>
                    <TabPanel value="exc">
                      <Typography variant="p" component="small">
                        <div
                          dangerouslySetInnerHTML={{ __html: trip.exclusion }}
                        />
                      </Typography>
                    </TabPanel>
                  </TabContext>
                </Card>
                {trip.note && (
                  <Card elevation={2} sx={{ m: 1, p: 2 }}>
                    <Typography variant="p" component="small">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: trip.note,
                        }}
                      />
                    </Typography>
                  </Card>
                )}
                {trip.galleryImages?.length > 0 && (
                  <Card elevation={2} sx={{ m: 1, px: 2, py: 3 }}>
                    <Typography
                      component="div"
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Photos:
                    </Typography>
                    <Divider sx={{ mb: 1 }} />
                    <ImageGallery
                      items={imgGallery}
                      showPlayButton={false}
                      thumbnailPosition="left"
                      showIndex={true}
                      showBullets={true}
                      autoPlay={true}
                      lazyLoad={true}
                    />
                  </Card>
                )}
                {trip?.youTubeVideoIds?.length > 0 && (
                  <Card elevation={2} sx={{ m: 1, px: 3, py: 3 }}>
                    <Typography
                      component="div"
                      variant="h6"
                      sx={{
                        fontSize: "16px",
                      }}
                    >
                      Videos:
                    </Typography>
                    <YouTubeSlider youTubeVideoIds={trip.youTubeVideoIds} />
                  </Card>
                )}
                <MakeYouHappier slidesToShow={2} />

                <Dialog
                  fullScreen={isFullScreen}
                  maxWidth="sm"
                  open={openBookingForm}
                  TransitionComponent={RightTransition}
                  onClose={() => setOpenBookingForm(false)}
                >
                  <DialogTitle sx={{ textAlign: "center" }}>
                    Booking
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => setOpenBookingForm(false)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseOutlined />
                  </IconButton>
                  <Divider />
                  <DialogContent>
                    <Stack spacing={3} className="mb-5">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          {...groupedDates}
                          name="startDate"
                          label="Choose Trip date"
                          format="DD/MMM/YYYY"
                          isRequired="true"
                          onChange={(value) => {
                            setSelectedDate(moment(value).toISOString());
                          }}
                          mindate={selectedDate}
                          slotProps={{
                            day: {
                              sx: {
                                "&.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)": {
                                  backgroundColor: "#fff",
                                },
                                "&.MuiPickersDay-root": {
                                  backgroundColor: "purple",
                                  color:"#fff"
                                },
                              },
                            },
                            textField: {
                              error: !!errors.selectedDate,
                              helperText: errors.selectedDate,
                            },
                          }}
                          shouldDisableDate={shouldDisableDates}
                          renderInput={(params) => <TextField {...params} />}
                          error={errors.selectedDate}
                        />
                      </LocalizationProvider>
                      {errors.selectedDate && (
                        <Box sx={{ color: "red", mt: 1 }}>
                          Please select a date.
                        </Box>
                      )}
                    </Stack>
                    <Stack spacing={3}>
                      {/* <Autocomplete
                        options={groupedDates}
                        size="small"
                        groupBy={(option) => option.mname}
                        getOptionLabel={(option) => option.formattedDate}
                        onChange={(e, value) => {
                          setSelectedDate(value?.date);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.formattedDate === value?.formattedDate
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select a date"
                            placeholder="Choose your batch date"
                            error={!!errors.selectedDate}
                            helperText={errors.selectedDate}
                          />
                        )}
                      /> */}
                      <TableContainer
                        component={Paper}
                        sx={{
                          borderRadius: 2,
                          boxShadow: "0 0 10px #0001 !important",
                          border: "1px solid #0005",
                          marginTop: "35px !important",
                        }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left" colSpan={3}>
                                Room Sharing
                              </TableCell>
                              <TableCell align="center">
                                Price per person
                              </TableCell>
                              <TableCell align="center">
                                <PersonRounded />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell align="left" colSpan={3}>
                                Quad Sharing
                              </TableCell>
                              <TableCell align="center">
                                <s>
                                  INR{" "}
                                  {numeral(trip.startingPrice * 0.7).format(
                                    "0,0"
                                  )}
                                  /-
                                </s>{" "}
                                INR{" "}
                                {numeral(trip.quadSharingAmount).format("0,0")}
                                /-
                              </TableCell>
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      quadBookingCount > 0 &&
                                      setQuadBookingCount(quadBookingCount - 1)
                                    }
                                  >
                                    <RemoveRounded />
                                  </IconButton>
                                  <Box mx={2}>{quadBookingCount}</Box>
                                  <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setQuadBookingCount(quadBookingCount + 1)
                                    }
                                  >
                                    <AddRounded />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" colSpan={3}>
                                Triple Sharing
                              </TableCell>
                              <TableCell align="center">
                                <s>
                                  INR{" "}
                                  {numeral(
                                    trip.startingPrice +
                                      trip.startingPrice * 0.25
                                  ).format("0,0")}
                                  /-
                                </s>{" "}
                                INR{" "}
                                {numeral(trip.tripleSharingAmount).format(
                                  "0,0"
                                )}
                                /-
                              </TableCell>
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      tripleBookingCount > 0 &&
                                      setTripleBookingCount(
                                        tripleBookingCount - 1
                                      )
                                    }
                                  >
                                    <RemoveRounded />
                                  </IconButton>
                                  <Box mx={2}>{tripleBookingCount}</Box>
                                  <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setTripleBookingCount(
                                        tripleBookingCount + 1
                                      )
                                    }
                                  >
                                    <AddRounded />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="left" colSpan={3}>
                                Double Sharing
                              </TableCell>
                              <TableCell align="center">
                                <s>
                                  INR{" "}
                                  {numeral(
                                    doublePrice + doublePrice * 0.25
                                  ).format("0,0")}
                                  /-
                                </s>{" "}
                                INR{" "}
                                {numeral(trip.doubleSharingAmount).format(
                                  "0,0"
                                )}
                                /-
                              </TableCell>
                              <TableCell>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <IconButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      doubleBookingCount > 0 &&
                                      setDoubleBookingCount(
                                        doubleBookingCount - 1
                                      )
                                    }
                                  >
                                    <RemoveRounded />
                                  </IconButton>
                                  <Box mx={2}>{doubleBookingCount}</Box>
                                  <IconButton
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      setDoubleBookingCount(
                                        doubleBookingCount + 1
                                      )
                                    }
                                  >
                                    <AddRounded />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <FormHelperText error={!!errors.roomSharing}>
                        {errors.roomSharing}
                      </FormHelperText>

                      {customField.length > 0 ? (
                        <TableContainer
                          component={Paper}
                          sx={{
                            borderRadius: 2,
                            boxShadow: "0 0 10px #0001 !important",
                            border: "1px solid #0005",
                          }}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left" colSpan={3}>
                                  Add Ons
                                </TableCell>
                                <TableCell align="center">
                                  Price per person
                                </TableCell>
                                <TableCell align="center">
                                  <PersonRounded />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {customField.map((element) => (
                                <TableRow key={element._id}>
                                  <TableCell align="left" colSpan={3}>
                                    {element?.customItemName}
                                  </TableCell>
                                  <TableCell align="center">
                                    <s>
                                      INR{" "}
                                      {numeral(
                                        element?.customItemAmount * 0.25
                                      ).format("0,0")}
                                      /-
                                    </s>{" "}
                                    INR{" "}
                                    {numeral(element?.customItemAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <IconButton
                                        variant="contained"
                                        color="secondary"
                                        onClick={() =>
                                          removeCustomCount(element._id)
                                        }
                                      >
                                        <RemoveRounded />
                                      </IconButton>
                                      <Box mx={2}>
                                        {customFieldCount[element._id] || 0}
                                      </Box>
                                      <IconButton
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          addCustomCount(element._id)
                                        }
                                      >
                                        <AddRounded />
                                      </IconButton>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : null}

                      <TextField
                        label="Full Name"
                        variant="outlined"
                        size="small"
                        onChange={(e) => setFullName(e.target.value)}
                        error={!!errors.fullName}
                        helperText={errors.fullName}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <DriveFileRenameOutlineRounded />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        size="small"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <EmailRounded />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <MuiTelInput
                        value={phone}
                        label="Mobile No"
                        defaultCountry="IN"
                        onChange={(ph) => setPhone(ph)}
                        size="small"
                        error={!!errors.phone}
                        helperText={errors.phone}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneRounded />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <LoadingButton
                        fullWidth
                        variant="contained"
                        loading={saving}
                        endIcon={<SendRounded />}
                        onClick={handleBooking}
                        sx={{
                          background: "#2f86a6",
                        }}
                      >
                        Submit
                      </LoadingButton>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Dialog
                  fullWidth
                  maxWidth="sm"
                  TransitionComponent={LeftTransition}
                  open={openBookingConfirmation}
                  onClose={() => {
                    setQr("");
                    setOpenBookingConfirmation(false);
                  }}
                >
                  <DialogTitle sx={{ textAlign: "center" }}>
                    Review Booking for {bookingData.tripName} (
                    {getDurationNameById(trip.durationId)})
                  </DialogTitle>
                  <Divider />
                  <DialogContent>
                    <Card
                      elevation={1}
                      sx={{
                        p: 1,
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                          <List dense>
                            <ListItem>
                              <ListItemIcon>
                                <DateRangeRounded />
                              </ListItemIcon>
                              <ListItemText primary={getTripsDurationDate()} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <PersonRounded />
                              </ListItemIcon>
                              <ListItemText primary={bookingData.username} />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <MailRounded />
                              </ListItemIcon>
                              <ListItemText primary={bookingData.email} />
                            </ListItem>

                            <ListItem>
                              <ListItemIcon>
                                <SmartphoneRounded />
                              </ListItemIcon>
                              <ListItemText primary={bookingData.mobileNo} />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            {qr ? (
                              <Tooltip
                                title="Scan the QR code for payment"
                                arrow
                                placement="top"
                              >
                                <CardMedia
                                  component="img"
                                  image={qr}
                                  loading="lazy"
                                  alt={"Template QR code"}
                                  sx={{
                                    maxHeight: 200,
                                    p: 3,
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              <CardMedia
                                component="img"
                                image={
                                  process.env.PUBLIC_URL + "/qr-template.png"
                                }
                                loading="eager"
                                alt="Template QR code"
                                sx={{
                                  maxHeight: 200,
                                  filter: "blur(4px)",
                                }}
                              />
                            )}
                            {!qr && (
                              <Tooltip title="Get QR Code">
                                <Button
                                  style={{
                                    top: "50%",
                                    left: "50%",
                                    borderRadius: 15,
                                    position: "absolute",
                                    transform: "translate(-50%, -50%)",
                                  }}
                                  size="small"
                                  variant="contained"
                                  color="warning"
                                  startIcon={<QrCodeScannerRounded />}
                                  onClick={() =>
                                    setOpenPaymentConfirmation(true)
                                  }
                                >
                                  Load
                                </Button>
                              </Tooltip>
                            )}
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              Rs.
                              {numeral(barCodePayableAmount).format("0,0")}
                              /-
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" sx={{ fontSize: "14px" }}>
                            Payment Details :
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TableContainer>
                            <Table size="small">
                              <TableHead
                                sx={{
                                  backgroundColor: "skyblue",
                                }}
                              >
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    sx={{ color: "white" }}
                                  >
                                    Total Cost
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ color: "white" }}
                                  >
                                    INR{" "}
                                    {numeral(bookingData.payableAmount).format(
                                      "0,0"
                                    )}
                                    /-
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    sx={{ color: "white" }}
                                  >
                                    Details
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  backgroundColor: "lightgrey",
                                }}
                              >
                                <TableRow>
                                  <TableCell align="center">
                                    Quad Sharing
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.quadSharing.totalCost
                                    ).format("0,0")}
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.quadSharing.perPersonCost
                                    ).format("0,0")}{" "}
                                    X {bookingData.quadSharing.bookingCount}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell align="center">
                                    Triple Sharing
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.tripleSharing.totalCost
                                    ).format("0,0")}
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.tripleSharing.perPersonCost
                                    ).format("0,0")}{" "}
                                    X {bookingData.tripleSharing.bookingCount}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="center">
                                    Double Sharing
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.doubleSharing.totalCost
                                    ).format("0,0")}
                                  </TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.doubleSharing.perPersonCost
                                    ).format("0,0")}{" "}
                                    X {bookingData.doubleSharing.bookingCount}
                                  </TableCell>
                                </TableRow>
                                {Object.keys(customFieldCount).map((id) => (
                                  <TableRow key={id}>
                                    <TableCell align="center">
                                      {
                                        customField.find(
                                          (item) => item._id === id
                                        ).customItemName
                                      }
                                    </TableCell>
                                    <TableCell align="center">
                                      INR{" "}
                                      {customField.find(
                                        (item) => item._id === id
                                      ).customItemAmount * customFieldCount[id]}
                                    </TableCell>
                                    <TableCell align="center">
                                      INR{" "}
                                      {
                                        customField.find(
                                          (item) => item._id === id
                                        ).customItemAmount
                                      }
                                      X {customFieldCount[id]}
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell align="center">GST @ 5%</TableCell>
                                  <TableCell align="center">
                                    INR{" "}
                                    {numeral(
                                      bookingData.grossAmount * 0.05
                                    ).format("0,0")}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "16px",
                            }}
                          >
                            Confirm your booing with us with an initial payment
                            of ₹ 5,000/- only.
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            component="div"
                          >
                            Note: The Remaining amount has to be paied by{" "}
                            <strong>{bookingData.username}</strong>
                          </Typography>
                          <Typography variant="caption" component="small">
                            *Plus 3% additional charges on next step
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                    <Typography
                      variant="h5"
                      sx={{
                        fontSize: "18px",
                        pt: 1,
                      }}
                    >
                      I don't want to pay Payment Gateway charges!!
                    </Typography>
                    <Typography variant="caption" component="small">
                      To save Payment Gateway charges Scan above QR code from
                      any UPI app. and send us screenshot at{" "}
                      <a href="https://wa.me/918287734456">+91 8287734456</a>
                    </Typography>
                  </DialogContent>
                </Dialog>
                <Dialog
                  open={openPaymentConfirmation}
                  TransitionComponent={RightTransition}
                  onClose={(event, reason) => {
                    if (reason && reason === "backdropClick") return;
                  }}
                >
                  <DialogContent>
                    Want to pay full Amount Rs.
                    {Math.ceil(bookingData.payableAmount)}/-
                  </DialogContent>

                  <DialogActions>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="secondary"
                      loading={loadingQrCode}
                      onClick={() => {
                        handleQrConfirmation(5000);
                        setOpenPaymentConfirmation(false);
                      }}
                    >
                      Preoceed with ₹5000
                    </LoadingButton>
                    <LoadingButton
                      size="small"
                      variant="contained"
                      loading={loadingQrCode}
                      onClick={() => {
                        handleQrConfirmation(bookingData.payableAmount);
                        setOpenPaymentConfirmation(false);
                      }}
                      autoFocus
                    >
                      Yes
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                <Card
                  elevation={2}
                  sx={{
                    m: 1,
                    p: 2,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>
                    The Reality of our trip
                  </Typography>
                  <Card
                    sx={{
                      borderRadius: "10px",
                      height: 245,
                      maxWidth: 350,
                      display: "flex",
                      justifyContent: "center",
                      my: 5,
                      alignItems: "center",
                    }}
                  >
                    <YouTube
                      videoId={"hL7wkkR961c"}
                      opts={{
                        height: "255",
                        width: "350",
                      }}
                    />
                  </Card>
                  <EnquiryFormTrips extras />
                </Card>
              </Grid>
            </Grid>
            <RelatedTrips
              tripName={trip.tripName}
              categories={trip.categories}
            />
          </Stack>
        </TripContainer>
      )}
    </Box>
  );
};

export default BookingTripView;
