import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import AppBar from "../layouts/ApplicationBar";
import Banner from "../layouts/Banner";
import Footer from "../layouts/Footer";
import SocialReach from "../layouts/SocialReach";

const AppContainer = ({
  banner = <Banner />,
  bannerImage = "/bg1.webp",
  bannerImgAlt = "Banner",
  ...props
}) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > window.innerHeight * 0.3) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Paper
      sx={{
        // backgroundColor: "#EFF5F5",
        // backgroundImage:"url('../dotgrid.png')",
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
        minHeight: "100vh",
        paddingRight: "0 !important",
      }}
    >
      <AppBar />
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          zIndex: 0,
          "@media(max-width:550px)": {
            height: "80vh",
          },
        }}
      >
        <img
          src={bannerImage}
          alt={bannerImgAlt}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            background: "#0006",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            "@media(max-width:550px)": {
              height: "100%",
            },
          }}
        />
      </Box>

      {banner}
      <Box
        sx={{
          position: "absolute",
          bottom: 30,
          width: "100%",
          zIndex: 1,
          textAlign: "center",
          "@media(max-width:550px)": {
            bottom: 150,
          },
        }}
      >
        <SocialReach />
      </Box>
      <Box
        sx={{
          mb: 2,
          padding: 2,
          backgroundColor: "white",
        }}
      >
        {props.children}
      </Box>
      <Footer />
      {isScrolled ? (
        <a href="https://wa.me/918287734456" target="_blank">
          <img src="/whatsapp_icon.svg" class="callIcon"></img>
        </a>
      ) : (
        "test"
      )}
    </Paper>
  );
};

export default AppContainer;
