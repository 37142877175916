import { Grid, Link, Typography } from "@mui/material";

export default function Copyright(props) {
  return (
    <Grid
      container
      sx={{
        display: "block",
        marginTop: "34px",
        borderTop: "1px solid #ffffff3b",
      }}
    >
      <Typography
        variant="caption"
        color="#fff"
        align="center"
        display="block"
        sx={{ padding: 2, textAlign: "center" }}
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://memorableyourtip.com">
          MemorableYourTrip
        </Link>{" "}
        {new Date().getFullYear()}
        {". "}
        {"Deisgned & Developed by "}
        <Link color="inherit" href="https://inforises.com/">
          Inforises Technologies
        </Link>{" "}
      </Typography>
    </Grid>
  );
}
