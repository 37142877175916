import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import useTostify from '../../hooks/useTostify';
import { getCurrentDomain } from '../common/utils';
import TripListView from './TripListView';
import Preloader from './../common/Preloader';

const AsyncTripAutocomplete = ({ mappedTrips, setMappedTrips }) => {
  const [open, setOpen] = useState(false);
  const [trips, setTrips] = useState([]);
  const { setNotification } = useTostify();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (!open) {
      return undefined;
    }
    setLoading(true);
    axios
      .get(`${getCurrentDomain()}/trips`, {
        params: {
          for: 'category',
        },
      })
      .then((res) => {
        setLoading(false);
        if (active) {
          setTrips(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotification({
          open: true,
          severity: 'error',
          msg: 'Oops Error while fetching Trips!!',
        });
      });

    return () => {
      active = false;
    };
  }, [open, setNotification]);

  useEffect(() => {
    if (!open) {
      setTrips([]);
    }
  }, [open]);
  return (
    <Autocomplete
      multiple
      size='small'
      limitTags={2}
      id='asynchronous-trip'
      name='tripIds'
      options={trips}
      value={mappedTrips}
      disableCloseOnSelect
      noOptionsText='No Trips'
      onChange={(e, value) => {
        setMappedTrips(value);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.tripName}
      renderOption={(props, option, { selected }) => (
        <TripListView {...option} {...props} selected={selected} />
      )}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label='Mapped Trips (Optional)'
          placeholder='Select Trips...'
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (
              <>
                {loading ? (
                  // <Preloader />
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncTripAutocomplete;
