import {
  CallRounded,
  DriveFileRenameOutlineRounded,
  EmailRounded,
  LandscapeRounded,
  PhoneRounded,
  SendRounded,
  WhatsApp,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React, { useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain, validateEmail } from "../common/utils";

const EnquiryForm = ({ categoryName, handleClose, extras = false }) => {
  const { setNotification } = useTostify();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [destination, setDestination] = useState("");
  const [errors, setErrors] = useState({});
  const [saving, setSaving] = useState(false);

  const handleSubmitLead = () => {
    if (!validateLeadForm()) return;
    let newLead = {
      categoryName,
      username,
      email,
      mobileNo,
      destination,
    };
    setSaving(true);
    axios
      .post(`${getCurrentDomain()}/leads`, newLead)
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "We got you, Thanks for booking!!",
        });

        setSaving(false);
        if (handleClose) handleClose(false);
        clearForm();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while saving Category!!",
        });
        setSaving(false);
      });
  };

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setMobileNo("");
    setDestination("");
    setErrors({});
  };

  const validateLeadForm = () => {
    let valid = true;
    const newErrors = {};
    if (!username.trim()) {
      newErrors.username = "Name is required!!";
      valid = false;
    }
    if (validateEmail(email.trim())) {
      newErrors.email = "Invalid email!!";
      valid = false;
    }
    if (!matchIsValidTel(mobileNo)) {
      newErrors.mobileNo = "Invalid Phone Number!!";
      valid = false;
    }

    if (!destination.trim()) {
      newErrors.destination = "Name is required!!";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{ border: "1px dashed #2f86a6" }}
    >
      <Grid item xs={12} sm={6} md={6}>
        <Card
          sx={{
            p: 2,
            boxShadow: "0 0 50px #00000000 !important",
            minWidth: { lg: "400px" },
            "@media(max-width:767px)": {
              padding: "5px !important",
            },
          }}
          elevation={0}
        >
          <Typography
            component={"h4"}
            sx={{
              textAlign: "center",
              fontWeight: "700 !important",
              padding: "16px 0 0 0 !important",
              "@media(max-width:550px)": {
                fontSize: "24px",
              },
            }}
          >
            Struggling to make a decision?
          </Typography>
          <CardContent>
            <Stack spacing={1}>
              <TextField
                label="Name"
                variant="outlined"
                size="small"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                error={!!errors.username}
                helperText={errors.username}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DriveFileRenameOutlineRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <MuiTelInput
                value={mobileNo}
                label="Mobile No"
                defaultCountry="IN"
                onChange={(ph) => setMobileNo(ph)}
                size="small"
                error={!!errors.mobileNo}
                helperText={errors.mobileNo}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Destination"
                variant="outlined"
                size="small"
                value={destination}
                onChange={(e) => setDestination(e.target.value)}
                error={!!errors.destination}
                helperText={errors.destination}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LandscapeRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                fullWidth
                loading={saving}
                variant="contained"
                endIcon={<SendRounded />}
                onClick={handleSubmitLead}
                sx={{ background: "#2f86a6 !important" }}
              >
                Get Quote
              </LoadingButton>
              {extras && (
                <>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<WhatsApp />}
                    color="success"
                    href="https://wa.me/918287734456"
                  >
                    Whatsapp
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    startIcon={<CallRounded />}
                    color="warning"
                    href="tel:918287734456"
                  >
                    Call Now
                  </Button>
                  <Card
                    sx={{
                      paddingx: 2,
                      width: "100%",
                      boxShadow: "0 0 0 #0000 !important",
                    }}
                    elevation={0}
                    variant="outlined"
                  >
                    <CardHeader
                      title="Why Memorable Your Trip"
                      sx={{ backgroundColor: "skyblue" }}
                    />
                    <CardContent>
                      <strong>Community of Travellers</strong>: <br />
                      Community of happy travellers on Instagram.
                      <br />
                      <br />
                      <strong>Women Travellers Centric Facilities</strong>:
                      <br /> Catering to all-girl trips with female room
                      sharing, female coordinators, and tailored amenities.
                      <br />
                      <br />
                      <strong>
                        Embarking on adventures to offbeat destinations.
                      </strong>
                      :<br /> In addition to mainstream destinations,
                      discovering over 100 off-the-beaten-path travel spots.
                      <br />
                      <br />
                      <strong>The vitality of youth.</strong>:<br /> A youth-led
                      company fostering an open and secure environment for young
                      travelers.
                      <br />
                      <br />
                      <strong>Tailored Travel Plans</strong>:<br /> Expert
                      travel planners crafting optimal itineraries to suit your
                      requirements.
                    </CardContent>
                  </Card>
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography
          component={"h4"}
          sx={{
            fontWeight: 600,
            color: "black",
            fontSize: "20px",
            pt: 4,
            textAlign: "center",
            paddingLeft: 4,
            pr: 4,
            "@media(max-width:767px)": { pt: 1 },
          }}
        >
          Feel uncertain? Let our team of travel experts take the wheel.
          <br></br>
          <span style={{ color: "#2f86a6" }}>
            Trust their authentic insights to guide you through your queries.
          </span>
        </Typography>
        <Typography
          component={"h4"}
          sx={{
            fontWeight: 400,
            color: "white",
            fontSize: "14px",
            pt: 4,
            textAlign: "center",
            background: "#2f86a6",
            margin: "15px 25px",
            padding: "8px",
          }}
        >
          No spam calls
        </Typography>

        <Typography
          component={"h4"}
          sx={{
            fontWeight: 400,
            color: "white",
            fontSize: "14px",
            pt: 4,
            textAlign: "center",
            background: "#2f86a6",
            margin: "15px 25px",
            padding: "8px",
          }}
        >
          Personalized query resolution
        </Typography>

        <Typography
          component={"h4"}
          sx={{
            fontWeight: 400,
            color: "white",
            fontSize: "14px",
            pt: 4,
            textAlign: "center",
            background: "#2f86a6",
            margin: "15px 25px",
            padding: "8px",
          }}
        >
          Prompt responses to your calls
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EnquiryForm;
