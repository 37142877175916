import { Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";

const socialReachs = [
  {
    title: "Community",
    subtitle: "On Instagram",
    icon: "/insta.svg",
    alt: "Insta Reach",
    url: "https://www.instagram.com/memorableyourtrip/",
  },

  {
    title: "Google",
    subtitle: "Review",
    icon: "/google.png",
    alt: "Goole Review",
    url: "https://maps.app.goo.gl/brmGQadDQRsrgDDY7/",
  },
  {
    title: "Itineraries",
    subtitle: "",
    icon: "/map.svg",
    alt: "Map Itineraries",
    url: "#",
  },
  {
    title: "No Cost",
    subtitle: "EMI",
    icon: "/emi.png",
    alt: "No Cost EMI",
    url: "#",
  },
];
const SocialReach = () => {
  return (
    <Grid container spacing={2} justifyContent="space-around">
      {socialReachs.map((sr, index) => (
        <Grid item xs={6} sm={6} md={3} lg={3} xl={3} key={index}>
          <Link
            href={sr.url}
            className="HoverLink"
            sx={{
              textDecoration: "none",
              textAlign: "center",
              color: "white",
              fontWeight: 500,
              fontSize: "18px",
              "@media(max-width: 767px)": {
                fontSize: "13px",
                fontWeight: 300,
              },
            }}
          >
            <img alt="Logo" src={sr.icon} className="socialImg" />
            <Stack direction="column" spacing={2}>
              <Typography>
                {sr.title}
                <br />
                {sr.subtitle}
              </Typography>
            </Stack>
            {/* <br /> */}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default SocialReach;
