import {
  DeleteRounded,
  EditRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useTostify from "../../hooks/useTostify";
import { formatDate, getCurrentDomain } from "../common/utils";

const CategoryCard = ({
  _id,
  slug,
  categoryName,
  categoryDescription,
  coverImage,
  trips,
  handleOpenEdit,
  reloadCategories,
}) => {
  const [hovered, setHovered] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { setNotification } = useTostify();
  const handleDelete = () => {
    setDeleting(true);
    axios
      .delete(`${getCurrentDomain()}/categories`, {
        params: {
          categoryId: _id,
        },
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Successfully Deleted!!",
        });
        setDeleting(false);
        reloadCategories();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while deleting Category!!",
        });
        setDeleting(false);
      });
  };
  return (
    <Card
      raised={hovered}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      sx={{ maxWidth: { xs: 300, sm: 325, md: 350 }, m: 1 }}
    >
      <CardActionArea LinkComponent={Link} to={`/${slug}`}>
        <CardMedia
          component="img"
          image={coverImage.url}
          alt={categoryName}
          loading="lazy"
          sx={{
            height: { xs: "200px", sm: "225px", md: "250px" },
            width: { xs: "300px", sm: "325px", md: "350px" },
          }}
          title={categoryName}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {categoryName}
          </Typography>
        </CardContent>
        <Divider />
      </CardActionArea>
      <CardActions disableSpacing>
        <Tooltip title="Edit">
          <LoadingButton
            aria-label="Edit Trip"
            color="inherit"
            size="small"
            onClick={() => handleOpenEdit({ _id })}
            startIcon={<EditRounded />}
          >
            Edit
          </LoadingButton>
        </Tooltip>
        <Tooltip title="Delete">
          <LoadingButton
            loading={deleting}
            aria-label="Delete Trip"
            color="error"
            size="small"
            onClick={handleDelete}
            startIcon={<DeleteRounded />}
          >
            Delete
          </LoadingButton>
        </Tooltip>
        <Tooltip title={`Click to ${expanded ? "Collapsed" : "Expand"}`}>
          <IconButton
            aria-label="Show more"
            color="info"
            size="small"
            onClick={handleExpandClick}
            sx={{
              marginLeft: "auto",
            }}
          >
            {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="subtitle2" component="div">
              Mapped Trips :
            </Typography>
            {trips.map((t) => (
              <Tooltip
                key={t._id}
                title={
                  <Typography variant="caption">
                    Trip name: {t.tripName} <br />
                    Pickup location: {t.pickupLocation} <br />
                    Drop location: {t.dropLocation} <br />
                    Trip Dates: {formatDate(t.tripDates)}
                  </Typography>
                }
              >
                <Chip
                  variant="outlined"
                  size="small"
                  label={t.tripName}
                  sx={{ m: 1 }}
                />
              </Tooltip>
            ))}
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CategoryCard;
