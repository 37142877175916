import { Box, Card, CardHeader, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCurrentDomain } from "../components/common/utils";
import AppContainer from "../components/containers/AppContainer";
import CuratedBanner from "../components/layouts/CuratedBanner";
import InDoubtCantDecide from "../components/layouts/InDoubtCantDecide";
import MakeYouHappier from "../components/layouts/MakeYouHappier";
import RealityOfTrip from "../components/layouts/RealityOfTrip";
import ReasonToCountOnUs from "../components/layouts/ReasonToCountOnUs";
import TripGridWithUpcoming from "../components/layouts/TripGridWithUpcoming";
import useTostify from "../hooks/useTostify";

const UpcomingView = () => {
  const { monthSlug } = useParams();
  const { setNotification } = useTostify();
  const [monthName, setMonthName] = useState(monthSlug);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [trips, setTrips] = useState([]);

  const filterTripsByMonthsOnly = (data, monthName) => {
    let result = new Map();
    data.forEach((category) => {
      category.trips.forEach((trip) => {
        trip.tripDates.forEach((dateArray) => {
          dateArray.forEach((date) => {
            const tripDate = new Date(date);
            if (
              tripDate.toLocaleString("en-US", { month: "long" }) === monthName
            ) {
              const tripId = trip._id;
              const existingTrip = result.get(tripId);
              if (existingTrip) {
                existingTrip.categoryNames.push(category.categoryName);
              } else {
                const tripWithCategory = {
                  ...trip,
                  categoryNames: [category.categoryName],
                };
                result.set(tripId, tripWithCategory);
              }
            }
          });
        });
      });
    });
    return Array.from(result.values()).map((trip) => ({
      ...trip,
      categoryNames: Array.from(trip.categoryNames),
    }));
  };

  useEffect(() => {
    setLoadingCategory(true);
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          for: "category",
        },
      })
      .then((res) => {
        let upcomingTrips = filterTripsByMonthsOnly(res.data, monthSlug);
        setTrips(upcomingTrips);
        setLoadingCategory(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingCategory(false);
      });
  }, [setNotification]);

  return (
    <AppContainer banner={<CuratedBanner monthName={monthSlug} />}>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          marginX: { xs: 1, md: 13 },
        }}
      >
        <Card
          sx={{
            boxShadow: "0 0 2px #8c8c8c5c !important",
            // border: "1px solid #c9c9c95c",
            marginTop: "30px",
            marginBottom: "40px",
            borderRadius: "10px",
            "@media(max-width:767px)": {
              marginTop: "20px",
              marginBottom: "30px",
            },
          }}
        >
          <CardHeader
            title={
              <Typography
                variant="h4"
                sx={{
                  fontSize: "25px",
                  color: "#2f86a6 !important",
                  fontWeight: 700,
                  background: "#efefef !important",
                  padding: "14px 20px",
                  "@media(max-width:767px)": {
                    fontSize: "28px",
                  },
                  "@media(max-width:550px)": {
                    fontSize: "18px",
                  },
                }}
              >
                {`Book Upcoming Trips of  ${monthSlug} at Best Price`}
              </Typography>
            }
            sx={{ padding: "0px !important" }}
          />
        </Card>

        <TripGridWithUpcoming
          category={monthName}
          monthName={monthName}
          trips={trips || []}
          loadingTrips={loadingCategory}
        />
      </Box>
      <ReasonToCountOnUs />
      <MakeYouHappier />
      <RealityOfTrip />
      <InDoubtCantDecide />
    </AppContainer>
  );
};

export default UpcomingView;
