import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentDomain } from "../components/common/utils";
import useTostify from "../hooks/useTostify";

const AccessDenied = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { setNotification } = useTostify();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsername("");
    setPassword("");
    setShowPassword(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(`${getCurrentDomain()}/user/login`, {
        username,
        password,
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Successfully LoggedIn!!",
        });
        localStorage.setItem("accessToken", res.data.accessToken);
        setLoading(false);
        handleClose();
        navigate("/admin/dashboard");
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Username or Password is not correct, Please try again.",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          backgroundColor: "#EFF5F5",
        }}
      >
        <img
          width={isSmallScreen ? "60%" : "30%"}
          alt="Access Denied"
          src={process.env.PUBLIC_URL + "/403.svg"}
        />
        <Typography variant="caption" sx={{ textAlign: "center" }}>
          Oops! you do not have enough privilege to see requested page.
        </Typography>
        <Button
          variant="text"
          sx={{ textTransform: "none" }}
          onClick={handleClickOpen}
        >
          Login
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{ textAlign: "center" }}>Login</DialogTitle>
          <Divider />
          <DialogContent>
            <Stack direction="column" spacing={2}>
              <TextField
                fullWidth
                required
                id="username"
                size="small"
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                fullWidth
                required
                size="small"
                id="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffRounded />
                        ) : (
                          <VisibilityRounded />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              size="small"
              onClick={handleSubmit}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AccessDenied;
