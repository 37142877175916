import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import FilterDropDown from "./FilterDropDown";
import TripCard from "./TripCard";

export default function Carousel({
  title = "Upcoming Trips",
  trips = [],
  navigationLink = "",
  filter,
}) {
  const [cachedTrips, setCachedTrips] = useState(trips);
  // const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = {
    speed: 1000,
    slidesToShow: Math.min(4, cachedTrips.length),
    slidesToScroll: 1,
    // accessibility: true,
    infinite: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    // fade: true,

    nextArrow: <ArrowForward color="primary" fontSize="small" />,
    prevArrow: <ArrowBack color="primary" fontSize="small" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          arrows: false,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1.5,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Box sx={{ marginBottom: "50px" }}>
      <FilterDropDown
        title={title}
        trips={trips}
        filter={filter}
        navigationLink={navigationLink}
        setCachedTrips={setCachedTrips}
      />
      <center>
        {cachedTrips?.length > 0 ? (
          <Slider {...settings}>
            {cachedTrips.map((td, i) => (
              <TripCard categoryName={title} key={i} {...td} />
            ))}
          </Slider>
        ) : (
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              mb: 2,
              textAlign: "center",
            }}
          >
            No Data found
          </Typography>
        )}
      </center>
    </Box>
  );
}
