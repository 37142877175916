import {
  AccessTimeRounded,
  DeleteRounded,
  EditRounded,
  EventRounded,
  PlaceRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  Chip,
  Stack,
  Tooltip,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import axios from "axios";
import numeral from "numeral";
import { useState } from "react";
import { Link } from "react-router-dom";
import useTostify from "../../hooks/useTostify";
import {
  formatDate,
  getCurrentDomain,
  getDurationNameById,
} from "../common/utils";

const GridTripCard = ({
  coverImage,
  tripName = "Thailand Fullmoon New Year Party with Flight",
  slug,
  pickupLocation = "Delhi",
  durationId = 1,
  startingPrice = 88567,
  tripDates = [],
  editable = false,
  reloadTrips,
  handleOpenEdit,
  categoryName,
  _id,
}) => {
  const [hovered, setHovered] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { setNotification } = useTostify();

  const handleDelete = () => {
    setDeleting(true);
    axios
      .delete(`${getCurrentDomain()}/trips`, {
        params: {
          tripId: _id,
        },
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Successfully Deleted!!",
        });
        setDeleting(false);
        reloadTrips();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while deleting Trip!!",
        });
        setDeleting(false);
      });
  };

  return (
    <center>
      <Card
        className="zoom_effect"
        raised={hovered}
        sx={{
          maxWidth: { xs: 250, sm: 325, md: 390 },
          borderRadius: "6px",
          boxShadow: "rgba(0, 0, 0, 0.08) 0px 1px 10px 1px !important",
          border: "1px solid rgba(1, 95, 116, 0.1)",
        }}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <CardActionArea
          LinkComponent={Link}
          to={`/trip/${slug}?id=${categoryName}`}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   padding: "10px",
              "@media(max-width:767px)": { padding: "8px" },
            }}
          >
            <CardMedia
              component="img"
              image={coverImage.url}
              alt={tripName}
              title={tripName}
              sx={{
                height: {
                  xs: "115px",
                  sm: "120px",
                  md: "140px",
                  lg: "160px",
                  xl: "180px",
                },
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                borderRadius: "6px 6px 0 0",
              }}
              loading="lazy"
            />
          </div>
          <CardContent sx={{ padding: "5px 10px" }}>
            {/* <Box display="flex" alignItems="center"> */}

            {startingPrice ? (
              <Typography
                className="priceLabelGrid"
                gutterBottom
                variant="h6"
                component="div"
                ml={1}
                sx={{
                  fontWeight: 600,
                  fontSize: "15px",
                  letterSpacing: "0px",
                  "@media(max-width:767px)": {
                    fontWeight: 600,
                    fontSize: "14px",
                    marginLeft: "0px",
                  },
                }}
              >
                <Typography
                  component="span"
                  sx={{ fontSize: "13px !important" }}
                >
                  <s>
                    ₹{""}
                    {numeral(startingPrice + startingPrice * 0.25).format(
                      "0,0"
                    )}
                    /-
                  </s>
                </Typography>
                {` ₹${numeral(startingPrice).format("0,0")}/-`}
                {""}
                {/* <Chip
                  sx={{
                    background:"#0000"
                  }}
                    label={
                      !tripDates || !tripDates[0] ? "Customized" : "Group Trip"
                    }
                    size="small"
                    color={!tripDates || !tripDates[0] ? "warning" : "default"}
                  /> */}
              </Typography>
            ) : (
              <Typography
                className="priceLabel"
                gutterBottom
                variant="h6"
                component="div"
                ml={1}
                sx={{
                  fontWeight: 600,
                  fontSize: "15px",
                  letterSpacing: "0px",
                  "@media(max-width:767px)": {
                    fontSize: "14px",
                    marginLeft: "0px",
                  },
                }}
              >
                Customizable
              </Typography>
            )}
            {/* </Box> */}
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                fontWeight: "500 !important",
                fontSize: "19px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                textAlign: "left",
                marginLeft: "3px",
                WebkitLineClamp: 1,
                color: "rgba(12, 12, 12, 0.85)",
                "@media(max-width:767px)": {
                  fontWeight: 600,
                  fontSize: ".875rem",
                },
              }}
            >
              {tripName}
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 2 }}
              justifyContent="space-between"
            >
              <Tooltip
                title={`Trip duration ${getDurationNameById(durationId, true)}`}
              >
                <Box display="flex" alignItems="center">
                  <AccessTimeRounded sx={{ color: "#2f86a6" }} />
                  <Typography
                    sx={{ textAlign: "left" }}
                    variant="subtitle2"
                    color="rgba(12, 12, 12, 0.75)"
                    ml={1}
                  >
                    {getDurationNameById(durationId)}
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        "@media(max-width:767px)": {
                          fontSize: ".7rem",
                        },
                      }}
                    >
                      <Chip
                        label={
                          !tripDates || !tripDates[0]
                            ? "Customized"
                            : "(Group Trip)"
                        }
                        size="small"
                        color={
                          !tripDates || !tripDates[0] ? "warning" : "default"
                        }
                        sx={{
                          "@media(max-width:767px)": {
                            fontSize: ".7rem",
                          },
                        }}
                      />
                    </Stack>
                  </Typography>
                </Box>
              </Tooltip>
              <Tooltip title={`Trip will start from ${pickupLocation}`}>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    "@media(max-width:1024px)": {
                      display: "none",
                    },
                  }}
                >
                  <PlaceRounded sx={{ color: "#2f86a6" }} />
                  <Typography
                    variant="subtitle2"
                    color="rgba(12, 12, 12, 0.75)"
                    ml={1}
                    sx={{ textAlign: "left" }}
                  >
                    {pickupLocation}
                  </Typography>
                </Box>
              </Tooltip>
            </Stack>

            <Tooltip title={formatDate(tripDates)}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ marginTop: "10px" }}
              >
                <EventRounded
                  sx={{
                    color: "#2f86a6",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                  }}
                />
                <Typography
                  variant="body2"
                  ml={1}
                  color="rgba(12, 12, 12, 0.75)"
                  sx={{
                    fontWeight: 500,
                    textAlign: "left",
                    "@media(max-width:767px)": {
                      fontSize: ".7rem",
                    },
                  }}
                >
                  {formatDate(tripDates).substring(0, 20)}
                  {tripDates[0]?.length >= 5 && "..."}
                </Typography>
              </Box>
            </Tooltip>
          </CardContent>
          <Typography
            component={"div"}
            variant="body2"
            sx={{
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor: "#2f86a6 !important",
              color: "#fff",
              fontWeight: "600",
              padding: "5px",
              margin: "0px 10px 10px",
            }}
          >
            View Details
          </Typography>
          <CardActions
            sx={{
              backgroundColor: "#eaeef6",
              padding: "2px 10px",
              "@media(max-width:550px)": {
                padding: "2px 5px",
              },
              "& .MuiTypography-root": { fontSize: "11px" },
            }}
          >
            <Typography
              component="span"
              variant="body2"
              sx={{
                fontWeight: 400,
                fontSize: "18px",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                WebkitLineClamp: 1,
              }}
            >
              or <strong>3</strong> monthly payment of{" "}
              <strong>{`₹ ${numeral(startingPrice / 3).format(
                "0,0"
              )} /-`}</strong>
            </Typography>
            <Typography
              component="h1"
              variant="subtitle2"
              sx={{ color: "#2f86a6", ml: 0.5 }}
            >
              (T&C)
            </Typography>
          </CardActions>
        </CardActionArea>
        {editable && (
          <Stack direction="row" spacing={1} p={1}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleOpenEdit({ _id })}
              startIcon={<EditRounded />}
            >
              Edit
            </Button>
            <Tooltip title="Delete Trip">
              <LoadingButton
                fullWidth
                loading={deleting}
                variant="outlined"
                onClick={handleDelete}
                color="error"
                startIcon={<DeleteRounded />}
              >
                Delete
              </LoadingButton>
            </Tooltip>
          </Stack>
        )}
      </Card>
    </center>
  );
};

export default GridTripCard;
