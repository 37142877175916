import {
  AddCircleRounded,
  CategoryRounded,
  CheckCircleRounded,
  FileUploadRounded,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminBottomNavigation from "../components/layouts/AdminBottomNavigation";
import useAuth from "../hooks/useAuth";

import { LoadingButton } from "@mui/lab";
import axios from "axios";
import Preloader from "../components/common/Preloader";
import { getCurrentDomain } from "../components/common/utils";
import AsyncTripAutocomplete from "../components/layouts/AsyncTripAutocomplete";
import CategoryCard from "../components/layouts/CategoryCard";
import ImageChip from "../components/layouts/ImageChip";
import ImageUpload from "../components/layouts/ImageUpload";
import Editor from "../components/layouts/rich-text-editor/editor";
import useTostify from "../hooks/useTostify";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminCategories = () => {
  const [menu, setMenu] = useState("/admin/categories");
  const [categoryName, setCategoryName] = useState("");
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState("");
  const [mappedTrips, setMappedTrips] = useState([]);
  const [bannerFiles, setBannerFiles] = useState([]);
  const [coverFiles, setCoverFiles] = useState([]);
  const [type, setType] = useState("");

  const [isInternationalCategory, setIsInternationalCategory] = useState(false);
  const [isDomesticCategory, setIsDomesticCategory] = useState(false);
  const [isUpcomingCategory, setIsUpcomingCategory] = useState(false);
  const [isForFooter, setIsForFooter] = useState(false);
  const [isPromoHomeTop, setIsPromoHomeTop] = useState(false);
  const [isPromoHomeBottom, setIsPromoHomeBottom] = useState(false);
  const [isPromoCatTop, setIsPromoCatTop] = useState(false);
  const [isForSlider, setIsForSlider] = useState(false);
  const [isForCustomize, setIsForCustomize] = useState(false);

  const [coverImageDim, setCoverImageDim] = useState({
    width: 1080,
    height: 1350,
  });

  const [bannerImage, setBannerImage] = useState({});
  const [coverImage, setCoverImage] = useState({});
  const [saving, setSaving] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [catId, setCatId] = useState("");
  const { isAuthenticated } = useAuth();
  const { setNotification } = useTostify();
  const navigate = useNavigate();

  const handleUploadImage = (uploadFor) => {
    const newErrors = {};
    let dataFile = null;
    let url = `${getCurrentDomain()}/resource/`;
    if (uploadFor === "banner") {
      if (bannerFiles.length <= 0) {
        newErrors.bannerImage = "Banner Image is required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-banner";
      dataFile = { bannerImage: bannerFiles[0] };
      setUploadingBanner(true);
    } else if (uploadFor === "cover") {
      if (coverFiles.length <= 0) {
        newErrors.coverImage = "Cover Image is required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-cover";
      dataFile = { coverImage: coverFiles[0] };
      setUploadingCover(true);
    }
    axios
      .post(url + "?for=category", dataFile, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Uploaded Successfully!!",
        });
        if (uploadFor === "banner") {
          setBannerImage(res.data);
          setBannerFiles([]);
          setUploadingBanner(false);
        } else if (uploadFor === "cover") {
          setCoverImage(res.data);
          setCoverFiles([]);
          setUploadingCover(false);
        }
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while Uploading!!",
        });
        if (uploadFor === "banner") {
          setUploadingBanner(false);
        } else if (uploadFor === "cover") {
          setUploadingCover(false);
        }
      });
  };

  const handleSave = () => {
    if (!validateTripForm()) return;
    setSaving(true);
    if (edit) {
      axios
        .put(
          `${getCurrentDomain()}/categories`,
          {
            categoryName,
            categoryDescription: description,
            trips: mappedTrips.map(({ _id }) => _id),
            coverImage,
            bannerImage,
            isInternationalCategory,
            isDomesticCategory,
            isUpcomingCategory,
            isForFooter,
            isForSlider,
            isForCustomize,
            isCurated: type === "cur",
            isPromoHomeTop,
            isPromoHomeBottom,
            isPromoCatTop,
          },
          {
            params: {
              categoryId: catId,
            },
          }
        )
        .then((res) => {
          setNotification({
            open: true,
            severity: "success",
            msg: "Successfully Saved!!",
          });
          setSaving(false);
          handleCloseDialog();
          reloadCategories();
        })
        .catch((err) => {
          setNotification({
            open: true,
            severity: "error",
            msg: "Oops Error while saving Category!!",
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`${getCurrentDomain()}/categories`, {
          trips: mappedTrips.map(({ _id }) => _id),
          categoryName,
          categoryDescription: description,
          coverImage,
          bannerImage,
          isInternationalCategory,
          isDomesticCategory,
          isUpcomingCategory,
          isForFooter,
          isForSlider,
          isForCustomize,
          isCurated: type === "cur",
          isPromoHomeTop,
          isPromoHomeBottom,
          isPromoCatTop,
        })
        .then((res) => {
          setNotification({
            open: true,
            severity: "success",
            msg: "Successfully Saved!!",
          });
          setSaving(false);
          handleCloseDialog();
          reloadCategories();
        })
        .catch((err) => {
          setNotification({
            open: true,
            severity: "error",
            msg: "Oops Error while saving Category!!",
          });
          setSaving(false);
        });
    }
  };

  const handleOpenEdit = (catId) => {
    setCatId(catId?._id);
    const foundCategory = categories.find((cat) => cat._id === catId?._id);
    prepareEditCategoryForm(foundCategory);
    setEdit(true);
    setFormDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setFormDialogOpen(false);
    setCategoryName("");
    setDescription("");
    setCoverImage({});
    setCoverFiles([]);
    setBannerImage({});
    setBannerFiles([]);
    setMappedTrips([]);
    setIsInternationalCategory(false);
    setIsDomesticCategory(false);
    setIsUpcomingCategory(false);
    setIsForFooter(false);
    setIsForSlider(false);
    setIsForCustomize(false);
    setIsPromoHomeTop(false);
    setIsPromoHomeBottom(false);
    setIsPromoCatTop(false);
    setType("");
    setEdit(false);
  };

  const prepareEditCategoryForm = (cat) => {
    setCategoryName(cat.categoryName);
    setDescription(cat.categoryDescription);
    setMappedTrips(cat.trips);
    setCoverImage(cat.coverImage);
    setBannerImage(cat.bannerImage);
    setIsInternationalCategory(Boolean(cat.isInternationalCategory));
    setIsDomesticCategory(Boolean(cat.isDomesticCategory));
    setIsUpcomingCategory(Boolean(cat.isUpcomingCategory));
    setIsForFooter(Boolean(cat.isForFooter));
    setIsForSlider(Boolean(cat.isForSlider));
    setIsForCustomize(Boolean(cat.isForCustomize));
    setType(cat.isCurated ? "cur" : "pro");
    setIsPromoHomeTop(Boolean(cat.isPromoHomeTop));
    setIsPromoHomeBottom(Boolean(cat.isPromoHomeBottom));
    setIsPromoCatTop(Boolean(cat.isPromoCatTop));
  };

  useEffect(() => {
    if (!isAuthenticated()) navigate("/access-denied");
  });
  const validateTripForm = () => {
    let valid = true;
    const newErrors = {};

    if (!categoryName.trim()) {
      newErrors.categoryName = "Category Name is required!!";
      valid = false;
    }

    if (!description.trim()) {
      newErrors.description = "Category Description is required!!";
      valid = false;
    }

    if (!edit && !coverImage.key) {
      newErrors.coverImage = "Cover Image is required!!";
      valid = false;
    }

    if (!edit && !bannerImage.key) {
      newErrors.bannerImage = "Banner Image is required!!";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const reloadCategories = useCallback(() => {
    setLoadingCategories(true);
    axios
      .get(`${getCurrentDomain()}/categories`)
      .then((res) => {
        setCategories(res.data);
        setLoadingCategories(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Categories!!",
        });
        setLoadingCategories(false);
      });
  }, [setLoadingCategories, setCategories, setNotification]);

  useEffect(() => {
    reloadCategories();
  }, [reloadCategories]);

  return (
    <>
      <Stack direction="column" spacing={2} p={4}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Manage Categories
        </Typography>
        {loadingCategories ? (
          <Grid container justifyContent="center">
            <Grid item pt={5}>
              <Preloader />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            {categories.map((cat, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={4}
                // sx={{ display: "flex", justifyContent: "center" }}
              >
                <CategoryCard
                  {...cat}
                  reloadCategories={reloadCategories}
                  handleOpenEdit={handleOpenEdit}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>

      <Dialog
        open={formDialogOpen}
        // onClose={handleCloseDialog}
        fullWidth
        keepMounted
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <DialogTitle sx={{ textAlign: "center" }}>Add new Category</DialogTitle>
        <Divider />
        <DialogContent>
          <p
            onClick={handleCloseDialog}
            style={{
              position: "absolute",
              top: "0",
              color: "white",
              right: "20px",
              background: "#0000008f",
              padding: "1px 8px",
              borderRadius: "100px",
              cursor: "pointer",
            }}
          >
            X
          </p>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Category Name"
                    name="categoryName"
                    error={!!errors.categoryName}
                    helperText={errors.categoryName}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    InputProps={{
                      endAdornment: <CategoryRounded />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="category-description-editor"
                    label="Description *"
                    placeholder="Write formatted description here..."
                    content={description}
                    handleChange={(html) => setDescription(html)}
                    errorMsg={errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    name="for"
                    value={type}
                    defaultValue={type}
                    onChange={(e) => {
                      const v = e.target.value;
                      if (v === "cur") {
                        setCoverImageDim({
                          width: 1080,
                          height: 1350,
                        });
                        setIsPromoHomeTop(false);
                        setIsPromoHomeBottom(false);
                        setIsPromoCatTop(false);
                      } else {
                        setCoverImageDim({ width: 1500, height: 400 });
                      }
                      setType(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="cur"
                      control={<Radio />}
                      label="Curated"
                    />
                    <FormControlLabel
                      value="pro"
                      control={<Radio />}
                      label="Promotional"
                    />
                  </RadioGroup>
                </Grid>
                {type === "pro" && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        name="isPromoHomeTop"
                        control={
                          <Checkbox
                            checked={isPromoHomeTop}
                            onChange={() => setIsPromoHomeTop(!isPromoHomeTop)}
                          />
                        }
                        label="Is Promo For Home Top"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        name="isPromoHomeBottom"
                        control={
                          <Checkbox
                            checked={isPromoHomeBottom}
                            onChange={() =>
                              setIsPromoHomeBottom(!isPromoHomeBottom)
                            }
                          />
                        }
                        label="Is Promo For Home Bottom"
                        labelPlacement="end"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControlLabel
                        name="isPromoCatTop"
                        control={
                          <Checkbox
                            checked={isPromoCatTop}
                            onChange={() => setIsPromoCatTop(!isPromoCatTop)}
                          />
                        }
                        label="Is Promo For Category Top"
                        labelPlacement="end"
                      />
                    </Grid>
                  </>
                )}
                <Grid item sm={12} md={6}>
                  <ImageUpload
                    files={bannerFiles}
                    setFiles={setBannerFiles}
                    disabled={bannerImage.key ? true : false}
                    title="Banner"
                    size={{ width: 1500, height: 1000 }}
                    errors={errors}
                    setErrors={setErrors}
                    fieldName="bannerImage"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                  >
                    {bannerImage.key ? (
                      <ImageChip
                        image={bannerImage}
                        setImage={setBannerImage}
                      />
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving banner Image will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingBanner}
                      onClick={() => handleUploadImage("banner")}
                    >
                      upload
                    </LoadingButton>
                  </Stack>
                </Grid>

                <Grid item sm={12} md={6}>
                  <ImageUpload
                    files={coverFiles}
                    setFiles={setCoverFiles}
                    disabled={coverImage.key ? true : false}
                    title="Cover"
                    size={coverImageDim}
                    errors={errors}
                    setErrors={setErrors}
                    fieldName="coverImage"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                  >
                    {coverImage.key ? (
                      <ImageChip image={coverImage} setImage={setCoverImage} />
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving cover Image will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingCover}
                      onClick={() => handleUploadImage("cover")}
                    >
                      upload
                    </LoadingButton>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <AsyncTripAutocomplete
                    mappedTrips={mappedTrips}
                    setMappedTrips={setMappedTrips}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isInternationalCategory"
                    control={
                      <Checkbox
                        checked={isInternationalCategory}
                        onChange={() =>
                          setIsInternationalCategory(!isInternationalCategory)
                        }
                      />
                    }
                    label="International"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isDomesticCategory"
                    control={
                      <Checkbox
                        checked={isDomesticCategory}
                        onChange={() =>
                          setIsDomesticCategory(!isDomesticCategory)
                        }
                      />
                    }
                    label="Domestic"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isUpcomingCategory"
                    control={
                      <Checkbox
                        checked={isUpcomingCategory}
                        onChange={() =>
                          setIsUpcomingCategory(!isUpcomingCategory)
                        }
                      />
                    }
                    label="Upcoming"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isForFooter"
                    control={
                      <Checkbox
                        checked={isForFooter}
                        onChange={() => setIsForFooter(!isForFooter)}
                      />
                    }
                    label="Footer"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isForSlider"
                    control={
                      <Checkbox
                        checked={isForSlider}
                        onChange={() => setIsForSlider(!isForSlider)}
                      />
                    }
                    label="Slider"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControlLabel
                    name="isForCustomize"
                    control={
                      <Checkbox
                        checked={isForCustomize}
                        onChange={() => setIsForCustomize(!isForCustomize)}
                      />
                    }
                    label="Customize"
                    labelPlacement="end"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant="outlined" fullWidth onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                loading={saving}
                loadingPosition="start"
                fullWidth
                startIcon={<CheckCircleRounded />}
                variant="contained"
                onClick={handleSave}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
      <Tooltip title="Add a new Category">
        <Button
          variant="contained"
          startIcon={<AddCircleRounded />}
          sx={{
            borderRadius: 5,
            position: "fixed",
            zIndex: 1,
            bottom: 100,
            margin: "auto",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          onClick={() => setFormDialogOpen(true)}
        >
          Add New
        </Button>
      </Tooltip>
      <AdminBottomNavigation menu={menu} setMenu={setMenu} />
    </>
  );
};

export default AdminCategories;
