import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Preloader from "../components/common/Preloader";
import { getCurrentDomain } from "../components/common/utils";
import AppContainer from "../components/containers/AppContainer";
import CategoryDescription from "../components/layouts/CategoryDescription";
import CuratedBanner from "../components/layouts/CuratedBanner";
import InDoubtCantDecide from "../components/layouts/InDoubtCantDecide";
import MakeYouHappier from "../components/layouts/MakeYouHappier";
import RealityOfTrip from "../components/layouts/RealityOfTrip";
import ReasonToCountOnUs from "../components/layouts/ReasonToCountOnUs";
import TripGridWithFilter from "../components/layouts/TripGridWithFilter";
import useTostify from "../hooks/useTostify";

const CategoryView = () => {
  const { categorySlug } = useParams();
  const { setNotification } = useTostify();
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [category, setCategory] = useState({});

  useEffect(() => {
    setLoadingCategory(true);
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          slug: categorySlug,
          for: "category",
        },
      })
      .then((res) => {
        setCategory(res.data[0]);
        setLoadingCategory(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingCategory(false);
      });
  }, [categorySlug, setNotification]);
  return (
    <>
      {loadingCategory ? (
        <Grid container justifyContent="center">
          <Grid item pt={5} pb={5}>
            <Preloader />
            {/* <CircularProgress size={30} /> */}
          </Grid>
        </Grid>
      ) : (
        <>
          <AppContainer
            banner={<CuratedBanner categoryName={category.categoryName} />}
            bannerImage={category.bannerImage?.url}
            bannerImgAlt={category.categoryName}
          >
            <Box
              sx={{
                padding: 0,
                justifyContent: "center",
                alignItems: "center",
                marginX: { xs: 0, md: 10 },
              }}
            >
              <CategoryDescription
                categoryName={category.categoryName}
                categoryDescription={category.categoryDescription}
              />
              <TripGridWithFilter
                categoryName={category.categoryName}
                trips={category?.trips || []}
                loadingTripData={loadingCategory}
              />
            </Box>
            <ReasonToCountOnUs />
            <MakeYouHappier />
            <RealityOfTrip />
            <InDoubtCantDecide />
          </AppContainer>
        </>
      )}
    </>
  );
};

export default CategoryView;
