import {
  AccessTimeFilledRounded,
  Delete,
  DepartureBoardRounded,
  DoneAllRounded,
} from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Alert, Chip, CircularProgress, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import useTostify from "../../hooks/useTostify";
import Preloader from "../common/Preloader";
import { getCurrentDomain } from "../common/utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "username",
    align: "left",
    disablePadding: true,
    label: "Candidate Name",
  },
  {
    id: "categoryName",
    align: "left",
    disablePadding: false,
    label: "Category Name",
  },
  {
    id: "email",
    align: "center",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "mobileNo",
    align: "center",
    disablePadding: false,
    label: "Mobile",
  },

  {
    id: "destination",
    align: "center",
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "status",
    align: "center",
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({
  selected = [],
  setSelected,
  setNotification,
  reloadRows,
}) {
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [savingCompleted, setSavingCompleted] = useState(false);
  const [deletingLeads, setDeletingLeads] = useState(false);

  const handleSubmitInProgress = (state) => {
    if (state === "progress") setSavingInProgress(true);
    if (state === "completed") setSavingCompleted(true);
    axios
      .put(`${getCurrentDomain()}/leads`, {
        leadIds: selected,
        state,
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Successfully Saved!!",
        });
        if (state === "progress") setSavingInProgress(false);
        if (state === "completed") setSavingCompleted(false);
        setSelected([]);
        reloadRows();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while saving leads!!",
        });
        if (state === "progress") setSavingInProgress(false);
        if (state === "completed") setSavingCompleted(false);
      });
  };

  const handleDelete = () => {
    setDeletingLeads(true);
    axios
      .delete(`${getCurrentDomain()}/leads`, { data: { leadIds: selected } })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Leads deleted successfully!",
        });
        setDeletingLeads(false);
        setSelected([]);
        reloadRows();
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Error deleting leads.",
        });
        setDeletingLeads(false);
      });
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(selected.length > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {selected.length > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <Alert severity="warning">
            Hi Admin, This is your leads - you can sort the data by clicking on
            the columns name
          </Alert>
        </Typography>
      )}

      {selected.length > 0 ? (
        <Stack direction={"row"} spacing={0.5}>
          <Tooltip title="Mark as In Progress">
            <IconButton
              disabled={savingInProgress}
              onClick={() => handleSubmitInProgress("progress")}
            >
              {savingInProgress ? (
                <CircularProgress color="secondary" size={15} />
              ) : (
                <DepartureBoardRounded color="secondary" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Mark as completed">
            <IconButton
              disabled={savingCompleted}
              onClick={() => handleSubmitInProgress("completed")}
            >
              {savingCompleted ? (
                <CircularProgress color="secondary" size={15} />
              ) : (
                <DoneAllRounded color="success" />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Selected">
            <IconButton disabled={deletingLeads} onClick={handleDelete}>
              {deletingLeads ? (
                <CircularProgress color="secondary" size={15} />
              ) : (
                <Delete color="error" />
              )}
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  setNotification: PropTypes.func.isRequired,
};

export default function LeadTable() {
  const { setNotification } = useTostify();
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [rows, setRows] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [rows, order, orderBy, page, rowsPerPage]
  );

  const reloadRows = useCallback(() => {
    setLoadingLeads(true);
    axios
      .get(`${getCurrentDomain()}/leads`)
      .then((res) => {
        setRows(res.data);
        setLoadingLeads(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingLeads(false);
      });
  }, [setRows, setLoadingLeads, setNotification]);

  useEffect(() => {
    reloadRows();
  }, [reloadRows]);

  return loadingLeads ? (
    <Box sx={{ textAlign: "center" }}>
      <Preloader />
    </Box>
  ) : (
    <Box sx={{ width: "100%" }}>
      <EnhancedTableToolbar
        selected={selected}
        setSelected={setSelected}
        setNotification={setNotification}
        reloadRows={reloadRows}
      />
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row._id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.username}
                  </TableCell>
                  <TableCell align="left">{row.categoryName}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.mobileNo}</TableCell>
                  <TableCell align="center">{row.destination}</TableCell>
                  <TableCell align="center">
                    {row.state === "completed" && (
                      <Chip
                        color="success"
                        size="small"
                        variant="filled"
                        label={row.state}
                        icon={<DoneAllRounded />}
                      />
                    )}

                    {row.state === "progress" && (
                      <Chip
                        color="secondary"
                        size="small"
                        variant="filled"
                        label={row.state}
                        icon={<DepartureBoardRounded />}
                      />
                    )}

                    {row.state === "pending" && (
                      <Chip
                        color="warning"
                        size="small"
                        variant="filled"
                        label={row.state}
                        icon={<AccessTimeFilledRounded />}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
