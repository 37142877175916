import { createContext } from "react";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const isAuthenticated = () => {
    const accessToken = localStorage.getItem("accessToken");
    return accessToken ? true : false;
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
