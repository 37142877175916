import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import numeral from "numeral";
import React from "react";
import { formatDate, getDurationNameById } from "../common/utils";

const bullet = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);
const icon = <CheckBoxOutlineBlankRounded fontSize="small" />;
const checkedIcon = <CheckBoxRounded fontSize="small" />;
const TripListView = ({
  coverImage,
  tripName = "Thailand Fullmoon New Year Party with Flight",
  pickupLocation = "Delhi",
  dropLocation = "Mumbai",
  durationId = 1,
  slug,
  startingPrice = 88567,
  tripDates = [],
  selected,
  isSecondaryActionVisible = true,
  _id,
  ...otherProps
}) => {
  const labelId = `checkbox-list-label-${slug}`;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const truncatedTripName = isSmallScreen
    ? `${tripName.substring(0, 10)}...`
    : tripName;
  return (
    <ListItem
      {...otherProps}
      key={`ley-${labelId}`}
      secondaryAction={
        isSecondaryActionVisible ? (
          <Checkbox
            edge="end"
            checked={selected}
            icon={icon}
            checkedIcon={checkedIcon}
            inputProps={{ "aria-labelledby": labelId }}
          />
        ) : null
      }
      disablePadding
    >
      <ListItemAvatar>
        <Avatar alt={tripName} src={coverImage.url} />
      </ListItemAvatar>
      <Tooltip
        title={
          <Typography variant="caption">
            Trip name: {tripName} <br />
            Pickup location: {pickupLocation} <br />
            Drop location: {dropLocation} <br />
            Trip Dates: {formatDate(tripDates)}
          </Typography>
        }
      >
        <ListItemText
          primary={truncatedTripName}
          secondary={
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="caption"
              color="text.secondary"
            >
              {pickupLocation}
              {bullet}
              {dropLocation}
              {!isSmallScreen && bullet}
              {isSmallScreen && <br />}
              {getDurationNameById(durationId)}
              {bullet}
              {`₹ ${numeral(startingPrice).format("0,0")} /-`}
            </Typography>
          }
        />
      </Tooltip>
    </ListItem>
  );
};

export default TripListView;
