import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <>
      <Box
        sx={{
          minWidth: "100%",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          justifyItems: "center",
          backgroundColor: "#EFF5F5",
        }}
      >
        <img
          width="50%"
          alt="Page Not Found"
          src={process.env.PUBLIC_URL + "/404.svg"}
        />
        <Typography variant="caption">
          The page you are looking for isn’t here
        </Typography>
      </Box>
    </>
  );
};

export default NotFound;
