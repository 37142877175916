import styled from "@emotion/styled";
import { Facebook, Instagram, Twitter, WhatsApp } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";
import Copyright from "./Copyright";

const FooterWrapper = styled.footer`
  padding: 0 2rem;
`;

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { setNotification } = useTostify();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          for: "category",
        },
      })
      .then((res) => {
        setCategories(res.data.filter((c) => c.isForFooter));
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
      });
  }, [setNotification]);
  return (
    <Grid container sx={{ background: "#ffffff" }}>
      <img src="/desktop-footer.png" style={{ width: "100%" }} />
      <Grid container sx={{ background: "#2f86a6", marginTop: "-1px" }}>
        <FooterWrapper sx={{ marginTop: isSmallScreen ? 1 : 0 }}>
          {}
          {/* <Grid
        container
        justifyContent="center"
        alignItems="center"
        mb={2}
        sx={{
          marginTop: isSmallScreen ? -5 : -8,
        }}
      >
        <Grid
          href="/"
          item
          sx={{
            position: "relative",
            backgroundColor: "white",
            borderRadius: 10,
            paddingTop: "-2rem",
            border: "1px solid #eaeaea",
          }}
        >
          <img src="/logo_white.png" alt="Logo" height={100} />
        </Grid>
      </Grid> */}
          <Grid
            container
            justifyContent="space-between"
            spacing={4}
            marginTop={0}
            sx={{
              padding: "20px 80px",
              "@media(max-width:767px)": { padding: "0 5px" },
            }}
          >
            <Grid item xs={12} sm={6} md={3} sx={{ position: "relative" }}>
              <img
                className="borderFooter"
                src="/partition.svg"
                decoding="async"
                data-nimg="fill"
                loading="lazy"
                style={{ position: "absolute", height: "100%", right: "-5px" }}
              />
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: 22, color: "#fff" }}
              >
                About Us
              </Typography>
              <Typography
                variant="caption"
                fontSize={13}
                sx={{ lineHeight: "19px", display: "block", color: "#ffffff" }}
              >
                Memorable Your Trip: Your go-to destination for unforgettable
                journeys. Find curated itineraries, insider tips, and expert
                recommendations to make every adventure memorable. From weekend
                getaways to international escapades, we're here to inspire and
                guide you. Explore confidently with Memorable Your Trip by your
                side.
              </Typography>
              <Grid
                container
                justifyContent="left"
                spacing={1}
                alignItems="center"
                sx={{ marginTop: "10px" }}
              >
                <Grid item>
                  <IconButton
                    href="https://twitter.com/TripMemorable"
                    target="_blank"
                  >
                    <Twitter
                      color="primary"
                      fontSize="large"
                      sx={{ color: "#fff !important", fontSize: "30px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    href="https://www.facebook.com/Memorableyourtrip"
                    target="_blank"
                  >
                    <Facebook
                      color="primary"
                      fontSize="large"
                      sx={{ color: "#fff !important", fontSize: "30px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    href="https://www.instagram.com/memorableyourtrip/"
                    target="_blank"
                  >
                    <Instagram
                      color="primary"
                      fontSize="large"
                      sx={{ color: "#fff !important", fontSize: "30px" }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton href="https://wa.me/918287734456" target="_blank">
                    <WhatsApp
                      color="primary"
                      fontSize="large"
                      sx={{ color: "#fff !important", fontSize: "30px" }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ position: "relative" }}>
              <img
                className="borderFooter"
                src="/partition.svg"
                decoding="async"
                data-nimg="fill"
                loading="lazy"
                style={{ position: "absolute", height: "100%", right: "-4px" }}
              />
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: 22, color: "#fff" }}
              >
                Popular Trips
              </Typography>
              {categories.map((c, i) => (
                <Button
                  key={i}
                  underline="none"
                  sx={{
                    display: "block",
                    textTransform: "none",
                    width: "100%",
                    textAlign: "left",
                    color: "#fff",
                    fontWeight: 400,
                    marginBottom: 0,
                    p: 0,
                    mb: 0,
                    fontSize: 13,
                  }}
                >
                  {c.categoryName}
                </Button>
              ))}
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ position: "relative" }}>
              <img
                className="borderFooter"
                src="/partition.svg"
                decoding="async"
                data-nimg="fill"
                loading="lazy"
                style={{ position: "absolute", height: "100%", right: "-3px" }}
              />
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: 22, color: "#fff" }}
              >
                Quick Links
              </Typography>
              <Button
                underline="none"
                sx={{
                  display: "block",
                  textTransform: "none",
                  width: "100%",
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: 400,
                  marginBottom: 0,
                  p: 0,
                  mb: 0,
                  fontSize: 13,
                }}
              >
                Privacy Policy
              </Button>
              <Button
                underline="none"
                sx={{
                  display: "block",
                  textTransform: "none",
                  width: "100%",
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: 400,
                  marginBottom: 0,
                  p: 0,
                  mb: 0,
                  fontSize: 13,
                }}
              >
                Cancellation Policy
              </Button>
              <Button
                underline="none"
                sx={{
                  display: "block",
                  textTransform: "none",
                  width: "100%",
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: 400,
                  marginBottom: 0,
                  p: 0,
                  mb: 0,
                  fontSize: 13,
                }}
              >
                TnC
              </Button>
              <Button
                underline="none"
                sx={{
                  display: "block",
                  textTransform: "none",
                  width: "100%",
                  textAlign: "left",
                  color: "#fff",
                  fontWeight: 400,
                  marginBottom: 0,
                  p: 0,
                  mb: 0,
                  fontSize: 13,
                }}
              >
                Disclaimer
              </Button>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={1}>
          <center><Typography component={"div"} sx={{border:"1px solid #2f86a640",width:"1px",height:"300px"}}></Typography></center>
        </Grid> */}
            {/* Column 4: Address */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 700, fontSize: 22, color: "#fff" }}
              >
                Get In Touch
              </Typography>
              <Button
                variant="text"
                disableElevation
                sx={{ textTransform: "none", p: 0, mb: 0 }}
              >
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={"#fff"}
                  sx={{ fontSize: "18px" }}
                >
                  Memorable Your Trip
                </Typography>
              </Button>
              <Typography variant="body2" color="#fff" mb={1}>
                4th Floor, SRS Tower, Mewala Maharajpur Metro Station, Sector
                31, Faridabad, Haryana 121003
              </Typography>
              <Typography
                component={"a"}
                href="tel:+918287734456"
                variant="body2"
                color="#fff"
                sx={{ textDecoration: "none" }}
              >
              Contact US NOw  Mobile: +91-8287734456 , &nbsp;
              </Typography>
              <Typography
                component={"a"}
                href="tel:+918586043632"
                variant="body2"
                color="#fff"
                sx={{ textDecoration: "none" }}
              >
                +91-8586043632
              </Typography>
              <br></br>
              <Typography
                component={"a"}
                href="mailto:memorableyourtrip@gmail.com"
                variant="body2"
                color="#fff"
                sx={{ textDecoration: "none" }}
              >
                Email: memorableyourtrip@gmail.com ,<br></br>
              </Typography>
              <Typography
                component={"a"}
                href="mailto:info@memorableyourtrip.co.in"
                variant="body2"
                color="#fff"
                sx={{ textDecoration: "none" }}
              >
                info@memorableyourtrip.co.in
              </Typography>
            </Grid>
          </Grid>
          <Copyright />
        </FooterWrapper>
      </Grid>
    </Grid>
  );
};

export default Footer;
