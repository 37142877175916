import {
  Avatar,
  CardMedia,
  Chip,
  CircularProgress,
  Tooltip,
  Zoom,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";
import { FileOpenOutlined } from "@mui/icons-material";

const FileChip = ({fileKey, fileUrl, setFile, type = undefined }) => {
  const { setNotification } = useTostify();
  const [deleting, setDeleting] = useState(false);

  const handleDeleteImage = (keyToDelete) => {
    setDeleting(true);
    axios
      .delete(`${getCurrentDomain()}/resource/itinerary?key=${keyToDelete}`)
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Deleted Successfully!!",
        });
        if (type) {
          setFile();
        } else {
          setFile({});
        }
        setDeleting(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while Deleting Image!!",
        });
        setDeleting(false);
      });
  };
  return (
    <Tooltip
      title={
        "Itinerary File"
      }
      arrow
      key={fileUrl}
    >
      <Chip
        label={fileUrl}
        sx={{
          cursor: "pointer",
        }}
        disabled={deleting}
        onDelete={() => handleDeleteImage(fileKey)}
        icon={<FileOpenOutlined />}
        deleteIcon={
          deleting ? <CircularProgress size={20} color="inherit" /> : null
        }
      />
    </Tooltip>
  );
};

export default FileChip;
