import { Box, Card, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";

const YouTubeSlider = ({ youTubeVideoIds = [] }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const settings = {
    speed: 800,
    autoPlay: true,
    autoplaySpeed: 2000,
    // accessibility: true,
    slidesToShow: Math.min(2, youTubeVideoIds?.length),
    infinite: false,
    // slickPlay: true,
    dots: true,
    nav:false,
    arrows:false,
    // nextArrow: <ArrowForwardIosRounded color="primary" fontSize="large" />,
    // prevArrow: <ArrowBackIosNewRounded color="primary" fontSize="large" />,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Box
      // marginX={{ md: 15, lg: 10, xl: 10 }}
      // paddingY={{ xs: 1, md: 5 }}
      // paddingX={{ xs: 1, md: 5 }}
      sx={
        !isSmallScreen
          ? {}
          : { flexDirection: "column-reverse", alignItems: "center" }
      }
    >
      <Slider {...settings}>
        {youTubeVideoIds.map((youTubeVideoId, i) => (
          <center>
            <Card
              elevation={1}
              key={i}
              sx={{
                marginTop:"8px",
                marginLeft:"3px",
                marginRight:"3px",
                mb: 1,
                borderRadius: "10px",
                height: isSmallScreen ? 160 : 210,
                maxWidth: isSmallScreen ? 320 : 370,
              }}
            >
              <YouTube
                videoId={youTubeVideoId}
                opts={{
                  height: isSmallScreen ? "160" : "210",
                  width: isSmallScreen ? "100%" : " 370",
                }}
              />
            </Card>
          </center>
        ))}
      </Slider>
    </Box>
  );
};

export default YouTubeSlider;
