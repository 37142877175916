import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import EnquiryForm from "./EnquiryFormCategoryView";

const CuratedBannerCruise = ({ categoryName = "" }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: [150, 120],
        width: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: isSmallScreen ? "column" : "row",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "white !important",
          textAlign: "center",
          fontWeight: 800,
          fontSize: "calc(1.375rem + 1.5vw)",
          width: "50%",
          "@media(max-width:767px)": {
            fontSize: "28px !important",
            marginTop: "30px",
            marginBottom: "30px",
            width: "100%",
          },
        }}
      >
        {categoryName}
      </Typography>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{
          display: { xs: "none", md: "none" },
          position: "absolute",
          background: "#2f86a6",
          top: 80,
          "@media(max-width:767px)": {
            position: "relative",
            top: 10,
          },
        }}
      >
        Get Quote
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{ display: { xs: "none", md: "block" } }}
        open={open}
        onClose={() => setOpen(true)}
      >
        <DialogContent>
          <EnquiryForm categoryName={categoryName} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: { xs: "block", md: "block" },
        }}
      >
        <EnquiryForm categoryName={categoryName} handleClose={handleClose} />
      </Box>
    </Box>
  );
};

export default CuratedBannerCruise;
