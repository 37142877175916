import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCurrentDomain } from "../components/common/utils";
import BlankContainer from "../components/containers/BlankContainer";
import CuratedBannerCruise from "../components/layouts/CuratedBannerCruise";
import useTostify from "../hooks/useTostify";

const CruiseTrips = () => {
  const { categorySlug } = useParams();
  const { setNotification } = useTostify();
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [category, setCategory] = useState({});

  useEffect(() => {
    setLoadingCategory(true);
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          slug: categorySlug,
          for: "category",
        },
      })
      .then((res) => {
        setCategory(res.data[0]);
        setLoadingCategory(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingCategory(false);
      });
  }, [categorySlug, setNotification]);
  return (
    <BlankContainer
      banner={
        <CuratedBannerCruise categoryName={"Cruise Trip are Coming Soon!!!"} />
      }
      style={{ overflow: "hidden" }}
      // category.categoryName
      bannerImage={category.bannerImage?.url}
      bannerImgAlt={category.categoryName}
    ></BlankContainer>
  );
};

export default CruiseTrips;
