import { LocationOn, PlaceRounded, SearchRounded } from "@mui/icons-material";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";
import TripListView from "./TripListView";

const LeadSearchBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const textFieldSize = isSmallScreen ? "small" : "medium";

  const [open, setOpen] = useState(false);
  const [trips, setTrips] = useState([]);
  const { setNotification } = useTostify();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (!open) {
      return undefined;
    }
    setLoading(true);
    axios
      .get(`${getCurrentDomain()}/trips`, {
        params: {
          for: "category",
        },
      })
      .then((res) => {
        setLoading(false);
        if (active) {
          setTrips(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trips!!",
        });
      });

    return () => {
      active = false;
    };
  }, [open, setNotification]);

  useEffect(() => {
    if (!open) {
      setTrips([]);
    }
  }, [open]);
  return (
    <Autocomplete
      id="asynchronous-trip"
      size={textFieldSize}
      className="searchTrip"
      options={trips}
      noOptionsText="No Trips"
      onChange={(e, value) => {
        navigate(`/trip/${value.slug}`);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      // open={open}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={(option) => option.tripName}
      renderOption={(props, option, { selected }) => (
        <TripListView {...option} isSecondaryActionVisible={false} {...props} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search trips..."
          fullWidth
          sx={{
            border:"2px solid #2f86a6",
            color:"black",
            background: "white",
            width: "100%",
            borderRadius: 2,
            "@media (max-width: 767px)": {
              marginTop:"4px",
            },
            "@media (max-width: 600px)": {
              width: "95%",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none",
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <LocationOn sx={{color:"#2f86a6",fontSize:"35px",'@media(max-width:767px)':{fontSize:"25px"}}}/>
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <InputAdornment position="end">
                    <SearchRounded sx={{color:"#2f86a6",fontSize:"35px",'@media(max-width:767px)':{fontSize:"25px"}}}/>
                  </InputAdornment>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default LeadSearchBar;
