import { Box, CircularProgress, Grid } from "@mui/material";
import React, { useState } from "react";
import FilterDropDownCategory from "./FilterDropDownCategory";
import TripCard from "./TripCard";
import TripCardUpcoming from "./TripCardUpcoming";
import FilterDropDownUpcoming from "./FilterDropDownUpcoming";
import Preloader from './../common/Preloader';

const TripGridWithUpcoming = ({
  trips,
  loadingTrips,
  editable = false,
  reloadTrips,
  handleOpenEdit,
  monthName,
  categoryName
}) => {
  const [cachedTrips, setCachedTrips] = useState(trips);
  return (
    <Box mt={2}>
      {/* <FilterDropDownUpcoming monthName={monthName} trips={trips} setCachedTrips={setCachedTrips} /> */}
      {loadingTrips ? (
        <Grid container justifyContent="center">
          <Grid item pt={5}>
            <Preloader />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1}>
          {trips.map((td, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={3}
            >
          
              <TripCard
              {...td}
              slug={td.slug}
              categoryName={monthName}
              editable={editable}
              reloadTrips={reloadTrips}
              handleOpenEdit={handleOpenEdit} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TripGridWithUpcoming;
