import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { getTripDurationDropDownData } from '../common/utils';

export default function DurationDropDown({
  required = false,
  durationId = 0,
  setDurationId,
  errors,
}) {
  const dropDrownData = getTripDurationDropDownData();

  return (
    <TextField
      select
      required={required}
      size='small'
      value={durationId}
      fullWidth
      error={!!errors?.durationId}
      helperText={errors?.durationId}
      label='Duration'
      onChange={(e) => setDurationId(e.target.value)}
      className='dropdown_fieldset'
      sx={{
        minWidth: 100,
      }}
      id='select-month-small'
    >
      {dropDrownData.map((ddd, i) => (
        <MenuItem key={i} value={ddd.id} className='dropDown_menu' sx={{fontSize:"13px !important",lineHeight:"12px",minHeight:"0px",color:"#2f86a6"}}>
          {ddd.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
