import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import {
  AddCircleRounded,
  CheckCircleRounded,
  DateRangeRounded,
  Delete,
  FileOpenOutlined,
  FileUploadRounded,
  HailRounded,
  LandscapeRounded,
  MoneyRounded,
  PinDropRounded,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { MuiChipsInput } from "mui-chips-input";
import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentDomain } from "../components/common/utils";
import AdminBottomNavigation from "../components/layouts/AdminBottomNavigation";
import AsyncCategoryAutocomplete from "../components/layouts/AsyncCategoryAutocomplete";
import DurationDropDown from "../components/layouts/DurationDropDown";
import ImageChip from "../components/layouts/ImageChip";
import ImageUpload from "../components/layouts/ImageUpload";
import TripGridWithFilter from "../components/layouts/TripGridWithFilter";
import Editor from "../components/layouts/rich-text-editor/editor";
import useAuth from "../hooks/useAuth";
import useTostify from "../hooks/useTostify";
import FileUploadPDF from "../components/layouts/FileUploadPDF";
import FileChip from "../components/layouts/FileChip";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminTrips = () => {
  const { setNotification } = useTostify();
  const [menu, setMenu] = useState("/admin/trips");
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [saving, setSaving] = useState(false);
  const [uploadingBanner, setUploadingBanner] = useState(false);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [uploadingGallery, setUploadingGallery] = useState(false);
  const [uploadingItinerary, setUploadingItinerary] = useState(false);
  const [edit, setEdit] = useState(false);

  const [trips, setTrips] = useState([]);

  //form fields
  const [tripId, setTripId] = useState("");
  const [tripName, setTripName] = useState("");
  const [tripDescription, setTripDescription] = useState("");
  const [itinerary, setItinerary] = useState("");
  const [inclusion, setInclusion] = useState("");
  const [exclusion, setExclusion] = useState("");
  const [note, setNote] = useState("");
  const [bannerFiles, setBannerFiles] = useState([]);
  const [coverFiles, setCoverFiles] = useState([]);
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [itineraryFiles, setItineraryFiles] = useState([]);
  const [itineraryPDF, setItineraryPDF] = useState({});
  const [bannerImage, setBannerImage] = useState({});
  const [coverImage, setCoverImage] = useState({});
  const [gallery, setGallery] = useState([]);
  const [mappedCategories, setMappedCategories] = useState([]);
  const [youTubeVideoIds, setYouTubeVideoIds] = useState([]);
  const [pickupLocation, setPickupLocation] = useState("");
  const [doubleSharingAmount, setDoubleSharingAmount] = useState("");
  const [tripleSharingAmount, setTripleSharingAmount] = useState("");
  const [quadSharingAmount, setQuadSharingAmount] = useState("");
  const [customItemAmount, setCustomAmount] = useState("");
  const [customItemName, setCustomItem] = useState("");
  const [dropLocation, setDropLocation] = useState("");
  const [startingPrice, setStartingPrice] = useState(0);
  const [durationId, setDurationId] = useState(1);
  const [tripDates, setTripDates] = useState([]);
  const [errors, setErrors] = useState({});
  const [customField, setCustomField] = useState([]);
  const [customItemData, setCustomItemData] = useState([]);

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleCloseDialog = () => {
    setFormDialogOpen(false);
    clearForm();
    setErrors({});
  };

  const handleDateSelect = (dates) => {
    setTripDates(dates);
    setOpenDatePicker(false);
  };

  const handleEditorChange = (html, fieldName) => {
    switch (fieldName) {
      case "tripDescription":
        setTripDescription(html);
        break;
      case "itinerary":
        setItinerary(html);
        break;
      case "inclusion":
        setInclusion(html);
        break;
      case "exclusion":
        setExclusion(html);
        break;
      case "note":
        setNote(html);
        break;
      default:
        break;
    }
  };

  const handleUploadImage = (uploadFor) => {
    const newErrors = {};
    let dataFile = new FormData();
    let url = `${getCurrentDomain()}/resource/`;
    if (uploadFor === "banner") {
      if (bannerFiles.length <= 0) {
        newErrors.bannerImage = "Banner Image is required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-banner";
      dataFile = { bannerImage: bannerFiles[0] };
      setUploadingBanner(true);
    } else if (uploadFor === "cover") {
      if (coverFiles.length <= 0) {
        newErrors.coverImage = "Cover Image is required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-cover";
      dataFile = { coverImage: coverFiles[0] };
      setUploadingCover(true);
    } else if (uploadFor === "gallery") {
      if (galleryFiles.length <= 0) {
        newErrors.galleryImage = "Images are required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-gallery";
      console.log(galleryFiles);
      // dataFile = { galleryImages: galleryFiles };
      galleryFiles.forEach(file => dataFile.append("galleryImages", file));
      setUploadingGallery(true);
    }
    else if (uploadFor === "itinerary") {
      if (itineraryFiles.length <= 0) {
        newErrors.itineraryPDF = "PDF is required!!";
        setErrors(newErrors);
        return;
      }
      url += "upload-itinerary";
      dataFile = { itineraryPDF: itineraryFiles[0] };
      setUploadingItinerary(true);
    }
    axios
      .post(url + "?for=trip", dataFile, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Uploaded Successfully!!",
        });
        if (uploadFor === "banner") {
          setBannerImage(res.data);
          setBannerFiles([]);
          setUploadingBanner(false);
        } else if (uploadFor === "cover") {
          setCoverImage(res.data);
          setCoverFiles([]);
          setUploadingCover(false);
        } else if (uploadFor === "gallery") {
          setGallery([...gallery, ...res.data]);
          setGalleryFiles([]);
          setUploadingGallery(false);
        }
        else if (uploadFor === "itinerary") {
          setItineraryPDF(res.data);
          setItineraryFiles([]);
          setUploadingItinerary(false);
        }
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while Uploading!!",
        });
        if (uploadFor === "banner") {
          setUploadingBanner(false);
        } else if (uploadFor === "cover") {
          setUploadingCover(false);
        } else if (uploadFor === "gallery") {
          setUploadingGallery(false);
        } else if (uploadFor === "itinerary") {
          setUploadingItinerary(false);
        }
      });
  };

  const handleDeleteImageFromGallery = (keyToRemove) => {
    const updatedGallery = gallery.filter((g) => g.key !== keyToRemove);
    setGallery(updatedGallery);
  };
  const handleDeleteItinerary = (keyToRemove) => {
    setItineraryFiles([]);
    setItineraryPDF({});
  };
  const handleCustomData = () => {
    let arr = [...customField];
    return arr;
  }

  let getFieldName = (i, e) => {
    let newInput = [...customField];
    let ItemIndex = newInput[i];
    ItemIndex = { ...ItemIndex, [e.target.name]: e.target.value };
    newInput[i] = ItemIndex;
    setCustomField(newInput);
  }

  let removeCustomFields = (i) => {
    let customFieldRow = [...customField];
    customFieldRow.splice(i, 1);
    setCustomField(customFieldRow);
  }
  let addCustomFields = () => {
    setCustomField([...customField, { index: "" }]);
  }

  const handleSave = async () => {
    const tripData = {
      tripName,
      tripDescription,
      itinerary,
      inclusion,
      exclusion,
      note,
      coverImage,
      bannerImage,
      galleryImages: gallery,
      youTubeVideoIds,
      pickupLocation,
      doubleSharingAmount,
      tripleSharingAmount,
      quadSharingAmount,
      customItems: handleCustomData(),
      dropLocation,
      startingPrice,
      durationId,
      tripDates,
      itineraryPDF,
      categories: mappedCategories.map((mc) => mc._id),
    };
    console.log(tripData);
    if (!validateTripForm()) return;
    setSaving(true);
    if (edit) {
      axios
        .put(`${getCurrentDomain()}/trips`, tripData, {
          params: {
            tripId,
          },
        })
        .then((res) => {
          setNotification({
            open: true,
            severity: "success",
            msg: "Successfully Saved!!",
          });
          setSaving(false);
          handleCloseDialog();
          reloadTrips();
        })
        .catch((err) => {
          setNotification({
            open: true,
            severity: "error",
            msg: "Oops Error while saving Trip!!",
          });
          setSaving(false);
        });
    } else {
      axios
        .post(`${getCurrentDomain()}/trips`, tripData)
        .then((res) => {
          setNotification({
            open: true,
            severity: "success",
            msg: "Successfully Saved!!",
          });
          setSaving(false);
          handleCloseDialog();
          reloadTrips();
        })
        .catch((err) => {
          setNotification({
            open: true,
            severity: "error",
            msg: "Oops Error while saving Trip!!",
          });
          setSaving(false);
        });
    }
  };

  const handleOpenEdit = (tId) => {
    setTripId(tId?._id);
    let foundTrip = trips.find((t) => t._id === tId?._id);
    populateForm(foundTrip);
    setEdit(true);
    setFormDialogOpen(true);
  };

  const handleYouTubeLinksChange = (urls) => {
    setYouTubeVideoIds([
      ...new Set(
        urls.map((url) => {
          const match = url.match(/[?&]v=([^&]+)/);
          return match ? match[1] : url; // If match found, extract; otherwise, use the original as ID
        })
      ),
    ]);
  };

  const validateTripForm = () => {
    let valid = true;
    const newErrors = {};

    if (!tripName.trim()) {
      newErrors.tripName = "Trip Name is required!!";
      valid = false;
    }
    if (!tripDescription.trim()) {
      newErrors.tripDescription = "Trip Description is required!!";
      valid = false;
    }
    if (!itinerary.trim()) {
      newErrors.itinerary = "Trip Itinerary is required!!";
      valid = false;
    }
    if (!inclusion.trim()) {
      newErrors.inclusion = "Trip Inclusion is required!!";
      valid = false;
    }
    if (!exclusion.trim()) {
      newErrors.exclusion = "Trip Exclusion is required!!";
      valid = false;
    }

    if (!edit && !coverImage.key) {
      newErrors.coverImage = "Cover Image is required!!";
      valid = false;
    }

    if (!edit && !bannerImage.key) {
      newErrors.bannerImage = "Banner Image is required!!";
      valid = false;
    }

    if (!pickupLocation?.trim()) {
      newErrors.pickupLocation = "Pickup Location is required!!";
      valid = false;
    }

    if (!dropLocation?.trim()) {
      newErrors.dropLocation = "Drop Location is required!!";
      valid = false;
    }
    // if (startingPrice < 1000) {
    //   newErrors.startingPrice = "Price can't less than Rs. 1000 /- !!";
    //   valid = false;
    // }
    if (durationId <= 0) {
      newErrors.durationId = "Please select duration for the trip!!";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const getFormattedDates = (datesArray) => {
    if (datesArray.length <= 0) return "All Dates";
    return datesArray.map((date) => {
      const options = { month: "short", day: "numeric" };
      return ` ${new Date(date).toLocaleDateString("en-US", options)}`;
    });
  };

  const populateForm = (trip) => {
    setTripName(trip?.tripName || "");
    setTripDescription(trip?.tripDescription || "");
    setBannerImage(trip.bannerImage || {});
    setCoverImage(trip.coverImage || {});
    setItinerary(trip.itinerary || {});
    setGallery(trip.galleryImages || []);
    setItineraryPDF(trip?.itineraryPDF || {});
    setInclusion(trip?.inclusion || "");
    setExclusion(trip?.exclusion || "");
    setNote(trip?.note || "");
    setCustomItemData(trip?.customItemData || []);
    setMappedCategories(trip?.categories || []);
    setYouTubeVideoIds(trip?.youTubeVideoIds || []);
    setPickupLocation(trip?.pickupLocation || "");
    setDoubleSharingAmount(trip?.doubleSharingAmount || "");
    setTripleSharingAmount(trip?.tripleSharingAmount || "");
    setQuadSharingAmount(trip?.quadSharingAmount || "");

    setCustomAmount(trip?.customItemAmount || "");
    setCustomField(trip?.customItems || "");
    setDropLocation(trip?.dropLocation || "");
    setStartingPrice(trip?.startingPrice || 0);
    setDurationId(trip?.durationId || 0);
    setTripDates(trip?.tripDates?.flat().map((dtStr) => new Date(dtStr)) || []);
  };

  const clearForm = () => {
    setTripName("");
    setTripDescription("");
    setItinerary("");
    setInclusion("");
    setExclusion("");
    setNote("");
    setYouTubeVideoIds([]);
    setMappedCategories([]);
    setPickupLocation("");
    setDoubleSharingAmount("");
    setTripleSharingAmount("");
    setQuadSharingAmount("");
    setCustomItem("");
    setCustomItemData([]);
    setCustomAmount("");
    setDropLocation("");
    setStartingPrice(0);
    setDurationId(0);
    setTripDates([]);
    setBannerImage({});
    setBannerFiles([]);
    setCoverImage({});
    setCoverFiles([]);
    setGallery([]);
    setGalleryFiles([]);
    setItineraryPDF({});
    setItineraryFiles([]);
    setDurationId(1);
    setEdit(false);
  };

  const reloadTrips = useCallback(() => {
    setLoadingTrips(true);
    axios
      .get(`${getCurrentDomain()}/trips`)
      .then((res) => {
        setTrips(res.data);
        setLoadingTrips(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trips!!",
        });
        setLoadingTrips(false);
      });
  }, [setLoadingTrips, setTrips, setNotification]);

  useEffect(() => {
    reloadTrips();
  }, [reloadTrips]);

  useEffect(() => {
    if (!isAuthenticated()) navigate("/access-denied");
  });

  return (
    <>
      <Stack direction="column" spacing={2} p={4}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          Manage Trips
        </Typography>
        <TripGridWithFilter
          trips={trips}
          reloadTrips={reloadTrips}
          loadingTrips={loadingTrips}
          handleOpenEdit={handleOpenEdit}
          editable
        />
      </Stack>
      <Dialog
        open={formDialogOpen}
        // onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        keepMounted
        TransitionComponent={Transition}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {edit ? "Update" : "Add new"} Trip
        </DialogTitle>
        <Divider />
        <DialogContent>
          <p
            onClick={handleCloseDialog}
            style={{
              position: "absolute",
              top: "0",
              color: "white",
              right: "20px",
              background: "#0000008f",
              padding: "1px 8px",
              borderRadius: "100px",
              cursor: "pointer",
            }}
          >
            X
          </p>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    size="small"
                    label="Trip Name"
                    name="tripName"
                    error={!!errors.tripName}
                    helperText={errors.tripName}
                    value={tripName}
                    onChange={(e) => setTripName(e.target.value)}
                    InputProps={{
                      endAdornment: <LandscapeRounded />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="trip-description-editor"
                    label="Description *"
                    placeholder="Write formatted description here..."
                    errorMsg={errors.tripDescription}
                    content={tripDescription}
                    handleChange={(html) =>
                      handleEditorChange(html, "tripDescription")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="itinerary-editor"
                    label="Itinerary *"
                    placeholder="Write formatted itinerary here..."
                    errorMsg={errors.itinerary}
                    content={itinerary}
                    handleChange={(html) =>
                      handleEditorChange(html, "itinerary")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="inclusion-editor"
                    label="Inclusion *"
                    placeholder="Write formatted inclusion here..."
                    errorMsg={errors.inclusion}
                    content={inclusion}
                    handleChange={(html) =>
                      handleEditorChange(html, "inclusion")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="exclusion-editor"
                    label="Exclusion *"
                    placeholder="Write formatted exclusion here..."
                    errorMsg={errors.exclusion}
                    content={exclusion}
                    handleChange={(html) =>
                      handleEditorChange(html, "exclusion")
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Editor
                    id="note-editor"
                    label="Note"
                    placeholder="Write formatted Note here..."
                    errorMsg={errors.note}
                    content={note}
                    handleChange={(html) => handleEditorChange(html, "note")}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <ImageUpload
                    files={bannerFiles}
                    setFiles={setBannerFiles}
                    disabled={bannerImage.key ? true : false}
                    title="Banner"
                    size={{ width: 1920, height: 1080 }}
                    errors={errors}
                    setErrors={setErrors}
                    fieldName="bannerImage"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                    spacing={2}
                  >
                    {bannerImage.key ? (
                      <ImageChip
                        image={bannerImage}
                        setImage={setBannerImage}
                      />
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving banner Image will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingBanner}
                      onClick={() => handleUploadImage("banner")}
                    >
                      Upload
                    </LoadingButton>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                  <ImageUpload
                    files={coverFiles}
                    setFiles={setCoverFiles}
                    title="Cover"
                    size={{ width: 400, height: 250 }}
                    disabled={coverImage.key ? true : false}
                    errors={errors}
                    setErrors={setErrors}
                    fieldName="coverImage"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    {coverImage.key ? (
                      <ImageChip image={coverImage} setImage={setCoverImage} />
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving cover Image will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingCover}
                      onClick={() => handleUploadImage("cover")}
                    >
                      upload
                    </LoadingButton>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                  <ImageUpload
                    files={galleryFiles}
                    setFiles={setGalleryFiles}
                    title="Gallery"
                    size={{ width: 600, height: 400 }}
                    errors={errors}
                    multiple={true}
                    setErrors={setErrors}
                    fieldName="galleryImage"
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    {gallery.length > 0 ? (
                      gallery.map((g) => (
                        <ImageChip
                          key={g.key}
                          image={g}
                          setImage={() => handleDeleteImageFromGallery(g.key)}
                        />
                      ))
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving gallery Images will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingGallery}
                      onClick={() => handleUploadImage("gallery")}
                    >
                      upload
                    </LoadingButton>
                  </Stack>
                </Grid>
                <Grid item sm={12} md={6}>
                  <FileUploadPDF
                    files={itineraryFiles}
                    setFiles={setItineraryFiles}
                    disabled={itineraryPDF.key ? true : false}
                    title="Itinerary"
                    size={5}
                    errors={errors}
                    multiple={false}
                    setErrors={setErrors}
                    fieldName="itineraryPDF" />
                </Grid>
                <Grid item sm={12} md={6}>
                  <Stack
                    height="100%"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    {itineraryPDF.key ? (
                      <FileChip
                      fileKey={itineraryPDF.key}
                      fileUrl = {itineraryPDF.url}
                      setFile = {setItineraryPDF}
                      onDelete={()=> handleDeleteItinerary(itineraryPDF.key)}
                      />
                    ) : (
                      <Typography component="div" variant="caption">
                        After saving itinerary PDFs will show here!
                      </Typography>
                    )}
                    <LoadingButton
                      startIcon={<FileUploadRounded />}
                      color="secondary"
                      variant="contained"
                      size="small"
                      loading={uploadingItinerary}
                      onClick={() => handleUploadImage("itinerary")}
                    >
                      upload
                    </LoadingButton>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <AsyncCategoryAutocomplete
                    mappedCategories={mappedCategories}
                    setMappedCategories={setMappedCategories}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiChipsInput
                    fullWidth
                    label="YouTube Video Ids"
                    placeholder="Paste link/ID and press enter"
                    size="small"
                    value={youTubeVideoIds}
                    onChange={handleYouTubeLinksChange}
                  />
                </Grid>
                <Grid item sm={12} md={12}>
                  <Card variant="outlined" sx={{
                    padding: "10px"
                  }}>
                    <CardHeader
                      title={
                        <Typography
                          variant="h4"
                          sx={{
                            color: "#242424 !important",
                            fontWeight: 600,
                            fontSize: "16px",
                            padding: "0 14px 10px",
                            marginBottom: "15px"
                          }}
                        >
                          Select Sharing Type
                        </Typography>
                      }
                      sx={{ padding: "0px !important" }}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          required
                          fullWidth
                          name="Double Sharing"
                          value={doubleSharingAmount}
                          onChange={(e) => setDoubleSharingAmount(e.target.value)}
                          size="small"
                          label="Double Sharing Amount"
                          error={!!errors.doubleSharingAmount}
                          helperText={errors.doubleSharingAmount}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          required
                          fullWidth
                          name="Triple Sharing"
                          value={tripleSharingAmount}
                          onChange={(e) => setTripleSharingAmount(e.target.value)}
                          size="small"
                          label="Triple Sharing Amount"
                          error={!!errors.tripleSharingAmount}
                          helperText={errors.tripleSharingAmount}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          required
                          fullWidth
                          name="Quad Sharing"
                          value={quadSharingAmount}
                          onChange={(e) => setQuadSharingAmount(e.target.value)}
                          size="small"
                          label="Quad Sharing Amount"
                          error={!!errors.quadSharingAmount}
                          helperText={errors.quadSharingAmount}
                        />
                      </Grid>
                    </Grid>

                    {
                      customField.map((element, ind) => (
                        <Grid container rowSpacing={2} columnSpacing={1} key={ind}
                          sx={{
                            margin: "15px 0",
                          }}>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextField
                              required
                              fullWidth
                              key={ind}
                              value={element.customItemName}
                              name={`customItemName`}
                              onChange={(e) => getFieldName(ind, e)}
                              size="small"
                              label="Custom Item Name"
                              error={!!errors.customItemName}
                              helperText={errors.customItemName}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TextField
                              required
                              fullWidth
                              key={ind}
                              name={`customItemAmount`}
                              value={element.customItemAmount}
                              onChange={(e) => getFieldName(ind, e)}
                              size="small"
                              label="Custom Item Price"
                              error={!!errors.customItemAmount}
                              helperText={errors.customItemAmount}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={ind}>
                            <IconButton
                              key={ind}
                              onClick={() => removeCustomFields(ind)}>
                              <Delete color="warning" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Button sx={{ marginTop: "5px" }} variant="contained" onClick={() => addCustomFields()}>Add Customized Items</Button>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    required
                    fullWidth
                    name="pickupLocation"
                    value={pickupLocation}
                    onChange={(e) => setPickupLocation(e.target.value)}
                    size="small"
                    label="Pickup Location"
                    error={!!errors.pickupLocation}
                    helperText={errors.pickupLocation}
                    InputProps={{
                      endAdornment: <HailRounded />,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <TextField
                    required
                    fullWidth
                    name="dropLocation"
                    value={dropLocation}
                    size="small"
                    label="Drop Location"
                    error={!!errors.dropLocation}
                    helperText={errors.dropLocation}
                    onChange={(e) => setDropLocation(e.target.value)}
                    InputProps={{
                      endAdornment: <PinDropRounded />,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="number"
                    name="startingPrice"
                    value={startingPrice}
                    placeholder="Rs. 99999 /-"
                    // error={!!errors.startingPrice}
                    // helperText={errors.startingPrice}
                    size="small"
                    onChange={(e) => setStartingPrice(e.target.value)}
                    InputProps={{
                      endAdornment: <MoneyRounded />,
                    }}
                    label="Starting Price"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <DurationDropDown
                    required
                    errors={errors}
                    durationId={durationId}
                    setDurationId={setDurationId}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    fullWidth
                    size="small"
                    onClick={() => setOpenDatePicker(true)}
                    InputProps={{
                      readOnly: true,
                      endAdornment: <DateRangeRounded />,
                    }}
                    label="Select Trip Dates"
                    value={getFormattedDates(tripDates)}
                  />
                  <MultipleDatesPicker
                    open={openDatePicker}
                    selectedDates={tripDates}
                    onCancel={() => setOpenDatePicker(false)}
                    onSubmit={handleDateSelect}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Button variant="outlined" fullWidth onClick={handleCloseDialog}>
                Cancel
              </Button>
              <LoadingButton
                loading={saving}
                loadingPosition="start"
                fullWidth
                startIcon={<CheckCircleRounded />}
                variant="contained"
                onClick={handleSave}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>

      <Tooltip title="Add a new Trip">
        <Button
          variant="contained"
          startIcon={<AddCircleRounded />}
          sx={{
            borderRadius: 5,
            position: "fixed",
            zIndex: 1,
            bottom: 100,
            margin: "auto",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          onClick={() => setFormDialogOpen(true)}
        >
          Add New
        </Button>
      </Tooltip>
      <AdminBottomNavigation menu={menu} setMenu={setMenu} />
    </>
  );
};

export default AdminTrips;
