import { StarRateRounded } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const ReviewCard = ({
  img = "https://i.pravatar.cc/300",
  title,
  subTitle,
  desc,
}) => {
  return (
    <Card
      sx={{
        mx: 2,
        my: 3,
        borderRadius: 5,
        boxShadow: "0 0 9px #bfbfbf75 !important",
      }}
      raised
    >
      <CardHeader
        avatar={<Avatar src={img} alt="client bsdk" />}
        title={title}
        subheader={subTitle}
        sx={{
          paddingBottom: "0px",
        }}
      />

      <CardContent>
        <Stack direction="row" spacing={0} pt={0}>
          <StarRateRounded fontSize="small" color="warning" />
          <StarRateRounded fontSize="small" color="warning" />
          <StarRateRounded fontSize="small" color="warning" />
          <StarRateRounded fontSize="small" color="warning" />
          <StarRateRounded fontSize="small" color="warning" />
        </Stack>
        <Typography
          variant="caption"
          sx={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            WebkitLineClamp: 4,
          }}
        >
          {desc}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
