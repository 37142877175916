import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";
import YouTubeSlider from "./YoutubeSlider";

const RealityOfTrip = () => {
  const { setNotification } = useTostify();
  const [youTubeVideoIds, setYouTubeVideoIds] = useState([]);
  const reloadRot = useCallback(() => {
    axios
      .get(`${getCurrentDomain()}/app/rot`)
      .then((res) => {
        setYouTubeVideoIds(res.data[0]?.youTubeVideoIds);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Reality of trips!!",
        });
      });
  }, [setYouTubeVideoIds, setNotification]);

  useEffect(() => {
    reloadRot();
  }, [reloadRot]);
  return (
    <Box
      paddingY={{ xs: 1, md: 5 }}
      sx={{
        paddingLeft: "80px",
        paddingRight: "80px",
        mb: "50px",
        "@media(max-width:767px)": {
          paddingLeft: "5px",
          paddingRight: "5px",
        },
      }}
    >
      <Box sx={{ textAlign: "center", mb: 3 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 800,
            color: "#2f86a6",
            "@media(max-width:767px)": { fontSize: "24px !important" },
          }}
        >
          THE REALITY OF A TRIP
        </Typography>
        <Typography variant="body1" color={"gray"}>
          Testimonials, Reviews, Experiences, Virtual Tours & Much More
        </Typography>
      </Box>
      <YouTubeSlider youTubeVideoIds={youTubeVideoIds} />
    </Box>
  );
};

export default RealityOfTrip;
