import { Box, Card, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Slider from "react-slick";
import YouTube from "react-youtube";
const playVideo = (id) => {
  alert(id);
}
const YouTubeSlider = ({ youTubeVideoIds = [] }) => {
  const theme = useTheme();
  const [showPlayer, setShowPlayer] = useState([]);
  const [isPaused, setIsPaused] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const settings = {
    speed: 800,
    autoPlay: false,
    autoplaySpeed: 2000,
    // accessibility: true,
    slidesToShow: Math.min(3, youTubeVideoIds?.length),
    infinite: true,
    // slickPlay: true,
    dots: true,
    // nextArrow: <ArrowForwardIosRounded color="primary" fontSize="large" />,
    // prevArrow: <ArrowBackIosNewRounded color="primary" fontSize="large" />,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  let videoElement = null;

  const _onReady = (event) => {
    videoElement = event;
  };

  const playVideo = (index) => {
    setShowPlayer((prevState) => {
      if (prevState.includes(index))
        return prevState.filter((item) => item !== index);
      return [...prevState, index];
    });
    setIsPaused(!isPaused);
  };

  return (
    <Box
      // marginX={{ md: 15, lg: 10, xl: 10 }}
      // paddingY={{ xs: 1, md: 5 }}
      // paddingX={{ xs: 1, md: 5 }}
      sx={
        !isSmallScreen
          ? {}
          : { flexDirection: "column-reverse", alignItems: "center" }
      }
    >
      <Slider {...settings}>
        {youTubeVideoIds.map((youTubeVideoId, i) => (
          <center key={i}>
            <Card
              elevation={1}
              sx={{
                borderRadius: "30px",
                mx: 2,
                mb: 1,
                height: isSmallScreen ? 170 : 230,
                maxWidth: isSmallScreen ? "340px" : 370,
              }}
              key={i}
              onClick={() => playVideo(i)}
            >
              {showPlayer.includes(i) ?
                <YouTube
                  onReady={_onReady}
                  key={i}
                  videoId={youTubeVideoId}
                  opts={{
                    height: isSmallScreen ? "170" : "230",
                    width: isSmallScreen ? "100%" : "370",
                    playerVars: {
                      autoplay: 1
                    }
                  }}
                />
                :
                <div class="video-box" style={{ background: `linear-gradient(0deg, #00000010, #00000010),url(https://img.youtube.com/vi/${youTubeVideoId}/maxresdefault.jpg)` }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="yplay" width="80px">
                    <path d="M23,9.71a8.5,8.5,0,0,0-.91-4.13,2.92,2.92,0,0,0-1.72-1A78.36,78.36,0,0,0,12,4.27a78.45,78.45,0,0,0-8.34.3,2.87,2.87,0,0,0-1.46.74c-.9.83-1,2.25-1.1,3.45a48.29,48.29,0,0,0,0,6.48,9.55,9.55,0,0,0,.3,2,3.14,3.14,0,0,0,.71,1.36,2.86,2.86,0,0,0,1.49.78,45.18,45.18,0,0,0,6.5.33c3.5.05,6.57,0,10.2-.28a2.88,2.88,0,0,0,1.53-.78,2.49,2.49,0,0,0,.61-1,10.58,10.58,0,0,0,.52-3.4C23,13.69,23,10.31,23,9.71ZM9.74,14.85V8.66l5.92,3.11C14,12.69,11.81,13.73,9.74,14.85Z"></path></svg>
                </div>
              }

            </Card>
          </center>
        ))}
      </Slider>
    </Box>
  );
};

export default YouTubeSlider;
