import {
  AutoModeOutlined,
  FilterAltOutlined,
  Person3Outlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import RTCCard from "./RTCCard";

const content = [
  {
    icon: (
      <VerifiedUserOutlined
        sx={{
          fontSize: "2.7em",
          "@media(max-width:767px)": {
            fontSize: "2em",
          },
        }}
      />
    ),
    title: "Agent Free Model",
    desc: "We at Memorable Your Trip believe in one-on-one communication with our travelers. We have  direct to consumer model where travelers deal with us with no agent involved.",
  },
  {
    icon: (
      <Person3Outlined
        sx={{
          fontSize: "2.7em",
          "@media(max-width:767px)": {
            fontSize: "2em",
          },
        }}
      />
    ),
    title: "50% Women Travellers",
    desc: `Our experienced trip leaders accompany all travel batches, enable the ease & safety in traveling for all and especially women. We're proud to say that almost 50% of our travelers are women.`,
  },
  {
    icon: (
      <AutoModeOutlined
        sx={{
          fontSize: "2.7em",
          "@media(max-width:767px)": {
            fontSize: "2em",
          },
        }}
      />
    ),
    title: "Transparency with Travelers",
    desc: `Our travel experts always provide authentic scenarios and never sugarcoat. Through this open communication channel we have built an honest and transparent relationship with our travelers.`,
  },
  {
    icon: (
      <FilterAltOutlined
        sx={{
          fontSize: "2.7em",
          "@media(max-width:767px)": {
            fontSize: "2em",
          },
        }}
      />
    ),
    title: "Filtering Like Minded Travelers",
    desc: `To ensure the agreement among the batch that everyone's able to enjoy, we filter travellers based on factors such as age groups, comfort of traveling with the opposite gender and much more.`,
  },
];

const ReasonToCountOnUs = () => {
  const settings = {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Box mt={5} mb={5} sx={{
    paddingLeft:"80px",
    paddingRight:"80px",
    "@media(max-width:767px)": {
      paddingLeft:"5px",
      paddingRight:"5px",
    },}}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 800,
          textAlign: "center",
          color: "#2f86a6",
          "@media(max-width:767px)": {
            fontSize: "24px !important",
          },
        }}
      >
        Reasons To Count On Us!
      </Typography>
      <Slider {...settings}>
        {content.map((c, i) => (
          <RTCCard key={i} {...c} />
        ))}
      </Slider>
    </Box>
  );
};

export default ReasonToCountOnUs;
