import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DurationDropDown from "./DurationDropDown";
import MonthDropDown from "./MonthDropDown";

const FilterDropDownCategory = ({ title, trips, setCachedTrips }) => {
  const [month, setMonth] = useState("all");
  const [durationId, setDurationId] = useState(0);
  const handleFilterTrips = () => {
    const filteredTrips = trips.filter((trip) => {
      const isMonthMatch =
        month === "all" ||
        !trip.tripDates[0] ||
        trip.tripDates[0].length === 0 ||
        trip.tripDates[0].some((td) => {
          const formattedDate = new Date(td).toLocaleDateString("en-US", {
            month: "2-digit",
            year: "numeric",
          });
          return formattedDate === month;
        });

      const isDurationMatch =
        durationId === 0 || trip.durationId === durationId;

      return isMonthMatch && isDurationMatch;
    });
    setCachedTrips(filteredTrips);
  };

  useEffect(() => {
    handleFilterTrips();
  }, [month, durationId, trips]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      ml={1}
      mb={1}
    >
      {title && (
        <Typography
          variant="h4"
          sx={{
            mb: 2,
            color: "#2f86a6",
            fontSize: {
              xs: "22px",
              md: "35px",
            },
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      )}

      <Stack direction="row" spacing={1}>
        <MonthDropDown month={month} setMonth={setMonth} />
        <DurationDropDown
          durationId={durationId}
          setDurationId={setDurationId}
        />
      </Stack>
    </Stack>
  );
};

export default FilterDropDownCategory;
