import { Box, FormHelperText, Typography } from "@mui/material";
import React from "react";
import FileUpload from "react-material-file-upload";

const FileUploadPDF = ({
  files,
  setFiles,
  disabled,
  fieldName,
  errors,
  setErrors,
  title,
  multiple = false,
  size = 5,
}) => {

  const reader = new FileReader();
  const handleFileChange = (uploads) => {
    // setFiles([]);
    const selectedFile = uploads[0];
    console.log(selectedFile);
    reader.readAsArrayBuffer(selectedFile);
    if (selectedFile) {
      if (reader) {
      reader.onloadend = (e) => {
        const newErrors = {
          ...errors,
        };
        if (selectedFile.size <= (5 * 1024 * 1024)) {
          setFiles(uploads);
          newErrors[fieldName] = "";
          setErrors(newErrors);
        } else {
          newErrors[
            fieldName
          ] = `Selected File should be less than ` + size + ` MB`;
          setErrors(newErrors);
        }
      };
      }
    }
  };
  return (
    <Box>
      <FileUpload
        value={files}
        maxSize={5 * 1024 * 1024}
        onChange={e =>
          handleFileChange(e)}
        disabled={disabled}
        title={
          <Box>
            <Typography variant="body1">
              Drag & drop a <strong>{title}</strong> PDF file here, or click
              to select file
            </Typography>
            <Typography variant="caption">
              We recommend to upload PDF of
              <strong>
                {size} MB
              </strong>
            </Typography>
          </Box>
        }
        buttonText="Select"
        accept=".pdf"
      />
      <FormHelperText error={!!errors[fieldName]}>
        {errors[fieldName]}
      </FormHelperText>
    </Box>
  );
};

export default FileUploadPDF;
