import { PersonRounded } from "@mui/icons-material";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";

const RTCCard = ({
  icon = (
    <PersonRounded
      sx={{
        fontSize: "2em",
      }}
    />
  ),
  title = "Agent Free Model",
  desc = ` We at Memorable Your Trip believe in one-on-one communication with our
            travellers. We have direct to consumer model where travellers deal
            with us with no agent involved.`,
}) => {
  return (
    <Box sx={{ mx: 2, mb: 3 }}>
      <Avatar
        sx={{
          position: "relative",
          top: 50,
          left: "50%",
          backgroundColor: "#2f86a6",
          transform: "translateX(-50%)",
          width: "90px",
          height: "90px",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
          "@media(max-width:767px)": {
            width: "70px",
            height: "70px",
          },
        }}
      >
        {icon}
      </Avatar>
      <Card
        raised
        sx={{
          pt: 8,
          borderRadius: 5,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: 16,
          }}
          align="center"
        >
          {title}
        </Typography>
        <CardContent style={{ textAlign: "center" }} sx={{minHeight:"210px",'@media(max-width:1400px)':{minHeight:"230px !important"},'@media(max-width:767px)':{minHeight:"100px !important"}}}>
          <Typography variant="caption" fontSize={14} color={"GrayText"}>
            {desc}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default RTCCard;
