import { Alert, Slide, Snackbar } from "@mui/material";
import useTostify from "../../hooks/useTostify";

const Tostify = () => {
  const { notification, setNotification } = useTostify();
  const {
    open = false,
    variant = "standard",
    position = {
      vertical: "top",
      horizontal: "right",
    },
    msg = "Hi there I'm tostify.",
    severity = "info",
  } = notification;

  const TransitionLeft = (props) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={position}
      onClose={() => setNotification({ ...notification, open: false })}
      TransitionComponent={TransitionLeft}
    >
      <Alert
        variant={variant}
        onClose={() => setNotification({ ...notification, open: false })}
        severity={severity}
      >
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default Tostify;
