import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import AppBar from "../layouts/ApplicationBar";

const BlankContainer = ({
  //   banner = <Banner />,
  banner = "",
  bannerImage = "/cruise_bg.webp",
  // bannerImage = "",
  bannerImgAlt = "Banner",
  ...props
}) => {
  return (
    <Paper
      sx={{
        // backgroundColor: "#EFF5F5",
        // backgroundImage:"url('../dotgrid.png')",
        backgroundSize: "contain",
        backgroundRepeat: "repeat",
        minHeight: "100vh",
        paddingRight: "0 !important",
      }}
    >
      <AppBar />
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          zIndex: 0,
        }}
      >
        <img
          src="/cruise_bg.webp"
          alt={bannerImgAlt}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            background: "#0006",
            width: "100%",
            height: "100vh",
            position: "absolute",
            top: "0",
          }}
        />
      </Box>

      {banner}
      {/* <Box
        sx={{
          position: "absolute",
          bottom: 30,
          width: "100%",
          zIndex: 1,
          textAlign: "center",
        }}
      ></Box> */}
      {/* <Box
        sx={{
          mb: 2,
          padding: 2,
          backgroundColor: "white",
        }}
      >
        {props.children}
      </Box> */}

      <a
        href="https://wa.me/918287734456"
        target="_blank"
        style={{ zIndex: "9", position: "relative" }}
      >
        <img src="/whatsapp_icon.svg" class="callIcon"></img>
      </a>
    </Paper>
  );
};

export default BlankContainer;
