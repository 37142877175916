import {
  Avatar,
  CardMedia,
  Chip,
  CircularProgress,
  Tooltip,
  Zoom,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";

const ImageChip = ({ image, setImage, type = undefined }) => {
  const { setNotification } = useTostify();
  const [deleting, setDeleting] = useState(false);

  const handleDeleteImage = (keyToDelete) => {
    setDeleting(true);
    axios
      .delete(`${getCurrentDomain()}/resource/img?key=${keyToDelete}`)
      .then((res) => {
        setNotification({
          open: true,
          severity: "success",
          msg: "Deleted Successfully!!",
        });
        if (type) {
          setImage();
        } else {
          setImage({});
        }
        setDeleting(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while Deleting Image!!",
        });
        setDeleting(false);
      });
  };
  return (
    <Tooltip
      title={
        <CardMedia component="img" height="140" image={image.url} alt="Image" />
      }
      placement="top"
      TransitionComponent={Zoom}
      arrow
      key={image.key}
    >
      <Chip
        label={image.key}
        sx={{
          cursor: "pointer",
        }}
        disabled={deleting}
        onDelete={() => handleDeleteImage(image.key)}
        avatar={<Avatar src={image.url} />}
        deleteIcon={
          deleting ? <CircularProgress size={20} color="inherit" /> : null
        }
      />
    </Tooltip>
  );
};

export default ImageChip;
