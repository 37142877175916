import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import useTostify from "../../hooks/useTostify";
import { getCurrentDomain } from "../common/utils";
import CategoryListView from "./CategoryListView";

const AsyncCategoryAutocomplete = ({
  mappedCategories,
  setMappedCategories,
}) => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const { setNotification } = useTostify();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;
    if (!open) {
      return undefined;
    }
    setLoading(true);
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          for: "trip",
        },
      })
      .then((res) => {
        setLoading(false);
        if (active) {
          setCategories(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trips!!",
        });
      });

    return () => {
      active = false;
    };
  }, [open, setNotification]);

  useEffect(() => {
    if (!open) {
      setCategories([]);
    }
  }, [open]);
  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={2}
      id="asynchronous-category"
      name="categoryIds"
      options={categories}
      value={mappedCategories}
      disableCloseOnSelect
      noOptionsText="No Categories"
      onChange={(e, value) => {
        setMappedCategories(value);
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      getOptionLabel={(option) => option.categoryName}
      renderOption={(props, option, { selected }) => (
        <CategoryListView {...option} {...props} selected={selected} />
      )}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          label="Mapped Categories (Optional)"
          placeholder="Select Categories..."
          InputProps={{
            ...params.InputProps,
            type: "search",
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : // <Preloader />
                null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsyncCategoryAutocomplete;
