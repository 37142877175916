import { CssBaseline } from "@mui/material";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Tostify from "./components/layouts/Tostify";
import AuthProvider from "./context/AuthProvider";
import TostifyProvider from "./context/TostifyProvider";
import AccessDenied from "./pages/AccessDenied";
import AdminCategories from "./pages/AdminCategories";
import AdminDashboard from "./pages/AdminDashboard";
import AdminTrips from "./pages/AdminTrips";
import BookingTripView from "./pages/BookingTripView";
import CategoryView from "./pages/CategoryView";
import CruiseTrips from "./pages/CruiseTrips";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import UpcomingView from "./pages/UpcomingView";

function App() {
  return (
    <Router>
      <TostifyProvider>
        <AuthProvider>
          <CssBaseline />
          <Tostify />
          <Routes>
            {/* Home page */}
            {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="/CruiseTrips" element={<CruiseTrips />} />
            <Route path="/trip/:tripSlug" element={<BookingTripView />} />
            <Route path="/:categorySlug" element={<CategoryView />} />
            <Route path="/upcomingtrips/:monthSlug" element={<UpcomingView />} />
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/trips" element={<AdminTrips />} />
            <Route path="/admin/categories" element={<AdminCategories />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<NotFound />} />
            {/* Not autorized page */}
          </Routes>
        </AuthProvider>
      </TostifyProvider>
    </Router>
  );
}

export default App;
