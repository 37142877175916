import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import ReviewCard from "./ReviewCard";

const content = [
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjVPre26EpthRfR4FenlBdBi6YI9SiZScxf_H8sT76OMFPw=w150-h150-p-rp-mo-ba2-br100",
    title: "Lalit Mendadkar",
    subTitle: "Great Service",
    desc: "Absolutely amazing! Great support throughout the trip from Akash and Ravi. From Mumbai to Delhi and then Manali, Sissu, Tosh, Sethan. All the places are absolutely stunning, Hotels provided by them are also good. All Cab drivers also so polite and gives you total sightseeing experience. Thank you Memorable Your trip.",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjXBLwXsZHM9J5beJfLVamXkVnpJjrFfG5uURvX8OO59Tg=w150-h150-p-rp-mo-br100",
    title: "Manika",
    subTitle: "Blissful Experience",
    desc: "It was my first trip organised by Memorable Your Trip to  Manali in such a reasonable rate .Everything was perfect. No inconvenience was caused during this trip. Looking forward for more trips to be organised by Memorable Your Trip.",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjUz0SPo0izTW2H7uMpAAfFHuSgtUJPaUR9slCO7PV-2SBQ=w150-h150-p-rp-mo-br100",
    title: "Anurag Mishra",
    subTitle: "Best Trip Ever",
    desc: "I booked Bali trip with memorable your trip, for 6N and 7D (13feb  to 20feb 2024), whole trip was wonderfully managed by team of Memorable your trip. I would appreciate the effort of Avantika and Preeti for continuous coordination with local Bali team that made my trip experience really smooth. I will certainly choose memorable your trip for my upcoming trips. Thanks for making my honeymoon trip experience awesome. ",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjVGMUzMfIlP23pbFBDhysb0juIeNtrZgGsn-nqOb8H-96Y=w150-h150-p-rp-mo-ba2-br100",
    title: "Nikhil Gupta",
    subTitle: "Awesome  Experience",
    desc: "Great experience to have booked Thailand trip with them. Preeti was very supportive at every stage. Except for one accommodation everything was superbly planned and delivered.",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjUB4ZzexDXUZTWQXRiQASgN3EkxFkrh_mPIfPwXaeCFrtE5=w150-h150-p-rp-mo-br100",
    title: "Santosh Chand",
    subTitle: "Excellent Service",
    desc: "I found the service and response of this travel agency to be excellent. Easily 5-stars I was extremely satisfied with memorable your trip.",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjU1Bf0jshhmDpB9dsFokAHH3m9uua1PQno5X2dkJpIGj1A=w150-h150-p-rp-mo-br100",
    title: "Shital Chavan",
    subTitle: "Highly Recommended",
    desc: "Good service nice hotel Recommended thanks you so much,🙏 services very nice packages also budget friendly tour…",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjVzRjs8p8NAkD_nqM_pIMqsAzw1TkJmV_W24ftMeYIONvk=w150-h150-p-rp-mo-br100",
    title: "Bharat Baisoya",
    subTitle: "Great Experience",
    desc: "Memorable Your trip provide best packages at nominal rates. Their response time is extremely good. Aakash is so cooperative and guide us to plan our visit perfectly. The cab driver Mohan ji who is provided to us is also very good. The Manali View Resort has excellent rooms with great views and very good staff.",
  },
  {
    img: "https://lh3.googleusercontent.com/a-/ALV-UjV6MhW0odfhN5GVc-woebPFp7br5n4u_k8YoUJ7_wkjQh0=w150-h150-p-rp-mo-br100",
    title: "Amit Mishra",
    subTitle: "Great Service",
    desc: "Very nice experience with akash & Ravi..both are very helping and nice person...and trip planning and facilities is best with reasonable package..",
  },
];
const MakeYouHappier = ({ slidesToShow = 3 }) => {
  const settings = {
    speed: 500,
    slidesToShow,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <ArrowForwardIosRounded color="primary" fontSize="small" />,
    prevArrow: <ArrowBackIosNewRounded color="primary" fontSize="small" />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Box
      mt={7}
      mb={5}
      sx={{
        paddingLeft: "80px",
        paddingRight: "80px",
        "@media(max-width:767px)": {
          paddingLeft: "5px",
          paddingRight: "5px",
        },
      }}
      // sx={{
      //   boxShadow: "0 0 1px #bfbfbf75 !important",
      //   "@media(max-width:767px)": {
      //     marginTop: "38px",
      //     boxShadow: "0 0 10px #bfbfbf75 !important",
      //   },
      // }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 800,
          textAlign: "center",
          color: "#2f86a6",
          "@media(max-width:550px)": {
            fontSize: "24px",
          },
        }}
      >
        Memorable Your Trip Make You Happier
      </Typography>
      <Typography
        variant="body1"
        component="div"
        color="gray"
        sx={{ textAlign: "center" }}
      >
        More Than 10000 travellers have trusted Memorable Your Trip with their
        dream destination
      </Typography>
      <Slider {...settings} className="makehappier">
        {content.map((r, i) => (
          <ReviewCard key={i} {...r} />
        ))}
      </Slider>
    </Box>
  );
};

export default MakeYouHappier;
