import { createContext, useState } from "react";

export const TostifyContext = createContext({
  notification: {},
  setNotification: () => {},
});

const TostifyProvider = ({ children }) => {
  const [notification, setNotification] = useState({});

  return (
    <TostifyContext.Provider value={{ notification, setNotification }}>
      {children}
    </TostifyContext.Provider>
  );
};

export default TostifyProvider;
