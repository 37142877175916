import { Box, FormHelperText, Typography } from "@mui/material";
import React from "react";
import FileUpload from "react-material-file-upload";

const ImageUpload = ({
  files,
  setFiles,
  disabled,
  fieldName,
  errors,
  setErrors,
  title,
  multiple = false,
  size = { width: 1024, height: 600 },
}) => {
  const handleFileChange = (uploads) => {
    const selectedFile = uploads[0];
    if (selectedFile) {
      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      img.onload = () => {
        const { width, height } = img;
        const newErrors = {
          ...errors,
        };
        if (width === size.width && height === size.height) {
          setFiles(uploads);
          newErrors[fieldName] = "";
          setErrors(newErrors);
        } else {
          newErrors[
            fieldName
          ] = `Selected image should be ${size.width} X ${size.height}px.`;
          setErrors(newErrors);
        }
      };
    }
  };
  return (
    <Box>
      <FileUpload
        value={files}
        onChange={handleFileChange}
        disabled={disabled}
        title={
          <Box>
            <Typography variant="body1">
              Drag & drop a <strong>{title}</strong> image file here, or click
              to select file
            </Typography>
            <Typography variant="caption">
              We recommend to upload image of size{" "}
              <strong>
                {size.width} X {size.height} px
              </strong>
            </Typography>
          </Box>
        }
        buttonText="Select"
        multiple={multiple}
        accept=".jpg,.png,.gif,.svg,.webp"
      />
      <FormHelperText error={!!errors[fieldName]}>
        {errors[fieldName]}
      </FormHelperText>
    </Box>
  );
};

export default ImageUpload;
