import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

const getNextSixMonths = () => {
  const nextMonths = Array.from({ length: 6 }, (_, i) => {
    const date = new Date();
    // date.setMonth(date.getMonth() + i + 1);
    date.setMonth(date.getMonth() + i);
    return {
      value: date.toLocaleDateString('en-US', {
        month: '2-digit',
        year: 'numeric',
      }),
      name: date.toLocaleString('default', { month: 'short' }),
    };
  });
  return [{ value: 'all', name: 'All Month' }, ...nextMonths];
};

export default function MonthDropDown({month, setMonth }) {
  const dropDrownData = getNextSixMonths();
  return (
    <TextField
      select
      size='small'
      value={month}
      label='Month'
      className='dropdown_fieldset'
      sx={{
        minWidth: 90,
        color:"red !important"
      }}
      onChange={(e) => setMonth(e.target.value)}
      id='select-month-small'
    >
      {dropDrownData.map((ddd, i) => (
        <MenuItem key={i} value={ddd.value} className='dropDown_menu' sx={{fontSize:"13px !important",lineHeight:"12px",minHeight:"0px",color:"#2f86a6"}}>
          {ddd.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
