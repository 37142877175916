import {
  CloseOutlined,
  KeyboardArrowDownRounded,
  PhoneRounded,
  SearchOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Drawer,
  Grid,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useTostify from "../../hooks/useTostify";
import Preloader from "../common/Preloader";
import { getCurrentDomain } from "../common/utils";
import SearchDrawer from "../nav/SearchDrawer";
import DrawerMenu from "../nav/drawer";

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // appBar: {
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
}));

const ApplicationBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [intAnchorEl, setIntAnchorEl] = useState(null);
  const [dAnchorEl, setDAnchorEl] = useState(null);
  const [uAnchorEl, setUAnchorEl] = useState(null);
  const intOpen = Boolean(intAnchorEl);
  const dOpen = Boolean(dAnchorEl);
  const uOpen = Boolean(uAnchorEl);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [loadingNavbar, setLoadingNavbar] = useState(false);

  const [upcomingTrips, setUpcomingTrips] = useState([]);

  const [isScrolled, setIsScrolled] = useState(false);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > window.innerHeight * 0.1) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const toggleSearchDrawer = (isOpen) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSearchDrawerOpen(isOpen);
  };

  const { setNotification } = useTostify();
  const [intCategories, setIntCategories] = useState([]);
  const [dCategories, setDCategories] = useState([]);
  const [uCategories, setUCategories] = useState([]);
  const [scrolltopdata, setscrolltopdata] = useState("");

  useEffect(() => {
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          for: "category",
        },
      })
      .then((res) => {
        const categorizedData = res.data.reduce(
          (acc, category) => {
            if (category.isInternationalCategory) {
              acc.intCategories.push(category);
            }
            if (category.isDomesticCategory) {
              acc.dCategories.push(category);
            }
            // if (category.isUpcomingCategory) {
            //   acc.uCategories.push(category);
            // }
            return acc;
          },
          {
            intCategories: [],
            dCategories: [],
            // uCategories: [],
          }
        );
        let monthTrips = computeDates(res.data);

        setIntCategories(categorizedData.intCategories);
        setDCategories(categorizedData.dCategories);
        setUCategories(monthTrips);
        setUpcomingTrips(monthTrips);
        setLoadingNavbar(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingNavbar(false);
      });
  }, [setNotification]);

  const navList = [
    ...intCategories,
    ...dCategories,
    {
      categoryName: "upcoming",
      isCollapsible: true,
      trips: uCategories,
    },
  ];
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 50) {
        setscrolltopdata("");
      } else {
        setscrolltopdata("scrolled");
      }
    });
  }, []);

  let computeDates = (data) => {
    const allTrips = data.flatMap((item) => item.trips);
    const allTripDates = allTrips.flatMap((trip) => trip.tripDates.flat());

    const allMonths = allTripDates.map((date) => {
      return new Date(date).toLocaleString("en-US", {
        month: "long",
        timeZone: "UTC",
      });
    });
    const monthOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const uniqueMonths = [...new Set(allMonths)];
    uniqueMonths.sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));

    return uniqueMonths;
  };

  return (
    <AppBar
      className={`header-wrapper ${scrolltopdata}`}
      elevation={0}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {loadingNavbar ? (
        <Grid container justifyContent="center">
          <Grid item pt={5} pb={5}>
            <Preloader />
            {/* <CircularProgress size={30} /> */}
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              minHeight: "40px !important",
              justifyContent:"space-between",
              // paddingTop: 1,
              // paddingBottom: 1,
              "@media(max-width:767px)": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              {isScrolled ? (
                <img src="/logo_white.png" alt="Logo" className="header_logo" />
              ) : (
                <img src="/logo_white.png" alt="Logo" className="header_logo" />
              )}
            </Link>
            <Drawer
              anchor="right"
              open={openDrawer}
              onClose={handleDrawerToggle}
              className="sticky-drawer"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  height: "100vh !important",
                  top: "60px",
                },
              }}
            >
              <DrawerMenu navList={navList} />
            </Drawer>

            <Button
              variant="text"
              startIcon={<PhoneRounded />}
              component={Link}
              to="tel:+918287734456"
              sx={{
                fontSize: isSmallScreen ? "10px" : "14px",
                color: "white",
                fontWeight: 600,
                display: isSmallScreen ? "none" : "flex",
                marginLeft:"80px",
                "@media(max-width:1199px)": {
                  marginLeft:"30px",
                },
                "@media(max-width:1024px)": {
                  marginLeft:"0",
                }
              }}
            >
              8287734456
            </Button>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Button
                onClick={() => navigate("/")}
                // sx={{
                //   mt: 1,
                //   mb: 4,
                //   color: "white",
                //   display: "flex",
                //   textTransform: "none",
                //   borderBottom: "2px solid white",
                //   borderRadius:"0px !important",
                // }}/
                sx={{
                  borderBottom: "2px solid white",
                }}
                className="headerMenu"
              >
                Home
              </Button>
              {intCategories.length > 0 && (
                <Button
                  endIcon={<KeyboardArrowDownRounded />}
                  aria-owns={intAnchorEl ? "int-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    if (intAnchorEl !== e.currentTarget) {
                      setIntAnchorEl(e.currentTarget);
                    }
                  }}
                  onMouseOver={(e) => {
                    if (intAnchorEl !== e.currentTarget) {
                      setIntAnchorEl(e.currentTarget);
                    }
                  }}
                  // sx={{
                  //   my: 2,
                  //   color: "white",
                  //   display: "flex",
                  //   textTransform: "none",
                  // }}
                  className="headerMenu"
                >
                  International Trips
                </Button>
              )}

              {dCategories.length > 0 && (
                <Button
                  endIcon={<KeyboardArrowDownRounded />}
                  aria-owns={dAnchorEl ? "d-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    if (dAnchorEl !== e.currentTarget) {
                      setDAnchorEl(e.currentTarget);
                    }
                  }}
                  onMouseOver={(e) => {
                    if (dAnchorEl !== e.currentTarget) {
                      setDAnchorEl(e.currentTarget);
                    }
                  }}
                  // sx={{
                  //   my: 2,
                  //   color: "white",
                  //   display: "flex",
                  //   textTransform: "none",
                  // }}
                  className="headerMenu"
                >
                  Domestic Trips
                </Button>
              )}

              <Button
                onClick={() => navigate("/weekend-trip-from-delhi")}
                // sx={{
                //   my: 2,
                //   color: "white",
                //   display: "flex",
                //   textTransform: "none",
                // }}
                className="headerMenu"
              >
                Weekend Trips
              </Button>

              {upcomingTrips && (
                <Button
                  endIcon={<KeyboardArrowDownRounded />}
                  aria-owns={uAnchorEl ? "u-menu" : undefined}
                  aria-haspopup="true"
                  onClick={(e) => {
                    if (uAnchorEl !== e.currentTarget) {
                      setUAnchorEl(e.currentTarget);
                    }
                    window.location.reload();
                  }}
                  onMouseOver={(e) => {
                    if (uAnchorEl !== e.currentTarget) {
                      setUAnchorEl(e.currentTarget);
                    }
                  }}
                  // sx={{
                  //   my: 2,
                  //   color: "white",
                  //   display: "flex",
                  //   textTransform: "none",
                  // }}
                  className="headerMenu"
                >
                  Upcoming Trips
                </Button>
              )}

              <Button
                onClick={() => console.log("CruiseTrips")}
                // sx={{
                //   my: 2,
                //   color: "white",
                //   display: "flex",
                //   textTransform: "none",
                // }}
                className="headerMenu"
              >
                Blogs
              </Button>

              <Button
                onClick={() => navigate("/CruiseTrips")}
                // sx={{
                //   my: 2,
                //   color: "white",
                //   display: "flex",
                //   textTransform: "none",
                // }}
                className="headerMenu"
              >
                Cruise Trips
              </Button>

              <Menu
                className="menuLink"
                id="int-menu"
                anchorEl={intAnchorEl}
                open={intOpen}
                onClose={() => setIntAnchorEl(null)}
                disableScrollLock={true}
                MenuListProps={{ onMouseLeave: () => setIntAnchorEl(null) }}
              >
                {intCategories.map((c, i) => (
                  <MenuItem
                    key={i}
                    component={Link}
                    to={`/${c.slug}`}
                    className="menuItemsub"
                  >
                    {c.categoryName}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                className="menuLink"
                id="d-menu"
                anchorEl={dAnchorEl}
                open={dOpen}
                onClose={() => setDAnchorEl(null)}
                disableScrollLock={true}
                MenuListProps={{ onMouseLeave: () => setDAnchorEl(null) }}
              >
                {dCategories.map((c, i) => (
                  <MenuItem
                    key={i}
                    component={Link}
                    to={`/${c.slug}`}
                    className="menuItemsub"
                  >
                    {c.categoryName}
                  </MenuItem>
                ))}
              </Menu>
              <Menu
                className="menuLink"
                id="u-menu"
                anchorEl={uAnchorEl}
                open={uOpen}
                onClose={() => setUAnchorEl(null)}
                disableScrollLock={true}
                MenuListProps={{ onMouseLeave: () => setUAnchorEl(null) }}
              >
                {uCategories.map((c) => (
                  <MenuItem
                    key={c}
                    component={Link}
                    to={`/upcomingtrips/${c}`}
                    className="menuItemsub"
                  >
                    {c}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="black"
                onClick={toggleSearchDrawer(!searchDrawerOpen)}
                className={classes.menuButton}
              >
                <SearchOutlined
                  sx={{
                    color: "white !important",
                    fontSize: "32px !important",
                  }}
                />
              </IconButton>
            </Box>
            <Drawer
              anchor="bottom"
              open={searchDrawerOpen}
              onClose={toggleSearchDrawer(false)}
              onOpen={toggleSearchDrawer(true)}
              swipeAreaWidth={250}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}

            >
              <SearchDrawer toggleDrawer={toggleSearchDrawer} />
            </Drawer>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="black"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                {openDrawer ? (
                  <CloseOutlined
                    sx={{
                      color: "white !important",
                      fontSize: "32px !important",
                    }}
                  />
                ) : (
                  <MenuIcon
                    sx={{
                      color: "white !important",
                      fontSize: "32px !important",
                    }}
                  />
                )}
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                display: { xs: "flex", md: "none" },
                "@media(max-width:899px)": {
                  display: "none",
                },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon
                  sx={{
                    color: "white !important",
                    fontSize: "32px !important",
                  }}
                />
              </IconButton>
              <Menu
                className="menuLink"
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu} className="mobileMenu">
                  <Typography textAlign="left">Home</Typography>
                </MenuItem>
                {intCategories.length > 0 && (
                  <MenuItem
                    onClick={(e) => setIntAnchorEl(e.currentTarget)}
                    className="mobileMenu"
                  >
                    <Typography textAlign="center">
                      International Trips
                    </Typography>
                    <ListItemIcon>
                      <KeyboardArrowDownRounded />
                    </ListItemIcon>
                  </MenuItem>
                )}
                {dCategories.length > 0 && (
                  <MenuItem
                    onClick={(e) => setDAnchorEl(e.currentTarget)}
                    className="mobileMenu"
                  >
                    <Typography textAlign="center">Domestic Trips</Typography>
                    <ListItemIcon>
                      <KeyboardArrowDownRounded />
                    </ListItemIcon>
                  </MenuItem>
                )}
                <MenuItem onClick={handleCloseNavMenu} className="mobileMenu">
                  <Typography textAlign="center">Weekend Trips</Typography>
                </MenuItem>
                {uCategories.length > 0 && (
                  <MenuItem
                    onClick={(e) => setUAnchorEl(e.currentTarget)}
                    className="mobileMenu"
                  >
                    <Typography textAlign="center">Upcoming Trips</Typography>
                    <ListItemIcon>
                      <KeyboardArrowDownRounded />
                    </ListItemIcon>
                  </MenuItem>
                )}
                <MenuItem onClick={handleCloseNavMenu} className="mobileMenu">
                  <Typography textAlign="center">Blogs</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu} className="mobileMenu">
                  <Typography textAlign="center">Cruise Trips</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      )}
    </AppBar>
  );
};
export default ApplicationBar;
