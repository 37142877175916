import {
  AccessTimeRounded,
  EventRounded,
  PlaceRounded,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import useTostify from "../../hooks/useTostify";
import {
  formatDate,
  getCurrentDomain,
  getDurationNameById,
} from "../common/utils";
import TripCard from "./TripCard";

const RelatedTrips = () => {
  const search = useLocation();
  const urlParams = new URLSearchParams(search.search);
  const categoryName = urlParams.get("id");
  const selectedTrip = search.pathname.split("/")[2];
  const [hovered, setHovered] = useState(false);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [relatedTrips, setRelatedTrips] = useState({});
  const { setNotification } = useTostify();

  useEffect(() => {
    setLoadingCategory(true);
    if (selectedTrip != null || selectedTrip != undefined) {
      axios
        .get(`${getCurrentDomain()}/categories/related`, {
          params: {
            slug: selectedTrip,
            categoryName: categoryName,
            for: "related",
          },
        })
        .then((res) => {
          if (res.data[0].trips.length > 0) {
            const relatedTrips = filterRelatedTrip(res.data[0]);
            setRelatedTrips(relatedTrips);
          } else {
            setRelatedTrips([]);
          }
          setLoadingCategory(false);
        })
        .catch((err) => {
          // setNotification({
          //   open: true,
          //   severity: "error",
          //   msg: "Oops Error while fetching Related Trips!!",
          // });
          setLoadingCategory(false);
        });
    }
  }, []);

  const filterRelatedTrip = (data) => {
    const newarray = data.trips.filter((trip) => trip.slug !== selectedTrip);
    return newarray;
  };

  const itemCount = relatedTrips.length;

  const settings = {
    speed: 800,
    autoPlay: true,
    autoplaySpeed: 3000,
    slidesToShow: itemCount > 3 ? 4 : itemCount,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "5%",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: itemCount > 3 ? 3.5 : itemCount,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: itemCount > 2 ? 2.5 : itemCount,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: itemCount > 1 ? 1.5 : itemCount,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box mt={2}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          // sx={{ display: "flex", justifyContent: "center" }}
        >
          {relatedTrips.length > 0 ? (
            <Typography
              variant="h4"
              className="styled_heading"
              sx={{
                fontSize: "28px",
                fontWeight: 800,
                textAlign: "center",
                color: "#2f86a6",
                marginTop: "25px",
                marginBottom: "20px",
              }}
            >
              Related Trips
            </Typography>
          ) : null}
          {relatedTrips.length > 0 ? (
            <Slider {...settings}>
              {relatedTrips.map((td, i) => (
                  <TripCard
                  {...td}
                  slug={td.slug}
                  categoryName={td.categoryName} />

              ))}
            </Slider>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RelatedTrips;
