import {
  CategoryRounded,
  ConnectingAirportsRounded,
  DashboardRounded,
} from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
const adminRoutes = [
  {
    label: "Trips",
    value: "/admin/trips",
    icon: <ConnectingAirportsRounded />,
  },
  { label: "Dashboard", value: "/admin/dashboard", icon: <DashboardRounded /> },
  {
    label: "Categories",
    value: "/admin/categories",
    icon: <CategoryRounded />,
  },
];
const AdminBottomNavigation = ({ menu, setMenu }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const handleBottomNavigation = (event, newValue) => {
    event.preventDefault();
    setMenu(newValue);
    const selectedRoute = adminRoutes.find((route) => route.value === newValue);
    if (selectedRoute && location.pathname !== selectedRoute.value) {
      navigate(selectedRoute.value);
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        value={menu}
        onChange={handleBottomNavigation}
      >
        {adminRoutes.map((route, index) => (
          <BottomNavigationAction
            key={index}
            label={route.label}
            value={route.value}
            icon={route.icon}
          />
        ))}
      </BottomNavigation>
    </Paper>
  );
};

export default AdminBottomNavigation;
