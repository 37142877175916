import { CloseOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import LeadSearchBar from "../layouts/LeadSearchBar";
const SearchDrawer = ({ toggleDrawer }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="swipe-search-wrapper">
      <IconButton
        size="large"
        color="black"
        className="searchSwipe-closer"
        onClick={toggleDrawer(false)}
      >
        <CloseOutlined
          sx={{
            fontSize: "32px !important",
          }}
        />
      </IconButton>
      <LeadSearchBar />
    </div>
  );
};

export default SearchDrawer;
    