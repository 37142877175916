import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EnquiryForm from "./EnquiryForm";

const InDoubtCantDecide = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      backgroundColor="#2f86a6"
      mt={2}
      sx={{
        marginLeft: "-16px",
        marginRight: "-16px",
        padding: "20px 80px",
        "@media(max-width:767px)": {
          padding: "20px 20px",
        },
      }}
    >
      <Box paddingY={{ xs: 1, md: 5 }}>
        <Card sx={{ borderRadius: 10, p: 1.2 }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={2}
          >
            <Grid item md={6} sm={12}>
              <CardMedia
                component="img"
                image="https://static.vecteezy.com/system/resources/previews/006/409/485/original/people-thinking-to-make-decision-problem-solving-and-find-creative-ideas-with-question-mark-in-flat-cartoon-background-for-poster-illustration-vector.jpg"
                alt="In Doubt"
                loading="lazy"
                sx={{
                  height: { xs: "200px", sm: "225px", md: "250px" },
                  width: { xs: "300px", sm: "325px", md: "350px" },
                  margin: "auto",
                }}
                title="In Doubt"
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 800,
                  "@media(max-width:550px)": {
                    fontSize: "24px",
                  },
                }}
                color={"#2f86a6"}
              >
                Struggling to make a decision?
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  maxWidth: "80%",
                  margin: "auto",
                  lineHeight: "22px",
                  "@media(max-width:767px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Feel uncertain? Let our team of travel experts take the wheel.
                Trust their authentic insights to guide you through your
                queries.
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: 5,
                  px: 4,
                  py: 1,
                  mt: 2,
                  backgroundColor: "#2f86a6",
                }}
                onClick={() => setOpen(true)}
              >
                Reach out to us
              </Button>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  fontWeight: 500,
                }}
              >
                We're always eager to answer your calls!
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ background: "#0009" }}
      >
        <DialogContent
          sx={{
            padding: "20px !important",
            "@media(max-width:767px)": {
              padding: "5px !important",
            },
          }}
        >
          <EnquiryForm categoryName={"From Home"} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default InDoubtCantDecide;
