import {
  BookmarkRounded,
  CategoryRounded,
  CheckCircleRounded,
  ConnectingAirportsRounded,
  HomeRounded,
  LeaderboardRounded,
  YouTube,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { MuiChipsInput } from 'mui-chips-input';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentDomain } from '../components/common/utils';
import AdminBottomNavigation from '../components/layouts/AdminBottomNavigation';
import BookingTable from '../components/layouts/BookingTable';
import LeadTable from '../components/layouts/LeadTable';
import useAuth from '../hooks/useAuth';
import useTostify from '../hooks/useTostify';

const UpTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const DownTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const AdminDashboard = () => {
  const { setNotification } = useTostify();
  const [menu, setMenu] = useState('/admin/dashboard');
  const [openBookings, setOpenBookings] = useState(false);
  const [openLeads, setOpenLeads] = useState(false);
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [loadingRot, setLoadingRot] = useState(false);
  const [youTubeVideoIds, setYouTubeVideoIds] = useState([]);
  const [appId, setAppId] = useState('');
  const [saving, setSaving] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleYouTubeLinksChange = (urls) => {
    setYouTubeVideoIds([
      ...new Set(
        urls.map((url) => {
          const match = url.match(/[?&]v=([^&]+)/);
          return match ? match[1] : url;
        })
      ),
    ]);
  };

  const handleSave = () => {
    axios
      .post(
        `${getCurrentDomain()}/app/rot`,
        { youTubeVideoIds },
        {
          params: {
            appId,
          },
        }
      )
      .then((res) => {
        setNotification({
          open: true,
          severity: 'success',
          msg: 'Successfully Saved!!',
        });
        setSaving(false);
        setFormDialogOpen(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: 'error',
          msg: 'Oops Error while saving Reality of trips!!',
        });
        setSaving(false);
      });
  };

  const reloadRot = useCallback(() => {
    setLoadingRot(true);
    axios
      .get(`${getCurrentDomain()}/app/rot`)
      .then((res) => {
        setAppId(res.data[0]._id);
        setYouTubeVideoIds(res.data[0]?.youTubeVideoIds);
        setLoadingRot(false);
      })
      .catch((err) => {
        setNotification({
          open: true,
          severity: 'error',
          msg: 'Oops Error while fetching Reality of trips!!',
        });
        setLoadingRot(false);
      });
  }, [setLoadingRot, setYouTubeVideoIds, setNotification]);

  useEffect(() => {
    reloadRot();
  }, [reloadRot]);

  useEffect(() => {
    if (!isAuthenticated()) navigate('/access-denied');
  });

  return (
    <>
      <Grid
        container
        spacing={2}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={12}>
          <Typography variant='h4'>Welcome Admin!</Typography>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='success'
            startIcon={<HomeRounded />}
            sx={{ textTransform: 'none' }}
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </Button>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={1}>
            <Button
              variant='outlined'
              color='inherit'
              startIcon={<BookmarkRounded />}
              sx={{ textTransform: 'none' }}
              onClick={() => setOpenBookings(true)}
            >
              Bookings
            </Button>
            <Button
              variant='outlined'
              color='warning'
              startIcon={<LeaderboardRounded />}
              sx={{ textTransform: 'none' }}
              onClick={() => setOpenLeads(true)}
            >
              Leads
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Stack direction={'row'} spacing={1}>
            <Button
              variant='outlined'
              color='primary'
              startIcon={<ConnectingAirportsRounded />}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setMenu('/admin/trips');
                navigate('/admin/trips');
              }}
            >
              Manage Trips
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              startIcon={<CategoryRounded />}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                setMenu('/admin/categories');
                navigate('/admin/categories');
              }}
            >
              Manage Categories
            </Button>
          </Stack>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='error'
            startIcon={
              loadingRot ? (
                <CircularProgress size={12} color='error' />
              ) : (
                <YouTube />
              )
            }
            sx={{ textTransform: 'none' }}
            onClick={() => setFormDialogOpen(true)}
          >
            Manage ROT
          </Button>
        </Grid>
      </Grid>
      <Dialog
        fullWidth
        maxWidth='xl'
        open={openBookings}
        TransitionComponent={DownTransition}
        onClose={() => setOpenBookings(false)}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Bookings</DialogTitle>
        <Divider />
        <DialogContent>
          <BookingTable />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth='md'
        open={openLeads}
        TransitionComponent={UpTransition}
        onClose={() => setOpenLeads(false)}
      >
        <DialogTitle sx={{ textAlign: 'center' }}>Leads</DialogTitle>
        <Divider />
        <DialogContent>
          <LeadTable />
        </DialogContent>
      </Dialog>
      <Dialog
        open={formDialogOpen}
        onClose={() => setFormDialogOpen(false)}
        fullWidth
        maxWidth='md'
        keepMounted
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          The Reality of a trip
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Card>
            <CardContent>
              <MuiChipsInput
                fullWidth
                label='YouTube Video Ids'
                placeholder='Paste link/ID and press enter'
                size='small'
                value={youTubeVideoIds}
                onChange={handleYouTubeLinksChange}
              />
            </CardContent>
            <CardActions>
              <Button
                variant='outlined'
                fullWidth
                onClick={() => setFormDialogOpen(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={saving}
                loadingPosition='start'
                fullWidth
                startIcon={<CheckCircleRounded />}
                variant='contained'
                onClick={handleSave}
              >
                Save
              </LoadingButton>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
      <AdminBottomNavigation menu={menu} setMenu={setMenu} />
    </>
  );
};

export default AdminDashboard;
