import {
  Box,
  CardActionArea,
  CardMedia,
  Paper,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import { Link } from "react-router-dom";

export default function CustomizeTrip({ customizedCategories }) {
  return (
    <Box
      paddingY={{ xs: 1, md: 5 }}
      sx={{
        paddingLeft: "80px",
        paddingRight: "80px",
        "@media(max-width:767px)": {
          paddingLeft: "5px",
          paddingRight: "5px",
        },
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          mb: 3,
          mt: 3,
          "@media(max-width:767px)": { mt: 5 },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 800,
            color: "#2f86a6",
            "@media(max-width:550px)": {
              fontSize: "24px",
            },
          }}
        >
          Customize Your Trip
        </Typography>
        <Typography
          variant="body1"
          color={"gray"}
          sx={{
            fontWeight: "400",
            fontSize: "18px",
            "@media(max-width:767px)": {
              fontSize: "14px",
            },
          }}
        >
          We embrace the individuality and uniqueness of each traveler. Check
          out these customised trips curated with love that cater to your
          different needs!
        </Typography>
      </Box>
      <Grid container spacing={1}>
        <Grid item md={5} xs={12}>
          <Paper
            sx={{
              position: "relative",
              borderRadius: 4,
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <CardActionArea
              LinkComponent={Link}
              to={`/${customizedCategories[0]?.slug}`}
            >
              <CardMedia
                component="img"
                height={510}
                sx={{
                  width: "100%",
                  borderRadius: "inherit",
                  "@media(max-width:767px)": {
                    height: "250px",
                  },
                }}
                image={customizedCategories[0]?.bannerImage?.url}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  textAlign: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.60)",
                  textTransform: "capitalize",
                  padding: ".4rem",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: "white",
                    fontSize: "22px",
                    "@media(max-width:767px)": {
                      padding: "5px 3px",
                      fontSize: "15px",
                      lineHeight: "18px",
                    },
                  }}
                >
                  {customizedCategories[0]?.categoryName}
                </Typography>
              </Box>
            </CardActionArea>
          </Paper>
        </Grid>
        <Grid item md={7} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              {/* Second column with 250 height */}
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 4,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <CardActionArea
                  LinkComponent={Link}
                  to={`/${customizedCategories[2]?.slug}`}
                >
                  <CardMedia
                    component="img"
                    height={250}
                    sx={{
                      width: "100%",
                      borderRadius: "inherit",
                      "@media(max-width:767px)": {
                        height: "150px",
                      },
                    }}
                    image={customizedCategories[2]?.bannerImage?.url}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "rgba(0, 0, 0, 0.60)",
                      padding: ".4rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "white",
                        "@media(max-width:767px)": {
                          padding: "5px 3px",
                          fontSize: "15px",
                          lineHeight: "18px",
                        },
                      }}
                    >
                      {customizedCategories[2]?.categoryName}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              {/* Third column with 250 height */}
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 4,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <CardActionArea
                  LinkComponent={Link}
                  to={`/${customizedCategories[3]?.slug}`}
                >
                  <CardMedia
                    component="img"
                    height={250}
                    sx={{
                      width: "100%",
                      borderRadius: "inherit",
                      "@media(max-width:767px)": {
                        height: "150px",
                      },
                    }}
                    image={customizedCategories[3]?.bannerImage?.url}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "rgba(0, 0, 0, 0.60)",
                      padding: ".4rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "white",
                        "@media(max-width:767px)": {
                          padding: "5px 3px",
                          fontSize: "15px",
                          lineHeight: "18px",
                        },
                      }}
                    >
                      {customizedCategories[3]?.categoryName}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              {/* Second column with 250 height */}
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 4,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <CardActionArea
                  LinkComponent={Link}
                  to={`/${customizedCategories[4]?.slug}`}
                >
                  <CardMedia
                    component="img"
                    height={250}
                    sx={{
                      width: "100%",
                      borderRadius: "inherit",
                      "@media(max-width:767px)": {
                        height: "150px",
                      },
                    }}
                    image={customizedCategories[4]?.bannerImage?.url}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "rgba(0, 0, 0, 0.60)",
                      padding: ".4rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "white",
                        "@media(max-width:767px)": {
                          padding: "5px 3px",
                          fontSize: "15px",
                          lineHeight: "18px",
                        },
                      }}
                    >
                      {customizedCategories[4]?.categoryName}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              {/* Third column with 250 height */}
              <Paper
                sx={{
                  position: "relative",
                  borderRadius: 4,
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <CardActionArea
                  LinkComponent={Link}
                  to={`/${customizedCategories[5]?.slug}`}
                >
                  <CardMedia
                    component="img"
                    height={250}
                    sx={{
                      width: "100%",
                      borderRadius: "inherit",
                      "@media(max-width:767px)": {
                        height: "150px",
                      },
                    }}
                    image={customizedCategories[5]?.bannerImage?.url}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                      backgroundColor: "rgba(0, 0, 0, 0.60)",
                      padding: ".4rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 700,
                        color: "white",
                        "@media(max-width:767px)": {
                          padding: "5px 3px",
                          fontSize: "15px",
                          lineHeight: "18px",
                        },
                      }}
                    >
                      {customizedCategories[5]?.categoryName}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
