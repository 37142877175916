import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DurationDropDown from "./DurationDropDown";
import MonthDropDown from "./MonthDropDown";
import { useNavigate } from "react-router-dom";
import { ArrowBackIosNewOutlined, ArrowRightAltOutlined } from "@mui/icons-material";

const FilterDropDown = ({ title, trips, setCachedTrips, filter, navigationLink }) => {
  const navigate = useNavigate();
  const [month, setMonth] = useState("all");
  const [durationId, setDurationId] = useState(0);
  const handleFilterTrips = () => {
    const filteredTrips = trips.filter((trip) => {
      const isMonthMatch =
        month === "all" ||
        !trip.tripDates[0] ||
        trip.tripDates[0].length === 0 ||
        trip.tripDates[0].some((td) => {
          const formattedDate = new Date(td).toLocaleDateString("en-US", {
            month: "2-digit",
            year: "numeric",
          });
          return formattedDate === month;
        });

      const isDurationMatch =
        durationId === 0 || trip.durationId === durationId;

      return isMonthMatch && isDurationMatch;
    });
    setCachedTrips(filteredTrips);
  };

  useEffect(() => {
    handleFilterTrips();
  }, [month, durationId, trips]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems={"center"}
      spacing={1}
      ml={1}
      mb={1}
    >
      {title ?
        <Typography
          variant="h4"
          class="styled_heading"
          sx={{
            mb: 2,
            fontSize: {
              xs: "25px",
              sm: "30px",
              md: "35px",
            },
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        : null}

      {filter ? (
        <Stack direction="row" spacing={1}>
          <MonthDropDown month={month} setMonth={setMonth} />
          <DurationDropDown
            durationId={durationId}
            setDurationId={setDurationId}
          />
        </Stack>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          sx={{
            borderRadius: 10,
            mx: 1,
            padding: "5px 15px !important",
            lineHeight: "1 !important",
            fontSize: "15px",
            height:"35px",
            "@media(max-width:550px)": {
              fontSize: "13px",
            },
          }}
          onClick={() => navigate(`/${navigationLink}`)}
        >
          View All
          <ArrowRightAltOutlined sx={{ marginLeft: "5px" }} />
        </Button>
      )}
    </Stack>
  );
};

export default FilterDropDown;