import ImageResize from "quill-image-resize-module-react";
import React, { useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Box, FormHelperText } from "@mui/material";
import "./styles.css";

Quill.register("modules/imageResize", ImageResize);

const Editor = ({
  label = "Write something *",
  id,
  placeholder,
  content,
  handleChange,
  errorMsg,
}) => {
  useEffect(() => {
    Quill.register("modules/imageResize", ImageResize);
  }, []);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"], //"image", "video"
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    // "image",
    // "video",
  ];

  return (
    <Box id={id}>
      <label style={{ color: errorMsg ? "red" : "" }}>{label}</label>
      <ReactQuill
        onChange={handleChange}
        value={content}
        modules={modules}
        formats={formats}
        bounds={"#editor1"}
        placeholder={placeholder}
      />
      <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
    </Box>
  );
};

export default Editor;
