import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import EnquiryForm from "./EnquiryFormCategoryView";

const CuratedBanner = ({ monthName = "", categoryName = "" }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        top: [150, 120],
        width: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: isSmallScreen ? "column" : "row",
      }}
    >
      {monthName ? (
        <Typography
          variant="h4"
          sx={{
            color: "white !important",
            textAlign: "center",
            fontWeight: 800,
            fontSize: "calc(1.375rem + 1.5vw)",
            width: "50%",
            "@media(max-width:767px)": {
              fontSize: "28px !important",
              marginTop: "80px",
              width: "100%",
            },
          }}
        >
          Upcoming Trips in {monthName}
        </Typography>
      ) : null}
      {categoryName ? (
        <Typography
          variant="h4"
          sx={{
            color: "white !important",
            textAlign: "center",
            fontWeight: 800,
            fontSize: "calc(1.375rem + 1.5vw)",
            width: "50%",
            "@media(max-width:767px)": {
              fontSize: "28px !important",
              marginTop: "80px",
              width: "100%",
            },
          }}
        >
          {categoryName}
        </Typography>
      ) : null}

      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{
          display: { xs: "block", md: "none" },
          position: "absolute",
          background: "#2f86a6",
          top: 80,
          "@media(max-width:767px)": {
            position: "relative",
            top: 10,
          },
        }}
      >
        Get Quote
      </Button>
      <Dialog
        fullWidth
        maxWidth="md"
        sx={{
          display: { xs: "block", md: "none" },
          "@media(max-width:550px)": {
            "& .MuiDialog-paper": {
              //  backgroundImage: 'url("/form-back.jpg")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                borderRadius: "10px",
                background: "rgba(255, 255, 255, 0.5)", // Optional: dark overlay
                backdropFilter: "blur(3px)", // Apply backdrop filter effect
                zIndex: 0,
              },
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
          <EnquiryForm categoryName={categoryName} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiPaper-elevation": {
            // backgroundImage: 'url("/form-back.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              borderRadius: "10px",
              background: "#fff", // Optional: dark overlay
              backdropFilter: "blur(3px)", // Apply backdrop filter effect
              zIndex: 0,
            },
          },
        }}
      >
        <EnquiryForm categoryName={categoryName} handleClose={handleClose} />
      </Box>
    </Box>
  );
};

export default CuratedBanner;
