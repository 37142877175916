import { DownloadRounded, FlightRounded, Share } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { RWebShare } from "react-web-share";

const TripBanner = ({
  tripName = "6-7 days thailand tour package from india",
  setOpenBookingForm,
  itineraryURL,
  tripPrice,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const flexDirection = isSmallScreen ? "column" : "row";
  const location = useLocation();
  return (
    <Box
      sx={{
        position: "absolute",
        top: [150, 200],
        width: "100%",
        zIndex: 1,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: flexDirection,
      }}
    >
      <Stack>
        <Typography
          variant="h1"
          sx={{
            color: "white",
            textAlign: "center",
            fontWeight: { md: 500, sm: 500, xs: 500 },
            fontSize: { md: "70px", sm: "45px", xs: "32px" },
            textShadow: "0 4px 4px rgba(0,0,0,.8);",
            padding: "0 15px",
          }}
        >
          {tripName}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "white",
            pt: { md: 1, sm: 5, xs: 2 },
            textAlign: "center",
            fontSize: { md: "35px", sm: "25px", xs: "19px" },
            textShadow: "0 4px 4px rgba(0,0,0,.8);",
            fontWeight: "300",
          }}
        >
          Dil To Ghummakkad Hai...
        </Typography>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: { md: 2, sm: 10, xs: 10 },
          }}
        >
          <IconButton
            href={itineraryURL}
            disabled={itineraryURL == null || itineraryURL == undefined}
            variant="contained"
            color="inherit"
            target="_blank"
            size={isSmallScreen ? "medium" : "large"}
            sx={{
              background: "white",
              color: "#2f86a6",
              fontSize: "14px",
              padding: "10px 30px !important",
              borderRadius: 20,
              "&.Mui-disabled": {
                background: "#eaeaea",
                color: "#c0c0c0",
              },
              "@media(max-width:550px)": {
                fontSize: "10px",
              },
              "&:hover": {
                background: "#f0f0f0", // Example hover background color
                color: "#1f6e86", // Example hover text color
                cursor: "pointer",
              },
            }}
          >
            Get Itinerary
            <DownloadRounded
              sx={{
                fontSize: "20px",
                marginLeft: "10px",
                "@media(max-width:550px)": {
                  fontSize: "20px",
                },
              }}
            />
          </IconButton>

          {tripPrice ? (
            <IconButton
              className="bannerButton"
              variant="contained"
              color="inherit"
              size={isSmallScreen ? "medium" : "large"}
              sx={{
                background: "white",
                color: "#2f86a6",
                fontSize: "14px",
                fontWeight: "600",
                textAlign: "center",
                padding: "10px 30px !important",
                borderRadius: 20,
                "@media(max-width:550px)": {
                  fontSize: "10px",
                },
                "&:hover": {
                  background: "#f0f0f0", // Example hover background color
                  color: "#1f6e86", // Example hover text color
                  cursor: "pointer",
                },
              }}
              onClick={() => setOpenBookingForm(true)}
            >
              <FlightRounded
                sx={{
                  fontSize: "20px",
                  marginRight: "10px",
                  "@media(max-width:550px)": {
                    fontSize: "20px",
                  },
                }}
              />
              Book Now
            </IconButton>
          ) : (
            <Button
              disabled="true"
              variant="outlined"
              color="inherit"
              size={isSmallScreen ? "medium" : "large"}
              sx={{
                background: "white",
                color: "#2f86a6",
                fontSize: "14px",
                padding: "10px 30px !important",
                borderRadius: 20,
              }}
              onClick={() => setOpenBookingForm(true)}
            >
              Book Now
            </Button>
          )}
          <RWebShare
            data={{
              text: "Memorable Your Trip",
              url: location.pathname,
              title: "Memorable Your Trip Website link",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <IconButton
              variant="contained"
              color="inherit"
              size={isSmallScreen ? "medium" : "large"}
              sx={{
                background: "white",
                color: "#2f86a6",
                fontSize: "14px",
                padding: "10px !important",
                borderRadius: 20,
                "&:hover": {
                  background: "#f0f0f0", // Example hover background color
                  color: "#1f6e86", // Example hover text color
                  cursor: "pointer",
                },
              }}
            >
              <Share
                sx={{
                  fontSize: "20px",
                  "@media(max-width:550px)": {
                    fontSize: "20px",
                  },
                }}
              />
            </IconButton>
          </RWebShare>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TripBanner;
