import { Box, Card, CardActionArea, CardMedia, Grid } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { getCurrentDomain } from "../components/common/utils";
import AppContainer from "../components/containers/AppContainer";
import Carousel from "../components/layouts/Carousel";
import Curated from "../components/layouts/Curated";
import CustomizeTrip from "../components/layouts/CustomizeTrip";
import InDoubtCantDecide from "../components/layouts/InDoubtCantDecide";
import MakeYouHappier from "../components/layouts/MakeYouHappier";
import RealityOfTrip from "../components/layouts/RealityOfTrip";
import ReasonToCountOnUs from "../components/layouts/ReasonToCountOnUs";
import useTostify from "../hooks/useTostify";
import Preloader from "./../components/common/Preloader";
import "./Home.css";

const Home = () => {
  const { setNotification } = useTostify();
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [curatedCategories, setCuratedCategories] = useState([]);
  const [customizedCategories, setCustomizedCategories] = useState([]);
  const [topPromotionalBanner, setTopPromotionalBanner] = useState([]);
  const [bottomPromotionalBanner, setBottomPromotionalBanner] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  useEffect(() => {
    setLoadingCategories(true);
    axios
      .get(`${getCurrentDomain()}/categories`, {
        params: {
          for: "category",
        },
      })
      .then((res) => {
        const data = res.data;
        setCategories(data);
        setCuratedCategories(data.filter((c) => c.isCurated));
        setCustomizedCategories(data.filter((c) => c.isForCustomize));
        const topPromoBanner = data.filter((c) => c.isPromoHomeTop);
        const bottomPromoBanner = data.filter((c) => c.isPromoHomeBottom);
        setTopPromotionalBanner(topPromoBanner || []);
        setBottomPromotionalBanner(bottomPromoBanner || []);
        setLoadingCategories(false);
      })
      .catch((err) => {
        console.error("Error fetching bottom promotional banner:", err);
        setNotification({
          open: true,
          severity: "error",
          msg: "Oops Error while fetching Trip!!",
        });
        setLoadingCategories(false);
      });
  }, [setNotification]);

  const settings = {
    speed: 800,
    autoPlay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    infinite: true,
    dots: true,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <AppContainer>
      {loadingCategories ? (
        <Grid container justifyContent="center">
          <Grid item pt={5} pb={5}>
            <Preloader />
            {/* <CircularProgress size={30} /> */}
          </Grid>
        </Grid>
      ) : (
        <>
          <Curated categories={curatedCategories} />
          {/* Top Banner Slider */}
          <Slider {...settings}>
            {topPromotionalBanner.length > 0 &&
              topPromotionalBanner.map((b, i) => (
                <Card
                  className="saleCard"
                  key={b.slug}
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                    textAlign: "center",
                    mt: 10,
                    boxShadow: "0 0 0 #0000 !important",
                    borderRadius: "10px",
                    paddingLeft: "80px",
                    paddingRight: "80px",
                    "@media(max-width:767px)": {
                      width: "100%",
                      mt: 5,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    },
                  }}
                >
                  <CardActionArea LinkComponent={Link} to={`/${b?.slug}`}>
                    <CardMedia
                      component="img"
                      image={b?.coverImage.url}
                      alt={b?.categoryName}
                      loading="lazy"
                      sx={{ borderRadius: "10px" }}
                    />
                  </CardActionArea>
                </Card>
              ))}
          </Slider>

          {categories

            .filter((c) => c.trips.length > 0 && c.isForSlider)
            .slice(0, 2)
            .map((c, i) => (
              <Box
                key={i}
                sx={{
                  mt: 3,
                  className: "slidersSet",
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  "@media(max-width:767px)": {
                    paddingLeft: "5px",
                    mt: 4,
                    paddingRight: "5px",
                  },
                }}
              >
                <Carousel
                  filter={false}
                  key={i}
                  title={c.categoryName}
                  trips={c.trips}
                  navigationLink={c.slug}
                />

                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "2px",
                      width: "44%",
                      backgroundColor: "#2f86a6",
                      "@media(max-width:767px)": {
                        width: "30%",
                      },
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                      color: "#2f86a6 !important",
                      borderRadius: 10,
                      mx: 1,
                      border: "2px solid #2f86a6",
                      fontSize: "12px",
                    }}
                    onClick={() => navigate(`/${c.slug}`)}
                  >
                    View All
                  </Button>
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: "2px",
                      width: "44%",
                      backgroundColor: "#2f86a6",
                      "@media(max-width:767px)": {
                        width: "30%",
                      },
                    }}
                  />
                </Box> */}
              </Box>
            ))}
          {/* Bottom Banner Slider */}
          <Slider {...settings}>
            {bottomPromotionalBanner.length > 0 &&
              bottomPromotionalBanner.map((b, i) => (
                <Card
                  className="saleCard"
                  key={b.slug}
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                    textAlign: "center",
                    mt: 4,
                    boxShadow: "0 0 0 #0000 !important",
                    borderRadius: "10px",

                    paddingLeft: "80px",
                    paddingRight: "80px",
                    "@media(max-width:767px)": {
                      width: "100%",
                      mt: 2,
                      paddingLeft: "5px",
                      paddingRight: "5px",
                    },
                  }}
                >
                  <CardActionArea LinkComponent={Link} to={`/${b?.slug}`}>
                    <CardMedia
                      component="img"
                      image={b?.coverImage.url}
                      alt={b?.categoryName}
                      loading="lazy"
                      sx={{ borderRadius: "10px" }}
                    />
                  </CardActionArea>
                </Card>
              ))}
          </Slider>
          {categories
            .filter((c) => c.trips.length > 0 && c.isForSlider)
            .slice(2, 4)
            .map((c, i) => (
              <Box
                key={i}
                sx={{
                  mt: 3,
                  className: "slidersSet",
                  paddingLeft: "80px",
                  paddingRight: "80px",
                  "@media(max-width:767px)": {
                    paddingLeft: "5px",
                    mt: 4,
                    paddingRight: "5px",
                  },
                }}
              >
                <Carousel
                  filter={false}
                  key={i}
                  title={c.categoryName}
                  trips={c.trips}
                  navigationLink={c.slug}
                />
              </Box>
            ))}
          <MakeYouHappier />
          <InDoubtCantDecide />
          <CustomizeTrip customizedCategories={customizedCategories} />
          <ReasonToCountOnUs />
          <RealityOfTrip />
        </>
      )}
    </AppContainer>
  );
};

export default Home;
