import React from "react";
// import './Preloader.css'; // Import the CSS file for styling
import Lottie from "react-lottie";
import animationData from "../../preloader.json";

const Preloader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="preloader">
      <Lottie options={defaultOptions}  height={150} width={150} />
      {/* <img src={preloader} alt="Loading..." /> */}
    </div>
  );
};

export default Preloader;
